import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { updateUserSubscription, setUser } from './store.js';
import { supabase } from './supabaseClient.js';
import styled, { createGlobalStyle } from 'styled-components';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { stripePromise, STRIPE_PRICE_IDS, SUBSCRIPTION_PRICES, PLAN_DETAILS } from './stripeConfig.js';
import { useAuth } from './AuthContext.js';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: linear-gradient(135deg, #1a202c, #2d3748);
    min-height: 100vh;
    color: #ffffff;
    overflow-y: auto;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  background: linear-gradient(135deg, #1a202c, #2d3748);
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  position: relative;
  padding-bottom: 2rem;
`;

const TopBar = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  background: rgba(26, 32, 44, 0.95);
  backdrop-filter: blur(8px);
  z-index: 1000;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
  padding: 0.5rem;

  &:hover {
    color: #4299e1;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const BackText = styled.span`
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;

  ${BackLink}:hover & {
    opacity: 1;
    transform: translateX(0);
  }
`;

const MainContent = styled.main`
  width: 100%;
  max-width: 600px;
  margin: 2rem auto;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (max-width: 768px) {
    padding: 0 1rem;
    margin: 1rem auto;
  }
`;

const FormContainer = styled.div`
  background-color: rgba(26, 32, 44, 0.3);
  border-radius: 1rem;
  padding: 2.5rem;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  font-family: 'Poppins', sans-serif;
  
  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const PlanHeader = styled.div`
  text-align: center;
  margin-bottom: 3rem;
  padding: 1.5rem;
  background: rgba(26, 32, 44, 0.5);
  border-radius: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.1);

  h2 {
    font-size: 1.5rem;
    color: #ffffff;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  .price {
    font-size: 2.25rem;
    color: #4299e1;
    font-weight: 700;
    margin-bottom: 0.75rem;
  }

  .period {
    color: #A0AEC0;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
`;

const BillingToggle = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto 3rem;
  background: rgba(26, 32, 44, 0.5);
  padding: 0.5rem;
  border-radius: 0.75rem;
  width: fit-content;
`;

const ToggleButton = styled.button`
  padding: 0.75rem 2rem;
  border: none;
  background: ${props => props.$active ? '#4299e1' : 'transparent'};
  color: ${props => props.$active ? '#ffffff' : '#a0aec0'};
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.2s ease;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;

  &:hover {
    color: ${props => props.$active ? '#ffffff' : '#e2e8f0'};
    background: ${props => props.$active ? '#4299e1' : 'rgba(66, 153, 225, 0.1)'};
  }
`;

const PaymentSection = styled.div`
  margin: 2rem 0;
  text-align: center;
`;

const LinkPayButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 2rem;
  background-color: #1a202c;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  color: #ffffff;
  font-weight: 500;
  transition: all 0.2s ease;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  width: auto;
  min-width: 200px;
  gap: 0.5rem;

  &:hover {
    background-color: #2d3748;
    border-color: rgba(255, 255, 255, 0.2);
  }

  img {
    height: 20px;
    width: auto;
  }
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 2rem 0;
  color: #a0aec0;
  font-size: 0.875rem;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  span {
    margin: 0 1rem;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: rgba(26, 32, 44, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  color: #ffffff;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #4299e1;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.2);
  }

  &::placeholder {
    color: #718096;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem 1rem;
  background-color: rgba(26, 32, 44, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  color: #ffffff;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #4299e1;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.2);
  }

  option {
    background-color: #1a202c;
    color: #ffffff;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 1rem;
  background: linear-gradient(135deg, #4299e1 0%, #3182ce 100%);
  border: none;
  border-radius: 0.5rem;
  color: #ffffff;
  font-weight: 600;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  transition: all 0.3s ease;
  margin-top: 2rem;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  &:hover {
    background: linear-gradient(135deg, #3182ce 0%, #2c5282 100%);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(66, 153, 225, 0.3);
  }

  &:disabled {
    background: linear-gradient(135deg, #718096 0%, #4a5568 100%);
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const ErrorMessage = styled.div`
  color: #fc8181;
  margin-bottom: 1rem;
`;

const PlanSummary = styled.div`
  background-color: rgba(26, 32, 44, 0.6);
  border-radius: 0.75rem;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);

  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #ffffff;
  }

  p {
    font-size: 1.25rem;
    color: #4299e1;
    margin-bottom: 1.5rem;
  }
`;

const PlanFeature = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  
  svg {
    color: #48BB78;
    margin-right: 0.5rem;
  }
`;

const TermsText = styled.p`
  font-size: 0.875rem;
  color: #A0AEC0;
  margin-top: 1rem;
`;

const FormGroup = styled.div`
  margin-bottom: ${props => props.$spacing || '2rem'};
  width: 100%;
`;

const CardElementContainer = styled.div`
  padding: 1rem;
  background-color: rgba(26, 32, 44, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  transition: all 0.2s ease;

  &:focus-within {
    border-color: #4299e1;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.2);
  }
`;

const Label = styled.label`
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #e2e8f0;
  margin-bottom: 0.75rem;
  font-family: 'Poppins', sans-serif;
`;

const SubscriptionForm = ({ billingPeriod, setBillingPeriod, getPlanPrice, plan, session }) => {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [couponCode, setCouponCode] = useState('');
  const [couponError, setCouponError] = useState(null);
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [validatingCoupon, setValidatingCoupon] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    name: '',
    email: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'US'
  });
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const { isManageMode } = location.state || {};

  // Initialize billing details from user data
  useEffect(() => {
    if (user) {
      setBillingDetails(prev => ({
        ...prev,
        name: user.user_metadata?.full_name || '',
        email: user.email || ''
      }));
    }
  }, [user]);

  // Check for existing subscription on mount
  useEffect(() => {
    const checkSubscription = async () => {
      if (!session?.user?.id) return;

      try {
        const { data: accountData, error: accountError } = await supabase
          .from('accounts')
          .select('subscription_status, stripe_subscription_id')
          .eq('id', session.user.id)
          .single();

        if (accountError) {
          console.error('Error checking subscription:', accountError);
          return;
        }

        // If user already has an active subscription, redirect to dashboard
        if (accountData?.subscription_status === 'active' && accountData?.stripe_subscription_id) {
          console.log('Active subscription found, redirecting to dashboard');
          navigate('/dashboard', { replace: true });
        }
      } catch (error) {
        console.error('Error checking subscription status:', error);
      }
    };

    checkSubscription();
  }, [session, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!session?.user) {
      setError('No active session found. Please log in again.');
      return;
    }

    setProcessing(true);
    setError(null);
    setCouponError(null);

    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
      
      // Create or update customer in Stripe
      const customerResponse = await fetch(`${apiUrl}/api/create-customer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: billingDetails.email,
          name: billingDetails.name,
          userId: session.user.id,
          address: {
            line1: billingDetails.address,
            city: billingDetails.city,
            state: billingDetails.state,
            postal_code: billingDetails.postalCode,
            country: billingDetails.country,
          }
        })
      });

      if (!customerResponse.ok) {
        const errorData = await customerResponse.json();
        throw new Error(errorData.message || 'Failed to create customer');
      }

      const { customerId } = await customerResponse.json();

      // Create payment method
      const { error: cardError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: billingDetails.name,
          email: billingDetails.email,
          address: {
            line1: billingDetails.address,
            city: billingDetails.city,
            state: billingDetails.state,
            postal_code: billingDetails.postalCode,
            country: billingDetails.country,
          }
        }
      });

      if (cardError) {
        throw new Error(cardError.message);
      }

      // Create subscription
      const subscriptionResponse = await fetch(`${apiUrl}/api/create-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId,
          paymentMethodId: paymentMethod.id,
          plan: plan?.toLowerCase() || 'business',
          billingPeriod: billingPeriod.toLowerCase(),
          couponCode: couponCode || undefined
        })
      });

      if (!subscriptionResponse.ok) {
        const errorData = await subscriptionResponse.json();
        if (errorData.code === 'INVALID_COUPON') {
          setCouponError(errorData.message || 'Invalid coupon code');
          setProcessing(false);
          return;
        }
        throw new Error(errorData.message || 'Failed to create subscription');
      }

      const { subscriptionId, clientSecret } = await subscriptionResponse.json();

      if (clientSecret) {
        const { error: confirmError } = await stripe.confirmCardPayment(clientSecret);
        if (confirmError) {
          throw new Error(confirmError.message);
        }
      }

      // Update account in Supabase
      const { error: updateError } = await supabase
        .from('accounts')
        .update({
          stripe_customer_id: customerId,
          stripe_subscription_id: subscriptionId,
          subscription_status: 'active',
          subscription_plan: plan?.toLowerCase() || 'business',
          updated_at: new Date().toISOString()
        })
        .eq('id', session.user.id);

      if (updateError) {
        throw new Error('Failed to update account information');
      }

      // Update Redux store
      dispatch(setUser({
        ...user,
        subscription_plan: plan?.toLowerCase() || 'business',
        subscription_status: 'active',
        stripe_customer_id: customerId,
        stripe_subscription_id: subscriptionId
      }));

      setProcessing(false);
      
      // Force a small delay to ensure all updates are complete
      await new Promise(resolve => setTimeout(resolve, 500));
      
      // Use replace instead of push to prevent going back to subscription page
      navigate('/dashboard', { 
        replace: true,
        state: { 
          message: 'Subscription created successfully!',
          subscriptionId: subscriptionId
        }
      });
    } catch (error) {
      console.error('Subscription error:', error);
      setError(error.message);
      setProcessing(false);
    }
  };

  const validateCoupon = async () => {
    if (!couponCode) {
      setCouponError('Please enter a coupon code');
      return;
    }

    setValidatingCoupon(true);
    setCouponError(null);

    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3001';
      const response = await fetch(`${apiUrl}/api/validate-coupon`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ couponCode })
      });

      const data = await response.json();

      if (!response.ok) {
        setCouponError(data.message || 'Invalid coupon code');
        setAppliedCoupon(null);
        return;
      }

      setAppliedCoupon(data.coupon);
    } catch (error) {
      console.error('Coupon validation error:', error);
      setCouponError('Failed to validate coupon');
      setAppliedCoupon(null);
    } finally {
      setValidatingCoupon(false);
    }
  };

  const getAdjustedPrice = () => {
    const basePrice = getPlanPrice();
    if (!appliedCoupon) return basePrice;

    // Handle percentage discount
    if (appliedCoupon.percent_off) {
      return basePrice * (1 - appliedCoupon.percent_off / 100);
    }

    // Handle fixed amount discount
    if (appliedCoupon.amount_off) {
      return Math.max(0, basePrice - (appliedCoupon.amount_off / 100)); // amount_off is in cents
    }

    return basePrice;
  };

  const removeCoupon = () => {
    setCouponCode('');
    setAppliedCoupon(null);
    setCouponError(null);
  };

  return (
    <FormContainer>
      <PlanHeader>
        <h2>{isManageMode ? 'Manage Billing Information' : PLAN_DETAILS[plan?.toLowerCase()]?.name || 'Subscription Plan'}</h2>
        {!isManageMode && (
          <>
            <div className="price" style={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center', 
              gap: '0.5rem' 
            }}>
              {appliedCoupon && (
                <span style={{ 
                  textDecoration: 'line-through', 
                  color: '#A0AEC0', 
                  fontSize: '1.5rem' 
                }}>
                  ${getPlanPrice().toFixed(2)}
                </span>
              )}
              <span style={{ color: appliedCoupon ? '#48BB78' : '#4299e1' }}>
                ${getAdjustedPrice().toFixed(2)}
              </span>
            </div>
            <div className="period">per {billingPeriod === 'MONTHLY' ? 'month' : 'year'}</div>
            {appliedCoupon && (
              <div style={{ 
                color: '#48BB78', 
                fontSize: '0.875rem', 
                marginTop: '0.5rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.5rem'
              }}>
                <FontAwesomeIcon icon={faCheck} />
                {appliedCoupon.percent_off 
                  ? `${appliedCoupon.percent_off}% off` 
                  : `$${(appliedCoupon.amount_off / 100).toFixed(2)} off`}
                {appliedCoupon.duration === 'repeating' && ` for ${appliedCoupon.duration_in_months} months`}
                {appliedCoupon.duration === 'once' && ' (one-time)'}
                {appliedCoupon.duration === 'forever' && ' (forever)'}
              </div>
            )}
          </>
        )}
      </PlanHeader>

      {!isManageMode && (
        <BillingToggle>
          <ToggleButton 
            $active={billingPeriod === 'MONTHLY'} 
            onClick={() => setBillingPeriod('MONTHLY')}
          >
            Monthly
          </ToggleButton>
          <ToggleButton 
            $active={billingPeriod === 'YEARLY'} 
            onClick={() => setBillingPeriod('YEARLY')}
          >
            Yearly
            <span style={{ marginLeft: '0.5rem', color: '#68D391', fontSize: '0.75rem' }}>
              (Save 16%)
            </span>
          </ToggleButton>
        </BillingToggle>
      )}

      <form onSubmit={handleSubmit}>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '2rem', marginBottom: '3rem' }}>
          <FormGroup $spacing="0">
            <Label>Full Name</Label>
            <Input
              type="text"
              name="name"
              value={billingDetails.name}
              onChange={(e) => setBillingDetails({ ...billingDetails, name: e.target.value })}
              placeholder="John Doe"
              required
            />
          </FormGroup>
          <FormGroup $spacing="0">
            <Label>Email</Label>
            <Input
              type="email"
              name="email"
              value={billingDetails.email}
              onChange={(e) => setBillingDetails({ ...billingDetails, email: e.target.value })}
              placeholder="john@example.com"
              required
            />
          </FormGroup>
        </div>

        <FormGroup $spacing="3rem">
          <Label>Address</Label>
          <Input
            type="text"
            name="address"
            value={billingDetails.address}
            onChange={(e) => setBillingDetails({ ...billingDetails, address: e.target.value })}
            placeholder="123 Main St"
            required
          />
        </FormGroup>

        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '2rem', marginBottom: '3rem' }}>
          <FormGroup $spacing="0">
            <Label>City</Label>
            <Input
              type="text"
              name="city"
              value={billingDetails.city}
              onChange={(e) => setBillingDetails({ ...billingDetails, city: e.target.value })}
              placeholder="New York"
              required
            />
          </FormGroup>
          <FormGroup $spacing="0">
            <Label>State</Label>
            <Input
              type="text"
              name="state"
              value={billingDetails.state}
              onChange={(e) => setBillingDetails({ ...billingDetails, state: e.target.value })}
              placeholder="NY"
              required
            />
          </FormGroup>
        </div>

        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '2rem', marginBottom: '3rem' }}>
          <FormGroup $spacing="0">
            <Label>ZIP / Postal Code</Label>
            <Input
              type="text"
              name="postalCode"
              value={billingDetails.postalCode}
              onChange={(e) => setBillingDetails({ ...billingDetails, postalCode: e.target.value })}
              placeholder="10001"
              required
            />
          </FormGroup>
          <FormGroup $spacing="0">
            <Label>Country</Label>
            <Select
              name="country"
              value={billingDetails.country}
              onChange={(e) => setBillingDetails({ ...billingDetails, country: e.target.value })}
              required
            >
              <option value="US">United States</option>
              <option value="CA">Canada</option>
              <option value="GB">United Kingdom</option>
            </Select>
          </FormGroup>
        </div>

        <FormGroup $spacing="3rem">
          <Label>Card Details</Label>
          <CardElementContainer>
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    color: '#ffffff',
                    fontFamily: '"Poppins", sans-serif',
                    '::placeholder': {
                      color: '#9CA3AF',
                    },
                    iconColor: '#ffffff',
                  },
                  invalid: {
                    color: '#EF4444',
                    iconColor: '#EF4444',
                  },
                },
              }}
            />
          </CardElementContainer>
        </FormGroup>

        {!isManageMode && (
          <FormGroup $spacing="3rem">
            <Label>Have a coupon code?</Label>
            <div style={{ 
              display: 'flex', 
              gap: '1rem',
              alignItems: 'flex-start',
              flexDirection: 'column'
            }}>
              <div style={{ 
                display: 'flex',
                gap: '1rem',
                width: '100%',
                position: 'relative'
              }}>
                <Input
                  type="text"
                  name="couponCode"
                  value={couponCode}
                  onChange={(e) => {
                    setCouponCode(e.target.value.toUpperCase());
                    setCouponError(null);
                  }}
                  placeholder="Enter coupon code"
                  style={{ 
                    textTransform: 'uppercase',
                    width: 'calc(100% - 140px)' // Account for button width and gap
                  }}
                  disabled={appliedCoupon || validatingCoupon}
                />
                {appliedCoupon ? (
                  <button
                    type="button"
                    onClick={removeCoupon}
                    style={{
                      padding: '0.75rem 1.25rem',
                      backgroundColor: 'rgba(229, 62, 62, 0.1)',
                      border: '1px solid rgba(229, 62, 62, 0.2)',
                      borderRadius: '0.5rem',
                      color: '#FC8181',
                      cursor: 'pointer',
                      transition: 'all 0.2s ease',
                      fontFamily: 'Poppins, sans-serif',
                      width: '120px',
                      height: '45px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexShrink: 0
                    }}
                  >
                    Remove
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={validateCoupon}
                    disabled={!couponCode || validatingCoupon}
                    style={{
                      padding: '0.75rem 1.25rem',
                      backgroundColor: 'rgba(66, 153, 225, 0.1)',
                      border: '1px solid rgba(66, 153, 225, 0.2)',
                      borderRadius: '0.5rem',
                      color: '#4299e1',
                      cursor: 'pointer',
                      transition: 'all 0.2s ease',
                      fontFamily: 'Poppins, sans-serif',
                      width: '120px',
                      height: '45px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      opacity: !couponCode || validatingCoupon ? 0.5 : 1,
                      flexShrink: 0
                    }}
                  >
                    {validatingCoupon ? 'Validating...' : 'Apply'}
                  </button>
                )}
              </div>
              {couponError && (
                <div style={{ 
                  color: '#EF4444', 
                  fontSize: '0.875rem',
                  width: '100%'
                }}>
                  {couponError}
                </div>
              )}
            </div>
          </FormGroup>
        )}

        {error && (
          <div style={{ 
            color: '#EF4444', 
            textAlign: 'center', 
            marginBottom: '2rem', 
            fontSize: '0.875rem',
            padding: '1rem',
            backgroundColor: 'rgba(229, 62, 62, 0.1)',
            borderRadius: '0.5rem',
            border: '1px solid rgba(229, 62, 62, 0.2)'
          }}>
            {error}
          </div>
        )}

        <SubmitButton type="submit" disabled={processing || !stripe}>
          {processing ? 'Processing...' : isManageMode ? 'Update Billing Information' : 'Subscribe Now'}
        </SubmitButton>

        {!isManageMode && (
          <p style={{ 
            fontSize: '0.875rem', 
            color: '#A0AEC0', 
            textAlign: 'center', 
            marginTop: '2rem',
            fontFamily: '"Poppins", sans-serif',
            lineHeight: '1.5'
          }}>
            By subscribing, you agree to our Terms of Service and Privacy Policy.
            Your subscription will automatically renew at the end of each billing period.
          </p>
        )}
      </form>
    </FormContainer>
  );
};

const StripeSubscriptionSetup = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [billingPeriod, setBillingPeriod] = useState('MONTHLY');
  const [session, setSession] = useState(null);
  const { user, loading: authLoading } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { plan = 'business', isManageMode = false } = location.state || {};

  useEffect(() => {
    const initializeSession = async () => {
      try {
        setLoading(true);
        
        // Get current session
        const { data: { session: currentSession }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) throw sessionError;

        if (!currentSession?.user) {
          throw new Error('No active session found');
        }

        // Set session and get account data
        setSession(currentSession);
        
        const { data: accountData, error: accountError } = await supabase
          .from('accounts')
          .select('*')
          .eq('id', currentSession.user.id)
          .single();

        if (accountError && accountError.code !== 'PGRST116') {
          throw accountError;
        }

        dispatch(setUser({
          ...currentSession.user,
          ...(accountData || {})
        }));

        setLoading(false);
      } catch (error) {
        console.error('Session initialization error:', error);
        setError(error.message || 'Failed to initialize session. Please try logging in again.');
        setLoading(false);
        navigate('/login', { state: { from: location } });
      }
    };

    initializeSession();
  }, [navigate, location, dispatch]);

  const getPlanPrice = () => {
    const planKey = plan?.toUpperCase();
    if (!planKey || !SUBSCRIPTION_PRICES[planKey]) return 0;
    
    const prices = SUBSCRIPTION_PRICES[planKey];
    return prices[billingPeriod] || 0;
  };

  if (loading || authLoading) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#1a202c',
        color: 'white',
        fontSize: '1.2rem',
        flexDirection: 'column',
        gap: '1rem'
      }}>
        <div>Initializing subscription setup...</div>
        <div style={{ fontSize: '1rem', color: '#A0AEC0' }}>Please wait while we load your information</div>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#1a202c',
        color: '#FC8181',
        fontSize: '1.2rem',
        padding: '2rem',
        textAlign: 'center'
      }}>
        {error}
      </div>
    );
  }

  if (!session || !user) {
    navigate('/login', { state: { from: location } });
    return null;
  }

  return (
    <>
      <GlobalStyle />
      <PageContainer>
        <TopBar>
          <BackLink to="/account">
            <FontAwesomeIcon icon={faArrowLeft} />
            <BackText>Back to Account</BackText>
          </BackLink>
        </TopBar>
        <MainContent>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{ width: '100%' }}
          >
            <h1 style={{ 
              fontSize: "2.25rem", 
              fontWeight: "bold", 
              marginBottom: "2rem", 
              color: "#ffffff", 
              textAlign: "center",
              fontFamily: "'Poppins', sans-serif"
            }}>
              {isManageMode ? 'Manage Billing Information' : 'Set Up Subscription'}
            </h1>
            <Elements 
              stripe={stripePromise}
              options={{
                mode: 'setup',
                currency: 'usd',
                appearance: {
                  theme: 'night',
                  variables: {
                    colorPrimary: '#4299e1',
                    colorBackground: '#1a202c',
                    colorText: '#ffffff',
                    colorDanger: '#EF4444',
                    fontFamily: 'Poppins, system-ui, sans-serif',
                  }
                }
              }}
            >
              <SubscriptionForm 
                billingPeriod={billingPeriod}
                setBillingPeriod={setBillingPeriod}
                getPlanPrice={getPlanPrice}
                plan={plan}
                session={session}
              />
            </Elements>
          </motion.div>
        </MainContent>
      </PageContainer>
    </>
  );
};

export default StripeSubscriptionSetup;
