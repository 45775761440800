import { jsPDF } from 'jspdf';
import { format } from 'date-fns';
import { API_COSTS } from './stripeConfig';
import { calculateMonthlyApiCosts } from './billingUtils';

export class InvoiceGenerator {
  constructor(businessData, billingPeriod) {
    this.pdf = new jsPDF();
    this.businessData = businessData;
    this.billingPeriod = billingPeriod;
    this.margin = 20;
    this.lineHeight = 10;
    this.colors = {
      primary: [67, 56, 202],    // #4338ca
      secondary: [75, 85, 99],   // #4b5563
      text: [31, 41, 55],        // #1f2937
      lightGray: [243, 244, 246] // #f3f4f6
    };
  }

  async generateInvoice(apiUsage, additionalUsers) {
    // Set up document
    this.pdf.setFont('helvetica');
    
    // Add decorative header bar
    this.addHeaderBar();
    
    // Add header content
    this.addHeader();
    
    // Add business details
    this.addBusinessDetails();
    
    // Add invoice details
    this.addInvoiceDetails();
    
    // Add billing summary
    await this.addBillingSummary(apiUsage, additionalUsers);
    
    // Add footer
    this.addFooter();
    
    return this.pdf;
  }

  addHeaderBar() {
    this.pdf.setFillColor(...this.colors.primary);
    this.pdf.rect(0, 0, this.pdf.internal.pageSize.width, 40, 'F');
  }

  addHeader() {
    // Add white INVOICE text on the colored bar
    this.pdf.setTextColor(255, 255, 255);
    this.pdf.setFontSize(28);
    this.pdf.setFont('helvetica', 'bold');
    this.pdf.text('INVOICE', this.margin, 28);

    // Add DynamicDrafts logo text
    this.pdf.setFontSize(16);
    this.pdf.text('DynamicDrafts', this.pdf.internal.pageSize.width - this.margin - 40, 28, { align: 'right' });
  }

  addBusinessDetails() {
    const yStart = 60;
    this.pdf.setTextColor(...this.colors.text);
    
    // From section
    this.pdf.setFontSize(12);
    this.pdf.setFont('helvetica', 'bold');
    this.pdf.text('From', this.margin, yStart);
    
    this.pdf.setFont('helvetica', 'normal');
    this.pdf.setFontSize(10);
    this.pdf.text('DynamicDrafts', this.margin, yStart + 10);
    this.pdf.setTextColor(...this.colors.secondary);
    this.pdf.text('Professional CAD Solutions', this.margin, yStart + 20);
    
    // Bill To section
    this.pdf.setTextColor(...this.colors.text);
    this.pdf.setFontSize(12);
    this.pdf.setFont('helvetica', 'bold');
    this.pdf.text('Bill To', this.pdf.internal.pageSize.width - this.margin - 80, yStart);
    
    this.pdf.setFont('helvetica', 'normal');
    this.pdf.setFontSize(10);
    this.pdf.text(this.businessData.name, this.pdf.internal.pageSize.width - this.margin - 80, yStart + 10);
  }

  addInvoiceDetails() {
    const yStart = 110;
    
    // Add subtle background rectangle
    this.pdf.setFillColor(...this.colors.lightGray);
    this.pdf.rect(this.margin - 5, yStart - 5, this.pdf.internal.pageSize.width - 2 * this.margin + 10, 35, 'F');
    
    this.pdf.setTextColor(...this.colors.text);
    this.pdf.setFontSize(10);
    
    // Left column
    this.pdf.text(`Invoice Date: ${format(new Date(), 'MMM dd, yyyy')}`, this.margin, yStart);
    this.pdf.text(`Invoice #: INV-${format(new Date(), 'yyyyMMdd')}-${this.businessData.id.slice(0, 6)}`, this.margin, yStart + 15);
    
    // Right column
    this.pdf.text('Billing Period:', this.pdf.internal.pageSize.width - this.margin - 80, yStart);
    this.pdf.text(`${format(this.billingPeriod.start, 'MMM dd, yyyy')} -`, this.pdf.internal.pageSize.width - this.margin - 80, yStart + 15);
    this.pdf.text(format(this.billingPeriod.end, 'MMM dd, yyyy'), this.pdf.internal.pageSize.width - this.margin - 80, yStart + 25);
  }

  async addBillingSummary(apiUsage, additionalUsers) {
    const yStart = 160;
    
    // Add table headers with primary color background
    this.pdf.setFillColor(...this.colors.primary);
    this.pdf.rect(this.margin - 5, yStart - 5, this.pdf.internal.pageSize.width - 2 * this.margin + 10, 15, 'F');
    
    this.pdf.setTextColor(255, 255, 255);
    this.pdf.setFontSize(10);
    this.pdf.setFont('helvetica', 'bold');
    this.pdf.text('Description', this.margin, yStart + 5);
    this.pdf.text('Quantity', this.margin + 100, yStart + 5);
    this.pdf.text('Rate', this.margin + 130, yStart + 5);
    this.pdf.text('Amount', this.margin + 160, yStart + 5);

    let currentY = yStart + 20;
    let total = 99.99;

    this.pdf.setTextColor(...this.colors.text);
    this.pdf.setFont('helvetica', 'normal');

    // Add billing lines with alternating backgrounds
    this.addBillingLineWithBackground('Business Subscription', 1, 99.99, currentY, true);
    currentY += this.lineHeight + 5;

    if (additionalUsers > 0) {
      this.addBillingLineWithBackground('Additional Users', additionalUsers, 10, currentY, false);
      total += additionalUsers * 10;
      currentY += this.lineHeight + 5;
    }

    Object.entries(apiUsage).forEach(([action, count], index) => {
      if (count > 0 && API_COSTS[action] > 0) {
        const cost = API_COSTS[action] * count;
        this.addBillingLineWithBackground(`API Usage - ${action}`, count, API_COSTS[action], currentY, index % 2 === 0);
        total += cost;
        currentY += this.lineHeight + 5;
      }
    });

    // Add total with primary color background
    currentY += 10;
    this.pdf.setFillColor(...this.colors.primary);
    this.pdf.rect(this.margin + 100, currentY - 5, this.pdf.internal.pageSize.width - this.margin - 120, 20, 'F');
    
    this.pdf.setTextColor(255, 255, 255);
    this.pdf.setFontSize(12);
    this.pdf.setFont('helvetica', 'bold');
    this.pdf.text(`Total: $${total.toFixed(2)}`, this.margin + 160, currentY + 7, { align: 'right' });
  }

  addBillingLineWithBackground(description, quantity, rate, y, isEven) {
    // Add subtle background for even rows
    if (isEven) {
      this.pdf.setFillColor(...this.colors.lightGray);
      this.pdf.rect(this.margin - 5, y - 5, this.pdf.internal.pageSize.width - 2 * this.margin + 10, this.lineHeight + 5, 'F');
    }

    this.pdf.setTextColor(...this.colors.text);
    this.pdf.setFontSize(10);
    this.pdf.text(description, this.margin, y);
    this.pdf.text(quantity.toString(), this.margin + 100, y);
    this.pdf.text(`$${rate.toFixed(2)}`, this.margin + 130, y);
    this.pdf.text(`$${(quantity * rate).toFixed(2)}`, this.margin + 160, y);
  }

  addFooter() {
    const pageHeight = this.pdf.internal.pageSize.height;
    
    // Add footer bar
    this.pdf.setFillColor(...this.colors.primary);
    this.pdf.rect(0, pageHeight - 30, this.pdf.internal.pageSize.width, 30, 'F');
    
    // Add footer text
    this.pdf.setTextColor(255, 255, 255);
    this.pdf.setFontSize(9);
    this.pdf.text('Thank you for your business!', this.margin, pageHeight - 15);
    this.pdf.setFontSize(8);
    this.pdf.text('Payment is due within 30 days of invoice date.', this.pdf.internal.pageSize.width - this.margin, pageHeight - 15, { align: 'right' });
  }
}
