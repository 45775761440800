import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserProfile, fetchUserGrids } from "./store.js";
import { ReactComponent as DynamicDraftsLogo } from "./DynamicDraftsLogo.svg";

const UserDashboard = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [userProfile, setUserProfile] = useState(null);
  const [userGrids, setUserGrids] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profile = await dispatch(fetchUserProfile(userId)).unwrap();
        const grids = await dispatch(fetchUserGrids(userId)).unwrap();

        console.log("profile :", profile);
        console.log("grids :", grids);

        setUserProfile(profile);
        setUserGrids(grids);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching user data:", err);
        setError("Failed to load user data. Please try again.");
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, userId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div style={{ display: "flex", height: "100vh", backgroundColor: "#111827" }}>
      <aside style={{ width: "16rem", background: "linear-gradient(to bottom, #1F2937, #111827)", color: "white" }}>
        <div style={{ padding: "1.5rem" }}>
          {location.state?.fromAdminConsole ? (
            <Link to="/admin" style={{ display: "flex", alignItems: "center", color: "white", textDecoration: "none", marginBottom: "2rem" }}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ width: "1.5rem", height: "1.5rem", marginRight: "0.5rem" }}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
              Back to Admin Console
            </Link>
          ) : (
            <Link to="/dashboard" style={{ display: "block", marginBottom: "2rem" }}>
              <DynamicDraftsLogo style={{ width: "100%", height: "auto" }} />
            </Link>
          )}
        </div>
      </aside>
      <main style={{ flex: 1, overflowY: "auto", color: "#F3F4F6" }}>
        <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "2rem 1.5rem" }}>
          <h1 style={{ fontSize: "1.875rem", fontWeight: "bold", color: "#F3F4F6", marginBottom: "2rem" }}>Dashboard for {userProfile.name || userProfile.email}</h1>
          <div style={{ backgroundColor: "#1F2937", borderRadius: "0.5rem", boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)", padding: "1.5rem" }}>
            <h2 style={{ fontSize: "1.5rem", fontWeight: 600, marginBottom: "1.5rem", color: "#F3F4F6" }}>User's Grids</h2>
            {userGrids.length === 0 ? (
              <p style={{ color: "#9CA3AF", fontStyle: "italic" }}>No saved grids yet.</p>
            ) : (
              <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))", gap: "1.5rem" }}>
                {userGrids.map((grid) => (
                  <Link
                    key={grid.id}
                    to={`/cad/${grid.id}`}
                    style={{
                      display: "block",
                      backgroundColor: "#374151",
                      padding: "1.25rem",
                      borderRadius: "0.5rem",
                      transition: "all 0.3s ease",
                      border: "1px solid #4B5563",
                      textDecoration: "none",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <h3 style={{ fontWeight: 600, fontSize: "1.125rem", marginBottom: "0.5rem", color: "#F3F4F6" }}>{grid.name}</h3>
                    <p style={{ fontSize: "0.875rem", color: "#D1D5DB" }}>Created: {new Date(grid.created_at).toLocaleDateString()}</p>
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default UserDashboard;
