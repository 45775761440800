import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import '../../styles/App.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch, faTimes, faCamera, faMap, faTimes as faCancel, faUndo, faRuler} from "@fortawesome/free-solid-svg-icons";



// Helper functions

function roundMeasurement(value) {
    // Round to the nearest 0.5
    return Math.round(value * 2) / 2;
}

const isCloseEnough = (p1, p2) => {
    const distance = Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    return distance < 10;
};


function findClosestPointOnLine(lineStart, lineEnd, point) {
    const dx = lineEnd.x - lineStart.x;
    const dy = lineEnd.y - lineStart.y;
    const lineLengthSquared = dx * dx + dy * dy;

    if (lineLengthSquared === 0) {
        return lineStart;
    }

    const t = ((point.x - lineStart.x) * dx + (point.y - lineStart.y) * dy) / lineLengthSquared;
    
    if (t < 0) {
        return lineStart;
    } else if (t > 1) {
        return lineEnd;
    }

    return {
        x: lineStart.x + t * dx,
        y: lineStart.y + t * dy
    };
}

function findClosestEdge(polygonPoints, point) {
    let closestDistance = Infinity;
    let closestPoint = null;
    let closestEdgeStart = null;
    let closestEdgeEnd = null;

    for (let i = 0; i < polygonPoints.length; i++) {
        const start = polygonPoints[i];
        const end = polygonPoints[(i + 1) % polygonPoints.length];
        const closestPointOnEdge = findClosestPointOnLine(start, end, point);
        const distance = calculateDistance(point, closestPointOnEdge);

        if (distance < closestDistance) {
            closestDistance = distance;
            closestPoint = closestPointOnEdge;
            closestEdgeStart = start;
            closestEdgeEnd = end;
        }
    }

    return { closestPoint, closestEdgeStart, closestEdgeEnd };
}

const calculateDistance = (p1, p2, scaleFactor, unit) => {
    const dx = p2.x - p1.x;
    const dy = p2.y - p1.y;
    const pixelDistance = Math.sqrt(dx * dx + dy * dy);
    
    if (scaleFactor !== undefined && unit !== undefined) {
        const distanceInFeet = pixelDistance * scaleFactor;
        const roundedDistance = roundMeasurement(unit === 'ft' ? distanceInFeet : distanceInFeet * 0.3048);
        return roundedDistance;
    }
    
    return pixelDistance; // Return pixel distance if scaleFactor and unit are not provided
};

const formatDistance = (distance, unit) => {
    const roundedDistance = roundMeasurement(distance);
    if (unit === 'ft') {
        const feet = Math.floor(roundedDistance);
        const inches = Math.round((roundedDistance - feet) * 12);
        return `${feet}'${inches === 6 ? '6"' : inches === 0 ? '' : `${inches}"`}`;
    } else {
        return `${roundedDistance.toFixed(1)}m`;
    }
};

// Add these helper functions at the top of the file, after the existing helper functions

function drawLine(ctx, start, end, color = '#FF0000', width = 2) {
    ctx.beginPath();
    ctx.moveTo(start.x, start.y);
    ctx.lineTo(end.x, end.y);
    ctx.strokeStyle = color;
    ctx.lineWidth = width;
    ctx.stroke();
}

function drawDashedLine(ctx, start, end, color = '#FF0000', width = 2, dashArray = [5, 5]) {
    ctx.beginPath();
    ctx.setLineDash(dashArray);
    ctx.moveTo(start.x, start.y);
    ctx.lineTo(end.x, end.y);
    ctx.strokeStyle = color;
    ctx.lineWidth = width;
    ctx.stroke();
    ctx.setLineDash([]);
}

function drawCircle(ctx, center, radius, fillColor = '#FF0000', strokeColor = '#FFFFFF', lineWidth = 2) {
    ctx.beginPath();
    ctx.arc(center.x, center.y, radius, 0, 2 * Math.PI);
    ctx.fillStyle = fillColor;
    ctx.fill();
    ctx.strokeStyle = strokeColor;
    ctx.lineWidth = lineWidth;
    ctx.stroke();
}

function drawMeasurementLabel(ctx, start, end, distance, unit, color = '#000000', bgColor = '#FFFFFF') {
    const midX = (start.x + end.x) / 2;
    const midY = (start.y + end.y) / 2;
    const angle = Math.atan2(end.y - start.y, end.x - start.x);

    ctx.save();
    ctx.translate(midX, midY);
    ctx.rotate(angle);

    ctx.font = 'bold 12px Arial';
    const text = formatDistance(distance, unit);
    const textWidth = ctx.measureText(text).width;
    const padding = 4;

    // Draw background
    ctx.fillStyle = bgColor;
    ctx.fillRect(-textWidth / 2 - padding, -9, textWidth + padding * 2, 18);

    // Draw border
    ctx.strokeStyle = color;
    ctx.lineWidth = 1;
    ctx.strokeRect(-textWidth / 2 - padding, -9, textWidth + padding * 2, 18);

    // Draw text
    ctx.fillStyle = color;
    ctx.fillText(text, -textWidth / 2, 4);

    ctx.restore();
}

const MapSelector = ({ onLocationSelect, onCancel }) => {
    const mapRef = useRef(null);
    const searchInputRef = useRef(null);
    const scriptRef = useRef(null);
    const imageRef = useRef(null);
    const canvasRef = useRef(null);
    const [cameraMode, setCameraMode] = useState(false);
    const [photoTaken, setPhotoTaken] = useState(null);
    const [lastCenter, setLastCenter] = useState(null);
    const [lastZoom, setLastZoom] = useState(null);
    const [isDetectingRoof, setIsDetectingRoof] = useState(false);
    const [isMapInitialized, setIsMapInitialized] = useState(false);
    const [isPlottingPoints, setIsPlottingPoints] = useState(false);
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const [currentPolygon, setCurrentPolygon] = useState(null);
    const [polygons, setPolygons] = useState([]);
    const [isDrawing, setIsDrawing] = useState(false);
    const [measurementUnit, setMeasurementUnit] = useState('ft');
    const [scaleFactor, setScaleFactor] = useState(1);
    const [showTakePhotoButton, setShowTakePhotoButton] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [isFeetSelected, setIsFeetSelected] = useState(true);






    useEffect(() => {
        document.body.classList.add('map-selector-active');
        return () => {
            document.body.classList.remove('map-selector-active');
        };
    }, []);

    const initMap = useCallback(() => {
        if (mapRef.current) {
            const map = new window.google.maps.Map(mapRef.current, {
                center: { lat: 0, lng: 0 },
                zoom: 2,
                mapTypeId: 'satellite',
                tilt: 0,  
                scrollwheel: true,  
                gestureHandling: 'greedy'
            });

            map.addListener('tilesloaded', () => {
                setIsMapInitialized(true);
            });

            map.addListener('click', (event) => {
                if (isMapInitialized) {
                    const latLng = event.latLng.toJSON();
                    onLocationSelect(latLng);
                }
            });

            const autocomplete = new window.google.maps.places.Autocomplete(searchInputRef.current);
            autocomplete.bindTo('bounds', map);
            autocomplete.setFields(['geometry', 'name']);

            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (!place.geometry) {
                    console.error("No details available for input: '" + place.name + "'");
                    return;
                }

                map.setCenter(place.geometry.location);
                map.setZoom(20);
                map.setTilt(0); 
            });

            mapRef.current.mapInstance = map;
        }
    }, [isMapInitialized, onLocationSelect]);

    useEffect(() => {
        window.initMap = initMap;
        return () => {
            delete window.initMap;
        };
    }, [initMap]);

    useEffect(() => {
        if (!window.google) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDjVuooG3QamPIdshEvx5sbZ5UUdXDd4co&libraries=places`;
            script.async = true;
            script.defer = true;
            script.onload = initMap;
            document.body.appendChild(script);
        } else {
            initMap();
        }

        return () => {
            const script = document.querySelector('script[src^="https://maps.googleapis.com/maps/api/js"]');
            if (script) {
                script.remove();
            }
        };
    }, [initMap]);

    const toggleSearch = () => {
        setIsSearchActive((prev) => !prev);
        const searchContainer = document.querySelector('.search-container');
        const searchInput = document.querySelector('.search-input');
        
        searchContainer.classList.toggle('active');
        if (searchContainer.classList.contains('active')) {
          searchInput.style.width = '240px';
          searchInput.style.padding = '0 6px';
        } else {
          searchInput.style.width = '0px';
          searchInput.style.padding = '0';
        }
      };

      const handleSearchInputChange = (event) => {
        if (event.target.value !== '') {
            setIsSearchActive(true);
        }
      };
      
      const handleSearchInputFocus = () => {
        setIsSearchActive(true);
        const searchContainer = document.querySelector('.search-container');
        searchContainer.classList.add('active');
      };
      
      const handleSearchInputBlur = () => {
        setIsSearchActive(false);
        const searchContainer = document.querySelector('.search-container');
        const searchInput = document.querySelector('.search-input');
        if (searchInput.value === '') {
          searchContainer.classList.remove('active');
          searchInput.style.width = '0px';
          searchInput.style.padding = '0';
        }
      };

      const toggleCameraMode = useCallback(() => {
        setCameraMode((prevCameraMode) => {
            const newCameraMode = !prevCameraMode;
            setShowTakePhotoButton(newCameraMode);
            return newCameraMode;
        });
    }, []);

    useEffect(() => {
        const takePhotoButton = document.getElementById('take-photo-button');
        if (takePhotoButton) {
            if (showTakePhotoButton) {
                takePhotoButton.classList.add('visible');
                setTimeout(() => {
                    takePhotoButton.classList.add('animate');
                }, 50);
            } else {
                takePhotoButton.classList.remove('visible', 'animate');
            }
        }
    }, [showTakePhotoButton]);

    useEffect(() => {
        const boundBox = document.getElementById('bound-box');
        if (boundBox) {
            boundBox.style.display = cameraMode ? 'block' : 'none';
        }
    }, [cameraMode]);

    const takePhoto = useCallback(() => {
        if (cameraMode && mapRef.current && mapRef.current.mapInstance && isMapInitialized) {
            const map = mapRef.current.mapInstance;
            const center = map.getCenter();
            const zoom = map.getZoom();
            const size = '640x640';
            const mapType = 'satellite';
            const apiKey = 'AIzaSyDjVuooG3QamPIdshEvx5sbZ5UUdXDd4co';
    
            const photoUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${center.lat()},${center.lng()}&zoom=${zoom}&size=${size}&maptype=${mapType}&key=${apiKey}`;
    
            console.log("Photo URL generated:", photoUrl);
            setPhotoTaken(photoUrl);
            setLastCenter(center);
            setLastZoom(zoom);
    
            const metersPerPx = 156543.03392 * Math.cos(center.lat() * Math.PI / 180) / Math.pow(2, zoom);
            const feetPerPx = metersPerPx * 3.28084;
            setScaleFactor(feetPerPx);
    
            setTimeout(() => {
                if (canvasRef.current && imageRef.current) {
                    const ctx = canvasRef.current.getContext('2d');
                    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                    ctx.drawImage(imageRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
                    console.log("Canvas redrawn");
                } else {
                    console.log("Canvas or image ref not available");
                }
            }, 100);
        }
    }, [cameraMode, isMapInitialized]);

    const handleImageLoad = useCallback(() => {
        if (imageRef.current) {
            console.log("Image loaded, dimensions:", imageRef.current.naturalWidth, imageRef.current.naturalHeight);
            setImageDimensions({
                width: imageRef.current.naturalWidth,
                height: imageRef.current.naturalHeight
            });
            
            if (canvasRef.current) {
                canvasRef.current.width = imageRef.current.naturalWidth;
                canvasRef.current.height = imageRef.current.naturalHeight;
                const ctx = canvasRef.current.getContext('2d');
                ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                ctx.drawImage(imageRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
                console.log("Canvas redrawn after image load");
            } else {
                console.log("Canvas ref not available");
            }
        } else {
            console.log("Image ref not available");
        }
    }, []);

    const renderPhotoPreview = () => {
        console.log("renderPhotoPreview called, photoTaken:", photoTaken);
        if (!photoTaken) return null;
    
        return (
            <div className="photo-preview">
                <img 
                    ref={imageRef}
                    src={photoTaken} 
                    alt="Captured location" 
                    onLoad={handleImageLoad}
                    style={{ display: 'none' }}
                    crossOrigin="anonymous"
                />
                <canvas 
                    ref={canvasRef}
                    width={imageDimensions.width || 640}
                    height={imageDimensions.height || 640}
                    onClick={handleImageClick}
                    onMouseMove={handleMouseMove}
                    style={{ cursor: isPlottingPoints ? 'crosshair' : 'default' }}
                />
                {isDetectingRoof && (
                    <div className="detecting-roof">
                        <div className="spinner"></div>
                        <p>Detecting roof outline...</p>
                    </div>
                )}
                {!isDetectingRoof && !isPlottingPoints && (
                    <div className="photo-preview-buttons">
                        <button 
                            className="accept-button" 
                            onClick={startManualPointPlotting}
                        >
                            Plot Roof Points
                        </button>
                        <button className="retake-button" onClick={handleRetakePhoto}>Retake</button>
                    </div>
                )}
                {isPlottingPoints && (
                    <div className="photo-preview-buttons">
                        <button 
                            className="accept-button" 
                            onClick={finishManualPointPlotting}
                        >
                            Finish Plotting
                        </button>
                        <button className="retake-button" onClick={handleRetakePhoto}>Retake</button>
                        <button className="undo-button" onClick={undoLastPoint}>Undo Last Point</button>
                        <div className="unit-toggle-container">
                            <button className="unit-toggle-button" onClick={toggleMeasurementUnit}>
                                <span className="unit-toggle-label">{measurementUnit === 'ft' ? 'ft' : 'm'}</span>
                                <div className={`unit-toggle-switch ${measurementUnit === 'ft' ? 'feet' : 'meters'}`}>
                                    <div className="unit-toggle-slider"></div>
                                </div>
                            </button>
                        </div>
                    </div>
                )}
                {isPlottingPoints && (
                    <div className="plotting-mode-indicator">
                        Plotting Mode Active
                    </div>
                )}
            </div>
        );
    };

    const drawPolygons = useCallback(() => {
        if (!canvasRef.current) return;
    
        const ctx = canvasRef.current.getContext('2d');
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    
        if (imageRef.current) {
            ctx.drawImage(imageRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
        }
    
        const drawPolygon = (polygon) => {
            polygon.points.forEach((point, index) => {
                drawCircle(ctx, point, 4, polygon.color, '#FFFFFF');

                if (index > 0) {
                    const prevPoint = polygon.points[index - 1];
                    drawLine(ctx, prevPoint, point, polygon.color, 2);
                    
                    const distance = calculateDistance(prevPoint, point, scaleFactor, measurementUnit);
                    if (distance > 0.5) {
                        drawMeasurementLabel(ctx, prevPoint, point, distance, measurementUnit);
                    }
                }
            });

            if (polygon.closed) {
                const firstPoint = polygon.points[0];
                const lastPoint = polygon.points[polygon.points.length - 1];
                drawLine(ctx, lastPoint, firstPoint, polygon.color, 2);

                const distance = calculateDistance(lastPoint, firstPoint, scaleFactor, measurementUnit);
                if (distance > 0.5) {
                    drawMeasurementLabel(ctx, lastPoint, firstPoint, distance, measurementUnit);
                }

                ctx.fillStyle = polygon.color + '20';
                ctx.fill();
            }
        };
    
        polygons.forEach(drawPolygon);
        if (currentPolygon) drawPolygon(currentPolygon);
    }, [polygons, currentPolygon, measurementUnit, scaleFactor]);

    const handleImageClick = useCallback((event) => {
        if (!isPlottingPoints) {
            return;
        }
    
        const rect = canvasRef.current.getBoundingClientRect();
        const scaleX = canvasRef.current.width / rect.width;
        const scaleY = canvasRef.current.height / rect.height;
    
        const x = Math.round((event.clientX - rect.left) * scaleX);
        const y = Math.round((event.clientY - rect.top) * scaleY);
    
        if (!isDrawing) {
            setCurrentPolygon({ points: [{ x, y }], color: '#FF0000' });
            setIsDrawing(true);
        } else {
            setCurrentPolygon((prev) => {
                const newPoints = [...prev.points];
                const clickPoint = { x, y };
    
                // Check if we're closing the polygon
                if (newPoints.length > 2 && calculateDistance(newPoints[0], clickPoint) < 20) {
                    const { closestPoint, closestEdgeStart, closestEdgeEnd } = findClosestEdge(newPoints, clickPoint);
    
                    // If the closest point is very close to an existing vertex, snap to that vertex
                    const snapThreshold = 10;
                    const closestVertex = [closestEdgeStart, closestEdgeEnd].find(
                        vertex => calculateDistance(vertex, closestPoint) < snapThreshold
                    );
    
                    if (closestVertex) {
                        newPoints.push(closestVertex);
                    } else {
                        // Otherwise, add the closest point on the edge
                        newPoints.push(closestPoint);
                    }
    
                    const closedPolygon = { ...prev, points: newPoints, closed: true };
                    setPolygons((prevPolygons) => [...prevPolygons, closedPolygon]);
                    setIsDrawing(false);
                    return null;
                } else {
                    // If not closing, add the clicked point normally
                    return { ...prev, points: [...newPoints, clickPoint] };
                }
            });
        }
        
        drawPolygons();
    }, [isPlottingPoints, isDrawing, drawPolygons]);

    useEffect(() => {
        drawPolygons();
    }, [drawPolygons]);

    useEffect(() => {
        if (photoTaken) {
            drawPolygons();
        }
    }, [photoTaken, drawPolygons]);




    const startManualPointPlotting = useCallback(() => {
        console.log("startManualPointPlotting called, photoTaken:", photoTaken);
        if (!photoTaken) return;
    
        const img = new Image();
        img.onload = () => {
            console.log("Image loaded, dimensions:", img.naturalWidth, img.naturalHeight);
            setImageDimensions({
                width: img.naturalWidth,
                height: img.naturalHeight
            });
            if (canvasRef.current) {
                canvasRef.current.width = img.naturalWidth;
                canvasRef.current.height = img.naturalHeight;
                const ctx = canvasRef.current.getContext('2d');
                ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
                ctx.drawImage(img, 0, 0, canvasRef.current.width, canvasRef.current.height);
                console.log("Image drawn on canvas");
            }
    
            setIsPlottingPoints(true);
            setPolygons([]);
            setCurrentPolygon(null);
            setIsDrawing(false);
        };
        img.onerror = (error) => {
            console.error("Error loading image:", error);
            setIsPlottingPoints(true);
            setPolygons([]);
            setCurrentPolygon(null);
            setIsDrawing(false);
        };
        img.src = photoTaken;
    }, [photoTaken]);

    function removeDuplicateShapes(shapes) {
        // Create a map to store unique shapes
        const uniqueShapes = new Map();
    
        shapes.forEach(shape => {
            // Convert the points array to a JSON string for comparison
            const key = JSON.stringify(shape.points);
    
            // Add the shape to the map if it doesn't exist already
            if (!uniqueShapes.has(key)) {
                uniqueShapes.set(key, shape);
            }
        });
    
        // Convert the map back to an array
        return Array.from(uniqueShapes.values());
    }

    const finishManualPointPlotting = useCallback(() => {
        let modifiedPolygons = removeDuplicateShapes(polygons);
    
        if (modifiedPolygons.length === 0) {
            alert("Please create at least one polygon to define the roof outline.");
            return;
        }
    
        setIsPlottingPoints(false);
        setIsDetectingRoof(true);
        const map = mapRef.current.mapInstance;
        const center = map.getCenter();
        const zoom = map.getZoom();
    
        const payload = { 
            lat: center.lat(),
            lng: center.lng(),
            center: { lat: center.lat(), lng: center.lng() },
            photoUrl: photoTaken,
            zoom: zoom,
            polygons: modifiedPolygons.map((polygon) => ({
                points: polygon.points.map((point, index) => {
                    if (index === 0) {
                        return { ...point, distance: 0 };
                    }
                    const prevPoint = polygon.points[index - 1];
                    const distance = calculateDistance(prevPoint, point, scaleFactor, measurementUnit);
                    return { ...point, distance: roundMeasurement(distance) };
                })
            }))
        };
    
        console.log("Sending payload to parent:", payload);
        onLocationSelect(payload);
    }, [polygons, photoTaken, onLocationSelect, scaleFactor, measurementUnit]);

    const undoLastPoint = useCallback(() => {
        setPolygons((prevPolygons) => {
            let updatedPolygons = [...prevPolygons];
            let updatedCurrentPolygon = currentPolygon ? {...currentPolygon} : null;
    
            if (updatedCurrentPolygon) {
                // We're currently drawing a polygon
                if (updatedCurrentPolygon.points.length > 1) {
                    // Remove the last point
                    updatedCurrentPolygon.points.pop();
                    updatedCurrentPolygon.closed = false;
                    setCurrentPolygon(updatedCurrentPolygon);
                } else {
                    // If only one point left, remove the current polygon entirely
                    setCurrentPolygon(null);
                    setIsDrawing(false);
                }
            } else if (updatedPolygons.length > 0) {
                // We're not currently drawing, so modify the last completed polygon
                let lastPolygon = updatedPolygons.pop();
                if (lastPolygon.closed) {
                    // If the polygon is closed, reopen it
                    lastPolygon.closed = false;
                    updatedCurrentPolygon = lastPolygon;
                    setIsDrawing(true);
                } else if (lastPolygon.points.length > 1) {
                    // Remove the last point
                    lastPolygon.points.pop();
                    updatedCurrentPolygon = lastPolygon;
                    setIsDrawing(true);
                } else {
                    // If only one point left, don't add it back to currentPolygon
                    setIsDrawing(false);
                }
                setCurrentPolygon(updatedCurrentPolygon);
            }
    
            // Ensure we're not left with any empty polygons
            updatedPolygons = updatedPolygons.filter(polygon => polygon.points.length > 1);
    
            return updatedPolygons;
        });
    }, [currentPolygon]);

    const handleRetakePhoto = useCallback(() => {
        setPhotoTaken(null);
        setPolygons([]);
        setCurrentPolygon(null);
        setIsPlottingPoints(false);
        setIsDrawing(false);
        if (mapRef.current && mapRef.current.mapInstance && lastCenter && lastZoom) {
            const map = mapRef.current.mapInstance;
            map.setCenter(lastCenter);
            map.setZoom(lastZoom);
        }
    }, [lastCenter, lastZoom]);

    const highlightClosestPointOrEdge = useCallback((mousePoint) => {
        if (!currentPolygon || currentPolygon.points.length < 2) return;

        const ctx = canvasRef.current.getContext('2d');
        const points = currentPolygon.points;
        let closestPoint = null;
        let closestDistance = Infinity;

        // Check distance to points
        points.forEach((point) => {
            const distance = calculateDistance(point, mousePoint);
            if (distance < closestDistance && distance < 10) {
                closestDistance = distance;
                closestPoint = point;
            }
        });

        // Check distance to edges
        for (let i = 0; i < points.length; i++) {
            const start = points[i];
            const end = points[(i + 1) % points.length];
            const closestPointOnEdge = findClosestPointOnLine(start, end, mousePoint);
            const distance = calculateDistance(mousePoint, closestPointOnEdge);

            if (distance < closestDistance && distance < 10) {
                closestDistance = distance;
                closestPoint = closestPointOnEdge;
            }
        }

        if (closestPoint) {
            drawCircle(ctx, closestPoint, 6, 'rgba(255, 255, 0, 0.5)', '#FFFF00', 2);
        }
    }, [currentPolygon, calculateDistance]);

    const handleMouseMove = useCallback((event) => {
        if (!isPlottingPoints || !isDrawing || !currentPolygon) return;
    
        const rect = canvasRef.current.getBoundingClientRect();
        const scaleX = canvasRef.current.width / rect.width;
        const scaleY = canvasRef.current.height / rect.height;
    
        const x = Math.round((event.clientX - rect.left) * scaleX);
        const y = Math.round((event.clientY - rect.top) * scaleY);
    
        drawPolygons();
    
        const ctx = canvasRef.current.getContext('2d');
        const lastPoint = currentPolygon.points[currentPolygon.points.length - 1];
        ctx.beginPath();
        ctx.moveTo(lastPoint.x, lastPoint.y);
        ctx.lineTo(x, y);
        ctx.strokeStyle = currentPolygon.color;
        ctx.lineWidth = 2;
        ctx.stroke();
    
        const midX = (lastPoint.x + x) / 2;
        const midY = (lastPoint.y + y) / 2;
        const distance = calculateDistance(lastPoint, { x, y }, scaleFactor, measurementUnit);
        const roundedDistance = roundMeasurement(distance);
        if (roundedDistance > 0.5) {
            const angle = Math.atan2(y - lastPoint.y, x - lastPoint.x);
        
            ctx.save();
            ctx.translate(midX, midY);
            ctx.rotate(angle);
        
            ctx.font = '12px Arial';
            const text = formatDistance(roundedDistance, measurementUnit);
            const textWidth = ctx.measureText(text).width;
        
            ctx.fillStyle = 'white';
            ctx.fillRect(-textWidth / 2 - 2, -8, textWidth + 4, 16);
            ctx.strokeStyle = 'black';
            ctx.strokeRect(-textWidth / 2 - 2, -8, textWidth + 4, 16);
        
            ctx.fillStyle = 'black';
            ctx.fillText(text, -textWidth / 2, 4);
        
            ctx.restore();
        }

        highlightClosestPointOrEdge({ x, y });
    }, [isPlottingPoints, isDrawing, currentPolygon, drawPolygons, calculateDistance, measurementUnit, scaleFactor, highlightClosestPointOrEdge]);
    
    const toggleMeasurementUnit = useCallback(() => {
        setIsFeetSelected(prev => !prev);
        setMeasurementUnit(prev => prev === 'ft' ? 'm' : 'ft');
    }, []);

    return (
        <div className="map-selector-container">
            <div ref={mapRef} className="map-container" />
            <div className="search-container">
                <input 
                    ref={searchInputRef}
                    type="text" 
                    className="search-input"
                    placeholder="Search location"
                    onFocus={handleSearchInputFocus}
                    onBlur={handleSearchInputBlur}
                    onChange={handleSearchInputChange}
                />
                <a className="search-btn" onClick={toggleSearch}>
                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                </a>
            </div>
            <div id="bound-box" className="bound-box" style={{ display: 'none' }}></div>
            
            <div className="button-container">
                <button id="camera-button" className="map-button" onClick={toggleCameraMode}>
                    <FontAwesomeIcon icon={cameraMode ? faMap : faCamera} className="button-icon" />
                    {cameraMode ? 'Map Mode' : 'Camera Mode'}
                </button>
                <button id="cancel-button" className="map-button" onClick={onCancel}>
                    <FontAwesomeIcon icon={faCancel} className="button-icon" />
                    Cancel
                </button>
            </div>
            
            {cameraMode && (
                <button id="take-photo-button" className="map-button" onClick={takePhoto}>
                    <FontAwesomeIcon icon={faCamera} className="button-icon" />
                    Take Photo
                </button>
            )}
            
            {photoTaken && (
                <div className="photo-preview">
                    <img 
                        ref={imageRef}
                        src={photoTaken} 
                        alt="Captured location" 
                        onLoad={handleImageLoad}
                        style={{ display: 'none' }}
                        crossOrigin="anonymous"
                    />
                    <canvas 
                        ref={canvasRef}
                        width={imageDimensions.width || 640}
                        height={imageDimensions.height || 640}
                        onClick={handleImageClick}
                        onMouseMove={handleMouseMove}
                        style={{ cursor: isPlottingPoints ? 'crosshair' : 'default' }}
                    />
                    {isDetectingRoof && (
                        <div className="detecting-roof">
                            <div className="spinner"></div>
                            <p>Detecting roof outline...</p>
                        </div>
                    )}
                    {!isDetectingRoof && !isPlottingPoints && (
                        <div className="photo-preview-buttons">
                            <button 
                                className="accept-button" 
                                onClick={startManualPointPlotting}
                            >
                                Plot Roof Points
                            </button>
                            <button className="retake-button" onClick={handleRetakePhoto}>Retake</button>
                            {isPlottingPoints && (
                            <div className="unit-toggle-container">
                                <button className="unit-toggle-button" onClick={toggleMeasurementUnit}>
                                    <span className="unit-toggle-label">{isFeetSelected ? 'ft' : 'm'}</span>
                                    <div className={`unit-toggle-switch ${isFeetSelected ? 'feet' : 'meters'}`}>
                                        <div className="unit-toggle-slider"></div>
                                    </div>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                    {isPlottingPoints && (
                        <div className="photo-preview-buttons">
                            <button 
                                className="accept-button" 
                                onClick={finishManualPointPlotting}
                            >
                                Finish Plotting
                            </button>
                            <button className="retake-button" onClick={handleRetakePhoto}>Retake</button>
                            <button className="undo-button" onClick={undoLastPoint}>Undo Last Point</button>
                            <div className="unit-toggle-container">
                                <button className="unit-toggle-button" onClick={toggleMeasurementUnit}>
                                    <span className="unit-toggle-label">{isFeetSelected ? 'ft' : 'm'}</span>
                                    <div className={`unit-toggle-switch ${isFeetSelected ? 'feet' : 'meters'}`}>
                                        <div className="unit-toggle-slider"></div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    )}
                    {isPlottingPoints && (
                        <div className="plotting-mode-indicator">
                            Plotting Mode Active
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}


export default MapSelector;