import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { ReactComponent as DynamicDraftsLogo } from './DynamicDraftsLogo.svg';
import { supabase } from './supabaseClient.js';

const UserManagement = () => {
    const user = useSelector(state => state.auth.user);
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newUserEmail, setNewUserEmail] = useState('');
    const [isLogoHovered, setIsLogoHovered] = useState(false);

    useEffect(() => {
        if (!user) {
            navigate('/login');
        } else {
            fetchUsers();
        }
    }, [user, navigate]);

    const fetchUsers = async () => {
        setIsLoading(true);
        try {
            const { data, error } = await supabase
                .from('accounts')
                .select('*')
                .eq('business_id', user.id);

            if (error) throw error;
            setUsers(data);
        } catch (error) {
            setError('Failed to fetch users. Please try again.');
            console.error('Error fetching users:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddUser = async (e) => {
        e.preventDefault();
        try {
            const { data, error } = await supabase.auth.signUp({
                email: newUserEmail,
                password: Math.random().toString(36).slice(-8), // Generate a random password
            });

            if (error) throw error;

            await supabase.from('accounts').update({
                business_id: user.id,
                account_type: 'individual'
            }).eq('id', data.user.id);

            setNewUserEmail('');
            fetchUsers();
        } catch (error) {
            setError('Failed to add user. Please try again.');
            console.error('Error adding user:', error);
        }
    };

    const handleRemoveUser = async (userId) => {
        try {
            const { error } = await supabase
                .from('accounts')
                .update({ business_id: null })
                .eq('id', userId);

            if (error) throw error;
            fetchUsers();
        } catch (error) {
            setError('Failed to remove user. Please try again.');
            console.error('Error removing user:', error);
        }
    };

    const logoLinkStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '8px',
        transition: 'all 0.3s ease',
        textDecoration: 'none',
        color: 'white',
    };

    const logoStyle = {
        width: '100%',
        height: 'auto',
        transition: 'transform 0.3s ease',
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div style={{display: 'flex', height: '100vh', backgroundColor: '#f3f4f6'}}>
            <aside style={{width: '16rem', background: 'linear-gradient(to bottom, #4338ca, #3730a3)', color: 'white'}}>
                <div style={{padding: '1.5rem'}}>
                    <Link 
                        to="/dashboard" 
                        style={{
                            ...logoLinkStyle,
                            transform: isLogoHovered ? 'scale(1.05)' : 'scale(1)',
                            boxShadow: isLogoHovered ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none',
                            backgroundColor: isLogoHovered ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                        }}
                        onMouseEnter={() => setIsLogoHovered(true)}
                        onMouseLeave={() => setIsLogoHovered(false)}>
                        <DynamicDraftsLogo style={logoStyle} />
                        <span style={{
                            marginTop: '0.5rem',
                            fontSize: '0.875rem',
                            opacity: isLogoHovered ? 1 : 0,
                            transition: 'opacity 0.3s ease',
                        }}>
                            Back to Dashboard
                        </span>
                    </Link>
                </div>
            </aside>
            <main style={{flex: 1, overflowY: 'auto', padding: '2rem'}}>
                <div style={{maxWidth: '48rem', margin: '0 auto'}}>
                    <h1 style={{fontSize: '2.25rem', fontWeight: 'bold', marginBottom: '2rem', color: '#1f2937'}}>User Management</h1>
                    {error && <p style={{color: '#ef4444', marginBottom: '1rem'}}>{error}</p>}
                    <div style={{backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)', padding: '2rem', marginBottom: '2rem'}}>
                        <h2 style={{fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem', color: '#1f2937'}}>Add New User</h2>
                        <form onSubmit={handleAddUser} style={{display: 'flex', gap: '1rem'}}>
                            <input
                                type="email"
                                value={newUserEmail}
                                onChange={(e) => setNewUserEmail(e.target.value)}
                                placeholder="Enter email address"
                                required
                                style={{flex: 1, padding: '0.5rem', borderRadius: '0.25rem', border: '1px solid #d1d5db'}}
                            />
                            <button
                                type="submit"
                                style={{
                                    backgroundColor: '#10b981',
                                    color: 'white',
                                    padding: '0.5rem 1rem',
                                    borderRadius: '0.25rem',
                                    border: 'none',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.2s',
                                }}
                            >
                                Add User
                            </button>
                        </form>
                    </div>
                    <div style={{backgroundColor: 'white', borderRadius: '0.5rem', boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)', padding: '2rem'}}>
                        <h2 style={{fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem', color: '#1f2937'}}>Managed Users</h2>
                        {users.length === 0 ? (
                            <p>No users found.</p>
                        ) : (
                            <ul style={{listStyleType: 'none', padding: 0}}>
                                {users.map((user) => (
                                    <li key={user.id} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem', borderBottom: '1px solid #e5e7eb'}}>
                                        <div>
                                            <p style={{fontWeight: 'bold'}}>{user.email}</p>
                                            <p style={{fontSize: '0.875rem', color: '#6b7280'}}>{user.name || 'No name provided'}</p>
                                        </div>
                                        <button
                                            onClick={() => handleRemoveUser(user.id)}
                                            style={{
                                                backgroundColor: '#ef4444',
                                                color: 'white',
                                                padding: '0.5rem 1rem',
                                                borderRadius: '0.25rem',
                                                border: 'none',
                                                cursor: 'pointer',
                                                transition: 'background-color 0.2s',
                                            }}
                                        >
                                            Remove
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default UserManagement;
