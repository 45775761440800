import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { supabase } from './supabaseClient.js';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #1a202c, #2d3748);
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  padding: 2rem;
`;

const FormContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
`;

const InputGroup = styled.div`
  margin-bottom: 1rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: #e2e8f0;
  }

  input {
    width: 100%;
    padding: 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid #4a5568;
    background-color: #2d3748;
    color: #ffffff;
    font-size: 1rem;

    &:focus {
      outline: none;
      border-color: #4299e1;
    }
  }
`;

const ResetButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background-color: #4299e1;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3182ce;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }
`;

const ResetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email);
      if (error) throw error;
      setMessage('Password reset email sent. Please check your inbox.');
    } catch (error) {
      setMessage(error.message);
    }
  };

  return (
    <PageContainer>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <FormContainer>
          <h2 className="text-3xl font-bold mb-6 text-white text-center">Reset Password</h2>
          <form onSubmit={handleResetPassword}>
            <InputGroup>
              <label htmlFor="email">Email</label>
              <div className="relative">
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <FontAwesomeIcon icon={faLock} className="absolute right-3 top-3 text-gray-400" />
              </div>
            </InputGroup>
            <ResetButton type="submit">
              Reset Password
            </ResetButton>
          </form>
          {message && <p className="mt-4 text-center text-sm text-gray-300">{message}</p>}
          <p className="mt-4 text-center text-sm text-gray-400">
            Remember your password?{' '}
            <button
              onClick={() => navigate('/login')}
              className="text-blue-500 hover:text-blue-400"
            >
              Log in
            </button>
          </p>
        </FormContainer>
      </motion.div>
    </PageContainer>
  );
};

export default ResetPasswordPage;
