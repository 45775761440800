import React, { useRef, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import { motion } from 'framer-motion';
import DynamicDraftsLogo from './DynamicDraftsLogo.svg';

const GlobalStyle = createGlobalStyle`
  html {
    overflow: auto;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', 'Roboto', sans-serif;
    line-height: 1.6;
    overflow-x: hidden;
    background: #0F172A;
  }
`;

const PageContainer = styled.div`
  background: linear-gradient(135deg, #0F172A, #1E293B);
  color: #ffffff;
  min-height: 100vh;
  position: relative;
  z-index: 1;
`;

const Header = styled.header`
  background: rgba(15, 23, 42, 0.8);
  backdrop-filter: blur(10px);
  padding: 1rem 0;
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease;

  &.scrolled {
    padding: 0.8rem 0;
    background: rgba(15, 23, 42, 0.95);
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const Logo = styled.img`
  height: 40px;
  width: auto;
  transition: transform 0.3s ease;
  filter: brightness(0) invert(1);

  &:hover {
    transform: scale(1.05);
  }
`;

const NavList = styled.ul`
  display: flex;
  list-style: none;
  gap: 2.5rem;
  align-items: center;
  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  position: relative;
`;

const NavLink = styled.a`
  color: #E2E8F0;
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  transition: all 0.3s ease;
  padding: 0.5rem 0;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background: #60A5FA;
    transition: width 0.3s ease;
  }

  &:hover {
    color: #60A5FA;
    
    &:after {
      width: 100%;
    }
  }
`;

const Button = styled(Link)`
  display: inline-block;
  padding: 0.8rem 1.8rem;
  border-radius: 8px;
  background: linear-gradient(135deg, #60A5FA, #3B82F6);
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(59, 130, 246, 0.3);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(59, 130, 246, 0.4);
    background: linear-gradient(135deg, #3B82F6, #2563EB);
  }

  &:active {
    transform: translateY(0);
  }
`;

const HeroSection = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  padding: 8rem 0;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 50% 50%, rgba(59, 130, 246, 0.1) 0%, rgba(15, 23, 42, 0) 50%);
  }
`;

const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  position: relative;
  z-index: 2;
`;

const HeroContent = styled(motion.div)`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

const HeroTitle = styled.h1`
  font-size: 4.5rem;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #FFFFFF 0%, #60A5FA 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) {
    font-size: 3rem;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.25rem;
  color: #E2E8F0;
  margin-bottom: 2.5rem;
  line-height: 1.8;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
`;

const Section = styled.section`
  padding: 6rem 0;
  position: relative;
  overflow: hidden;

  h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 3rem;
    text-align: center;
    background: linear-gradient(135deg, #FFFFFF 0%, #60A5FA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const SecondaryButton = styled(Button)`
  background: transparent;
  border: 2px solid #60A5FA;
  box-shadow: none;

  &:hover {
    background: rgba(96, 165, 250, 0.1);
    transform: translateY(-2px);
  }
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-top: 3rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const FeatureCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 2rem;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    background: rgba(255, 255, 255, 0.08);
    border-color: rgba(96, 165, 250, 0.4);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, #60A5FA, #3B82F6);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
  }
`;

const FeatureIcon = styled.div`
  width: 64px;
  height: 64px;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(96, 165, 250, 0.1);
  border-radius: 12px;
  color: #60A5FA;
`;

const FeatureTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #FFFFFF;
`;

const FeatureDescription = styled.p`
  color: #E2E8F0;
  line-height: 1.7;
  margin-bottom: 1.5rem;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FeatureListItem = styled.li`
  display: flex;
  align-items: center;
  color: #E2E8F0;
  margin-bottom: 0.75rem;
  font-size: 0.95rem;

  &:before {
    content: '✓';
    color: #60A5FA;
    margin-right: 0.75rem;
    font-weight: bold;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto 2rem;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  display: block;
`;

const FeatureWithVideo = ({ title, description }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h3>{title}</h3>
      <VideoContainer>
        <StyledVideo
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
        >
          <source src="/example.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </StyledVideo>
      </VideoContainer>
      <p>{description}</p>
    </motion.div>
  );
};

const AnimatedBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.2;  // Increased opacity
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AnimatedCADBackground = () => {
  const [dots, setDots] = useState([]);

  useEffect(() => {
    // Create initial dots
    const initialDots = Array.from({ length: 20 }, () => ({
      x: Math.random() * 100,
      y: Math.random() * 100,
      vx: (Math.random() - 0.5) * 0.3,
      vy: (Math.random() - 0.5) * 0.3,
    }));
    setDots(initialDots);

    // Animation loop
    const animationFrame = requestAnimationFrame(function animate() {
      setDots(prevDots => prevDots.map(dot => ({
        x: (dot.x + dot.vx + 100) % 100,
        y: (dot.y + dot.vy + 100) % 100,
        vx: dot.vx,
        vy: dot.vy,
      })));
      requestAnimationFrame(animate);
    });

    // Cleanup
    return () => cancelAnimationFrame(animationFrame);
  }, []);

  return (
    <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <pattern id="grid" width="10" height="10" patternUnits="userSpaceOnUse">
          <path d="M 10 0 L 0 0 0 10" fill="none" stroke="#4299e1" strokeWidth="0.1" strokeOpacity="0.5" />
        </pattern>
      </defs>
      <rect width="100" height="100" fill="url(#grid)" />
      {dots.map((dot, index) => (
        <circle
          key={index}
          cx={dot.x}
          cy={dot.y}
          r="0.3"
          fill="#4299e1"
          fillOpacity="0.7"
        />
      ))}
    </svg>
  );
};

const PricingCard = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 2rem 1.5rem;
  text-align: center;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  color: #fff;

  &:hover {
    transform: translateY(-5px);
  }

  h3 {
    color: #4299e1;
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  .subtitle {
    color: #a0aec0;
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .price {
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 0.5rem;
  }

  .period {
    color: #a0aec0;
    font-size: 1rem;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 1.5rem 0;
    text-align: left;
  }

  li {
    padding: 0.75rem 0;
    color: #e2e8f0;
    font-size: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  max-width: 900px;
  margin: 0 auto;
  padding: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 1rem 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const LandingPage = () => {
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);
  const contactRef = useRef(null);
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);

  const scrollToSection = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <GlobalStyle />
      <PageContainer>
        <Header className={scrolled ? 'scrolled' : ''}>
          <Nav>
            <Logo src={DynamicDraftsLogo} alt="DynamicDrafts" />
            <NavList>
              <NavItem>
                <NavLink href="#" onClick={(e) => {
                  e.preventDefault();
                  scrollToSection(featuresRef);
                }}>
                  Features
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#" onClick={(e) => {
                  e.preventDefault();
                  scrollToSection(pricingRef);
                }}>
                  Pricing
                </NavLink>
              </NavItem>
              <NavItem><Button to="/login">Log In</Button></NavItem>
            </NavList>
          </Nav>
        </Header>

        <main>
          <HeroSection>
            <Container>
              <HeroContent
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
              >
                <HeroTitle>Measure Roofs in Minutes, Not Hours</HeroTitle>
                <HeroSubtitle>
                  The ultimate CAD tool designed specifically for Insurance Adjusters, Home Inspectors, and Roofers. 
                  Get accurate measurements, detailed reports, and professional diagrams in minutes, not hours.
                </HeroSubtitle>
                <ButtonGroup>
                  <Button to="/signup">Get Started</Button>
                  <SecondaryButton to="/demo">Watch Demo</SecondaryButton>
                </ButtonGroup>
              </HeroContent>
            </Container>
            <AnimatedBackground>
              <AnimatedCADBackground />
            </AnimatedBackground>
          </HeroSection>

          <Section ref={featuresRef}>
            <Container>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              >
                <h2>Smart Roof Measurement Tools</h2>
                <FeatureGrid>
                  <FeatureCard
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                  >
                    <FeatureIcon>
                      <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                        <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"/>
                      </svg>
                    </FeatureIcon>
                    <FeatureTitle>Satellite Mapping</FeatureTitle>
                    <FeatureDescription>
                      Access and measure any roof using satellite imagery.
                    </FeatureDescription>
                    <FeatureList>
                      <FeatureListItem>Select any property location</FeatureListItem>
                      <FeatureListItem>High-resolution satellite views</FeatureListItem>
                      <FeatureListItem>Easy zoom and pan controls</FeatureListItem>
                      <FeatureListItem>Save location data</FeatureListItem>
                    </FeatureList>
                  </FeatureCard>

                  <FeatureCard
                    initial={{ opacity: 0, x: 20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    <FeatureIcon>
                      <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                        <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/>
                      </svg>
                    </FeatureIcon>
                    <FeatureTitle>Measurement Tools</FeatureTitle>
                    <FeatureDescription>
                      Simple and accurate tools to measure roof dimensions.
                    </FeatureDescription>
                    <FeatureList>
                      <FeatureListItem>Point-to-point measurements</FeatureListItem>
                      <FeatureListItem>Area calculations</FeatureListItem>
                      <FeatureListItem>Length and width tools</FeatureListItem>
                      <FeatureListItem>Angle measurements</FeatureListItem>
                    </FeatureList>
                  </FeatureCard>

                  <FeatureCard
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.3 }}
                  >
                    <FeatureIcon>
                      <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"/>
                        <path d="M14 2v6h6"/>
                        <path d="M16 13H8"/>
                        <path d="M16 17H8"/>
                        <path d="M10 9H8"/>
                      </svg>
                    </FeatureIcon>
                    <FeatureTitle>Project Management</FeatureTitle>
                    <FeatureDescription>
                      Keep your roof measurements organized and accessible.
                    </FeatureDescription>
                    <FeatureList>
                      <FeatureListItem>Save project details</FeatureListItem>
                      <FeatureListItem>Export measurement data</FeatureListItem>
                      <FeatureListItem>Project history</FeatureListItem>
                      <FeatureListItem>Basic report creation</FeatureListItem>
                    </FeatureList>
                  </FeatureCard>

                  <FeatureCard
                    initial={{ opacity: 0, x: 20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                  >
                    <FeatureIcon>
                      <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"/>
                        <path d="M3 9h18"/>
                        <path d="M9 21V9"/>
                      </svg>
                    </FeatureIcon>
                    <FeatureTitle>User Interface</FeatureTitle>
                    <FeatureDescription>
                      Simple and intuitive design for easy navigation.
                    </FeatureDescription>
                    <FeatureList>
                      <FeatureListItem>Easy-to-use toolbar</FeatureListItem>
                      <FeatureListItem>Clear measurement display</FeatureListItem>
                      <FeatureListItem>Undo/redo functionality</FeatureListItem>
                      <FeatureListItem>Quick tool switching</FeatureListItem>
                    </FeatureList>
                  </FeatureCard>
                </FeatureGrid>
              </motion.div>
            </Container>
          </Section>

          <Section ref={pricingRef}>
            <Container>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8 }}
              >
                <h2 style={{ fontSize: '2.5rem', marginBottom: '3rem', textAlign: 'center' }}>Simple Pricing Plans</h2>
                <PricingGrid>
                  <PricingCard
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                  >
                    <h3>Basic</h3>
                    <div className="subtitle">For individual users</div>
                    <div className="price">$12.99<span className="period">/month</span></div>
                    <ul>
                      <li>Single user account</li>
                      <li>Basic roof measurement tools</li>
                      <li>Satellite view access</li>
                      <li>Area and length calculations</li>
                      <li>Save and load projects</li>
                      <li>Basic PDF exports</li>
                      <li>Standard email support</li>
                    </ul>
                    <Button to="/signup">Get Started</Button>
                  </PricingCard>

                  <PricingCard
                    initial={{ opacity: 0, x: 20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    <h3>Professional</h3>
                    <div className="subtitle">For small teams</div>
                    <div className="price">$99.99<span className="period">/month</span></div>
                    <ul>
                      <li>Up to 10 user accounts</li>
                      <li>All Basic features included</li>
                      <li>Team project sharing</li>
                      <li>Bulk project management</li>
                      <li>Priority email support</li>
                      <li>API access</li>
                      <li>Project data backup</li>
                    </ul>
                    <Button to="/signup">Get Started</Button>
                    <div style={{ 
                      fontSize: '0.9rem', 
                      color: '#4299e1', 
                      marginTop: '1rem',
                      fontStyle: 'italic'
                    }}>
                      Additional users $20/month each
                    </div>
                  </PricingCard>
                </PricingGrid>
              </motion.div>
            </Container>
          </Section>

          <Section ref={contactRef}>
            <Container>
              <h2 style={{ fontSize: '2.5rem', marginBottom: '1.5rem', textAlign: 'center' }}>Contact Us</h2>
              <p style={{ fontSize: '1.2rem', marginBottom: '1rem', textAlign: 'center' }}>Get in touch with us via email:</p>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <motion.a 
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = 'mailto:info@DynamicDrafts.io';
                  }}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  style={{ 
                    color: '#4299e1', 
                    textDecoration: 'none', 
                    padding: '0.5rem',
                    borderRadius: '50%',
                    backgroundColor: 'transparent',
                    border: '2px solid #4299e1',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '60px',
                    height: '60px',
                    transition: 'all 0.3s ease'
                  }}
                >
                  <motion.svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    initial={{ rotate: 0 }}
                    animate={{ rotate: 360 }}
                    transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                  >
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                    <polyline points="22,6 12,13 2,6"></polyline>
                  </motion.svg>
                </motion.a>
              </div>
            </Container>
          </Section>
        </main>

        <footer>
          <Container>
            <p>&copy; 2023 DynamicDrafts. All rights reserved.</p>
          </Container>
        </footer>
      </PageContainer>
    </>
  );
};

export default LandingPage;