import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from './store.js';
import { motion } from 'framer-motion';
import styled, { createGlobalStyle } from 'styled-components';
// Import stripePromise conditionally to avoid errors if Stripe key is missing
// import { stripePromise } from './stripeConfig.js';
import { loadStripe } from '@stripe/stripe-js';
import { generateApiKey } from './utils/apiKeyGenerator';
import { supabase } from './supabaseClient';
import * as zxcvbn from 'zxcvbn';
import config from './config.js';  // Import the config

const GlobalStyle = createGlobalStyle`
  html {
    overflow: auto;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #1a202c, #2d3748);
  font-family: 'Roboto', sans-serif;
  color: #ffff;
  padding: 2rem;
`;

const FormContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
`;

const InputGroup = styled.div`
  margin-bottom: 1rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: #e2e8f0;
  }

  input {
    width: 100%;
    padding: 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid #4a5568;
    background-color: #2d3748;
    color: #ffff;
    font-size: 1rem;

    &:focus {
      outline: none;
      border-color: #4299e1;
    }
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background-color: #4299e1;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #3182ce;
  }
`;

const ErrorMessage = styled.p`
  color: #fc8181;
  margin-bottom: 1rem;
`;

const LoginLink = styled(Link)`
  display: block;
  text-align: center;
  margin-top: 1rem;
  color: #4299e1;
  text-decoration: none;
  font-size: 0.875rem;

  &:hover {
    text-decoration: underline;
  }
`;

const PasswordStrengthMeter = styled.div`
  margin-top: 0.5rem;
  height: 4px;
  background-color: #2d3748;
  border-radius: 2px;
  overflow: hidden;
`;

const PasswordStrengthIndicator = styled.div`
  height: 100%;
  width: ${props => (props.score / 4) * 100}%;
  background-color: ${props => {
    switch (props.score) {
      case 0: return '#fc8181';
      case 1: return '#f6ad55';
      case 2: return '#f6e05e';
      case 3: return '#68d391';
      case 4: return '#48bb78';
      default: return '#2d3748';
    }
  }};
  transition: all 0.3s ease;
`;

const PasswordRequirements = styled.ul`
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: #a0aec0;
  list-style: none;
  padding-left: 0;

  li {
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-right: 8px;
      border-radius: 50%;
      background-color: ${props => props.met ? '#48bb78' : '#fc8181'};
    }
  }
`;

const LoadingOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const validatePassword = (password) => {
  const requirements = {
    length: password.length >= 8,
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
    number: /[0-9]/.test(password),
    special: /[!@#$%^&*(),.?":{}|<>]/.test(password)
  };

  return {
    isValid: Object.values(requirements).every(Boolean),
    requirements
  };
};

const createApiKey = async (businessId, userId) => {
  try {
    const apiKey = generateApiKey();
    
    const { data, error } = await supabase
      .from('api_keys')
      .insert([
        {
          api_key: apiKey,
          created_by: userId,
          business_id: businessId,
          usage_count: 0
        }
      ])
      .select()
      .single();

    if (error) throw error;
    return data.api_key;
  } catch (error) {
    console.error('Error creating API key:', error);
    throw error;
  }
};

const BusinessSignUpPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [contactName, setContactName] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [couponValid, setCouponValid] = useState(false);
  const [error, setError] = useState(null);
  const [verificationSent, setVerificationSent] = useState(false);
  const [signupData, setSignupData] = useState(null);
  const [resendCooldown, setResendCooldown] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [verificationError, setVerificationError] = useState(null);
  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false
  });
  const [formTouched, setFormTouched] = useState({
    email: false,
    password: false,
    confirmPassword: false,
    companyName: false,
    contactName: false
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Add a state to track if Stripe is available
  const [stripeAvailable, setStripeAvailable] = useState(true);

  useEffect(() => {
    let timer;
    if (resendCooldown > 0) {
      timer = setInterval(() => {
        setResendCooldown(prev => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendCooldown]);

  useEffect(() => {
    if (password) {
      const result = validatePassword(password);
      setPasswordRequirements(result.requirements);
      const score = zxcvbn(password).score;
      setPasswordScore(score);
    }
  }, [password]);

  // Add an effect to check if Stripe is available
  useEffect(() => {
    // Check if Stripe public key is available
    if (!config.stripe.publicKey) {
      console.warn('Stripe public key is missing. Subscription setup may not work correctly.');
      setStripeAvailable(false);
    }
  }, []);

  const handleInputChange = (e, setter) => {
    const { name, value } = e.target;
    setter(value);
    setFormTouched(prev => ({ ...prev, [name]: true }));
    setError(null);
  };

  const handleResendVerification = async () => {
    try {
      setError(null);
      setIsLoading(true);

      // Make sure we have an email to resend to
      const emailToResend = signupData?.user?.email || email;
      if (!emailToResend) {
        throw new Error('No email address available for verification');
      }

      console.log('Resending verification to:', emailToResend);

      // Use site URL from window.location
      const siteUrl = window.location.origin;
      const redirectTo = `${siteUrl}/verify-email`;
      console.log('Using site URL for resend:', siteUrl);

      // Try resending with additional options
      const { data, error: resendError } = await supabase.auth.resend({
        type: 'signup',
        email: emailToResend,
        options: {
          emailRedirectTo: redirectTo,
          emailTemplate: 'signup',
          captchaToken: undefined
        }
      });

      if (resendError) {
        console.log('Detailed resend error:', {
          message: resendError.message,
          status: resendError.status,
          name: resendError.name,
          details: resendError
        });
        throw resendError;
      }

      console.log('Resend response:', {
        success: !resendError,
        data
      });

      setResendCooldown(60);
      setError('Verification email sent! Please check your inbox and spam folder.');
    } catch (error) {
      console.error('Error resending verification:', error);
      setError(error.message || 'Failed to resend verification email. Please try again in a few minutes.');
    } finally {
      setIsLoading(false);
    }
  };

  const validateForm = () => {
    if (!email || !password || !confirmPassword || !companyName || !contactName) {
      setError('All fields are required');
      return false;
    }

    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      return false;
    }

    const { isValid, requirements } = validatePassword(password);
    if (!isValid) {
      setError('Password does not meet requirements');
      return false;
    }

    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return false;
    }

    if (passwordScore < 3) {
      setError('Please choose a stronger password');
      return false;
    }

    return true;
  };

  // Add coupon validation function
  const validateCoupon = async (code) => {
    if (code.toUpperCase() === 'TEST') {
      setCouponValid(true);
      setError(null);
      return true;
    }
    setCouponValid(false);
    return false;
  };

  // Modify handleSubmit to include coupon
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    console.log('Starting signup process...');
    
    try {
      if (!validateForm()) {
        setIsLoading(false);
        return;
      }

      // Validate coupon if provided
      if (couponCode && !(await validateCoupon(couponCode))) {
        setError('Invalid coupon code');
        setIsLoading(false);
        return;
      }

      // Use relative path for redirect
      const redirectTo = '/verify-email';
      console.log('Using redirect URL:', redirectTo);
      
      // Add debug logging for signup request
      console.log('Sending signup request with data:', {
        email,
        metadata: {
          company_name: companyName,
          contact_name: contactName,
          role: 'business_owner',
          coupon_code: couponCode.toUpperCase()
        }
      });

      // Try signup with minimal options first
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            company_name: companyName,
            contact_name: contactName,
            role: 'business_owner',
            coupon_code: couponCode.toUpperCase()
          },
          emailRedirectTo: redirectTo,
          shouldCreateUser: true
        }
      });

      // Add detailed error logging
      if (error) {
        console.log('Detailed signup error:', {
          message: error.message,
          status: error.status,
          name: error.name,
          details: error
        });

        // Handle specific error cases
        if (error.message.includes('User already registered')) {
          const { data: signInData, error: signInError } = await supabase.auth.signInWithPassword({
            email,
            password
          });

          if (!signInError && signInData?.user?.email_confirmed_at) {
            throw new Error('This email is already registered and verified. Please log in.');
          } else {
            setVerificationSent(true);
            setSignupData({
              user: { email, id: 'pending' },
              plan: 'business',
              couponCode: couponCode.toUpperCase()
            });
            setError('Account exists but needs verification. Click below to resend the verification email.');
            return;
          }
        } else if (error.message.includes('confirmation email') || error.status === 500) {
          // Try to create the user without immediate email verification
          try {
            const { data: signInData, error: signInError } = await supabase.auth.signInWithPassword({
              email,
              password
            });

            if (!signInError && signInData?.user) {
              setVerificationSent(true);
              setSignupData({
                user: signInData.user,
                businessId: signInData.user.id,
                plan: 'business',
                couponCode: couponCode.toUpperCase()
              });

              // Try to resend verification email
              try {
                const { error: resendError } = await supabase.auth.resend({
                  type: 'signup',
                  email,
                  options: {
                    emailRedirectTo: redirectTo
                  }
                });

                if (resendError) {
                  console.log('Resend error:', resendError);
                  setError('Account created! Please try logging in to verify your email.');
                } else {
                  setError('Account created! Please check your email for verification instructions.');
                }
              } catch (resendError) {
                console.error('Error in resend:', resendError);
                setError('Account created! Please try logging in to verify your email.');
              }
            } else {
              setVerificationSent(true);
              setSignupData({
                user: { email, id: 'pending' },
                plan: 'business',
                couponCode: couponCode.toUpperCase()
              });
              setError('Account setup started. Please check your email or try logging in.');
            }
          } catch (signInError) {
            console.error('Error in sign in attempt:', signInError);
            setError('Unable to complete signup. Please try again or contact support.');
          }
          return;
        } else {
          throw error;
        }
      }

      // Handle successful signup
      if (data?.user) {
        console.log('User created successfully:', data.user);
        setVerificationSent(true);
        setSignupData({
          user: data.user,
          businessId: data.user.id,
          plan: 'business',
          couponCode: couponCode.toUpperCase()
        });

        // Clear form
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setCompanyName('');
        setContactName('');
        setCouponCode('');
        setFormTouched({
          email: false,
          password: false,
          confirmPassword: false,
          companyName: false,
          contactName: false
        });

        setError('Account created! Please check your email for verification.');
        return;
      } else {
        // Handle case where we don't have user data
        console.log('No user data in response, using temporary data');
        setVerificationSent(true);
        setSignupData({
          user: { email, id: 'pending' },
          plan: 'business',
          couponCode: couponCode.toUpperCase()
        });
        setError('Account setup started. Please wait for the verification email.');
        return;
      }
    } catch (error) {
      console.error('Error during signup:', error);
      setError(error.message || 'An error occurred during signup. Please try again.');
      setVerificationSent(false);
      setSignupData(null);
    } finally {
      setIsLoading(false);
    }
  };

  // Add effect to check verification status
  useEffect(() => {
    const {
      data: { subscription: authListener }
    } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log('Auth state changed:', event, session);
      
      if (event === 'SIGNED_IN' && signupData) {
        try {
          setIsLoading(true);
          
          // First verify the email is confirmed
          const { data: userData, error: userError } = await supabase.auth.getUser();
          if (userError) throw userError;

          console.log('User data after sign in:', userData);

          if (!userData.user.email_confirmed_at) {
            setVerificationError('Email not yet verified. Please check your email and click the verification link.');
            return;
          }

          // Update account status to active
          const { error: updateError } = await supabase
            .from('accounts')
            .update({ 
              status: 'active',
              updated_at: new Date().toISOString()
            })
            .eq('id', signupData.user.id);
          
          if (updateError) {
            console.error('Error updating account status:', updateError);
            setVerificationError('Failed to activate account. Please contact support.');
            return;
          }

          // Create initial API key for the business
          try {
            const apiKey = await createApiKey(signupData.businessId, signupData.user.id);
            signupData.user.apiKey = apiKey;
          } catch (apiKeyError) {
            console.error('Error creating API key:', apiKeyError);
            // Continue anyway as this is not critical
          }

          // Store session in localStorage
          if (session) {
            localStorage.setItem('supabase.auth.token', JSON.stringify(session));
          }

          dispatch(setUser({
            ...signupData.user,
            role: 'business_owner',
            businessId: signupData.businessId,
            session: session // Include session in user data
          }));

          // Navigate to dashboard if Stripe is not available, otherwise go to subscription setup
          if (!stripeAvailable) {
            console.warn('Stripe is not available. Redirecting to dashboard instead of subscription setup.');
            navigate('/dashboard');
          } else {
            navigate('/subscription-setup', {
              state: {
                plan: signupData.plan,
                userId: signupData.user.id,
                businessId: signupData.businessId,
                apiKey: signupData.user.apiKey
              }
            });
          }
        } catch (error) {
          console.error('Error in auth state change:', error);
          setVerificationError(error.message || 'Failed to complete verification. Please try again.');
        } finally {
          setIsLoading(false);
        }
      }
    });

    // Add effect to handle email verification token
    const handleEmailVerification = async () => {
      try {
        const params = new URLSearchParams(window.location.hash.substring(1));
        const accessToken = params.get('access_token');
        const refreshToken = params.get('refresh_token');

        if (accessToken && refreshToken) {
          const { data: { session }, error } = await supabase.auth.setSession({
            access_token: accessToken,
            refresh_token: refreshToken
          });

          if (error) throw error;

          if (session) {
            localStorage.setItem('supabase.auth.token', JSON.stringify(session));
            // The auth state change listener will handle the rest
          }
        }
      } catch (error) {
        console.error('Error handling email verification:', error);
        setVerificationError('Failed to verify email. Please try logging in.');
      }
    };

    handleEmailVerification();

    return () => {
      if (authListener) authListener.unsubscribe();
    };
  }, [signupData, dispatch, navigate, stripeAvailable]);

  return (
    <>
      <GlobalStyle />
      <PageContainer>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <FormContainer>
            {verificationSent ? (
              <>
                <h2 style={{ fontSize: '1.875rem', fontWeight: 'bold', marginBottom: '1.5rem', textAlign: 'center' }}>
                  Verify Your Email
                </h2>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                {verificationError && <ErrorMessage>{verificationError}</ErrorMessage>}
                <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                  <p style={{ marginBottom: '1rem' }}>
                    We've sent a verification link to <strong>{signupData?.user?.email}</strong>
                  </p>
                  <p style={{ color: '#A0AEC0', fontSize: '0.875rem', marginBottom: '2rem' }}>
                    Please check your email and click the link to verify your account.
                    After verification, you'll be automatically redirected to complete your subscription setup.
                  </p>
                  <p style={{ color: '#A0AEC0', fontSize: '0.875rem', marginBottom: '1rem' }}>
                    Don't see the email? Check your spam folder or click below to resend.
                  </p>
                  <SubmitButton 
                    onClick={handleResendVerification}
                    disabled={resendCooldown > 0 || isLoading}
                    style={{ 
                      width: 'auto', 
                      margin: '0 auto',
                      opacity: (resendCooldown > 0 || isLoading) ? 0.7 : 1
                    }}
                  >
                    {isLoading ? 'Sending...' :
                      resendCooldown > 0 
                        ? `Resend available in ${resendCooldown}s` 
                        : 'Resend verification email'
                    }
                  </SubmitButton>
                </div>
              </>
            ) : (
              <>
                <h2 style={{ fontSize: '1.875rem', fontWeight: 'bold', marginBottom: '1.5rem', textAlign: 'center' }}>
                  Business Sign Up
                </h2>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <form onSubmit={handleSubmit}>
                  <InputGroup>
                    <label htmlFor="companyName">Company Name</label>
                    <input
                      type="text"
                      id="companyName"
                      name="companyName"
                      value={companyName}
                      onChange={(e) => handleInputChange(e, setCompanyName)}
                      onBlur={() => setFormTouched(prev => ({ ...prev, companyName: true }))}
                      required
                    />
                  </InputGroup>
                  <InputGroup>
                    <label htmlFor="contactName">Contact Name</label>
                    <input
                      type="text"
                      id="contactName"
                      name="contactName"
                      value={contactName}
                      onChange={(e) => handleInputChange(e, setContactName)}
                      onBlur={() => setFormTouched(prev => ({ ...prev, contactName: true }))}
                      required
                    />
                  </InputGroup>
                  <InputGroup>
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => handleInputChange(e, setEmail)}
                      onBlur={() => setFormTouched(prev => ({ ...prev, email: true }))}
                      required
                    />
                    {formTouched.email && !validateEmail(email) && (
                      <ErrorMessage>Please enter a valid email address</ErrorMessage>
                    )}
                  </InputGroup>
                  <InputGroup>
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      value={password}
                      onChange={(e) => handleInputChange(e, setPassword)}
                      onBlur={() => setFormTouched(prev => ({ ...prev, password: true }))}
                      required
                    />
                  </InputGroup>
                  <InputGroup>
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => handleInputChange(e, setConfirmPassword)}
                      onBlur={() => setFormTouched(prev => ({ ...prev, confirmPassword: true }))}
                      required
                    />
                    {formTouched.confirmPassword && password !== confirmPassword && (
                      <ErrorMessage>Passwords don't match</ErrorMessage>
                    )}
                  </InputGroup>
                  <InputGroup>
                    <label htmlFor="password">Password Strength</label>
                    <PasswordStrengthMeter>
                      <PasswordStrengthIndicator score={passwordScore} />
                    </PasswordStrengthMeter>
                    <PasswordRequirements>
                      <li met={passwordRequirements.length}>At least 8 characters</li>
                      <li met={passwordRequirements.uppercase}>One uppercase letter</li>
                      <li met={passwordRequirements.lowercase}>One lowercase letter</li>
                      <li met={passwordRequirements.number}>One number</li>
                      <li met={passwordRequirements.special}>One special character</li>
                    </PasswordRequirements>
                  </InputGroup>
                  <InputGroup>
                    <label htmlFor="couponCode">Coupon Code (Optional)</label>
                    <input
                      type="text"
                      id="couponCode"
                      name="couponCode"
                      value={couponCode}
                      onChange={(e) => {
                        const code = e.target.value.toUpperCase();
                        setCouponCode(code);
                        if (code) validateCoupon(code);
                      }}
                      style={{
                        borderColor: couponCode && (couponValid ? '#48bb78' : '#fc8181')
                      }}
                    />
                    {couponCode && couponValid && (
                      <p style={{ color: '#48bb78', fontSize: '0.875rem', marginTop: '0.5rem' }}>
                        Coupon code applied successfully!
                      </p>
                    )}
                  </InputGroup>
                  <SubmitButton type="submit">Sign Up</SubmitButton>
                </form>
                <LoginLink to="/login">Already have an account? Log in</LoginLink>
              </>
            )}
          </FormContainer>
        </motion.div>
      </PageContainer>
    </>
  );
};

export default BusinessSignUpPage;
