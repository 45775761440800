import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { supabase } from './supabaseClient.js';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #1a202c, #2d3748);
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  padding: 2rem;
`;

const FormContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const InputGroup = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;

  label {
    display: block;
    margin-bottom: 0.75rem;
    color: #e2e8f0;
  }

  input {
    width: 100%;
    padding: 0.75rem;
    padding-left: 2.5rem;
    border-radius: 0.25rem;
    border: 1px solid #4a5568;
    background-color: #2d3748;
    color: #ffffff;
    font-size: 1rem;

    &:focus {
      outline: none;
      border-color: #4299e1;
    }
  }
`;

const ResetButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background-color: #4299e1;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s ease;
  margin-top: 1rem;

  &:hover {
    background-color: #3182ce;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }
`;

const LoginLink = styled(Link)`
  color: #60a5fa;
  text-decoration: none;
  transition: color 0.3s ease;
  margin-top: 1rem;
  display: inline-block;

  &:hover {
    color: #93c5fd;
    text-decoration: underline;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #4a5568;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 1.5rem;
`;

const ResetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email);
      if (error) throw error;
      setMessage('Password reset email sent. Please check your inbox.');
    } catch (error) {
      setMessage(error.message);
    }
  };

  return (
    <PageContainer>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <FormContainer>
          <h2 className="text-3xl font-bold mb-6 text-white">Reset Password</h2>
          <form onSubmit={handleResetPassword} style={{ width: '100%' }}>
            <InputGroup>
              <label htmlFor="email">Insert your email for password reset instructions</label>
              <div className="relative">
                <IconWrapper>
                  <FontAwesomeIcon icon={faLock} />
                </IconWrapper>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </InputGroup>
            <ResetButton type="submit">
              Reset Password
            </ResetButton>
          </form>
          {message && <p className="mt-4 text-sm text-gray-300">{message}</p>}
          <p className="mt-4 text-sm text-gray-400">
            Remember your password?{' '}
            <LoginLink to="/login">
              Log in
            </LoginLink>
          </p>
        </FormContainer>
      </motion.div>
    </PageContainer>
  );
};

export default ResetPasswordPage;
