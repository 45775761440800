import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser, clearUser } from './store';
import { supabase } from './supabaseClient';

const AuthContext = createContext({
  isAuthenticated: false,
  isLoading: true,
  user: null,
  session: null,
  signIn: async () => { },
  signOut: async () => { },
  refreshSession: async () => { },
});

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [session, setSession] = useState(null);
  const [user, setLocalUser] = useState([]);
  const [authError, setAuthError] = useState(null);
  const [currentAuthEvent, setCurrentAuthEvent] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Store auth subscription and state in refs
  const authSubscriptionRef = useRef(null);
  const mountedRef = useRef(true);
  const initializingRef = useRef(false);
  const lastProcessedEventRef = useRef(null);
  const lastUserIdRef = useRef(null);

  const accountDataRef = useRef(null);

  // useEffect(() => {
  //   console.log("getaccount :", getaccount);

  // }, [getaccount])

  const handleAuthStateChange = useCallback(async (event, session) => {
    const accountData = accountDataRef.current;

    try {
      if (event === 'SIGNED_OUT' || event === 'USER_DELETED') {
        console.log('Handling sign out event');
        dispatch(clearUser());
        setLocalUser(null);
        setSession(null);
        setIsAuthenticated(false);
        lastProcessedEventRef.current = null;
        lastUserIdRef.current = null;
        navigate('/login', { replace: true });
        return;
      }

      if (event === 'SIGNED_IN' && session) {
        try {
          if (!mountedRef.current) return;
          if (accountData == null) return;

          // Prepare user data
          const userData = {
            ...session.user,
            role: accountData?.role || 'business_owner',
            businessId: accountData?.business_id || null,
            subscription_status: accountData?.subscription_status || 'inactive',
            session
          };

          console.log('Setting user data:', accountData);

          // Update state
          dispatch(setUser(userData));
          setLocalUser(userData);
          setSession(session);
          setIsAuthenticated(true);

          // Get current location
          const currentPath = window.location.pathname;
          const publicRoutes = ['/landing', '/', '/login', '/signup', '/confirm', '/verify-email'];

          // Only redirect if on a public route
          if (publicRoutes.includes(currentPath)) {
            // Check if user needs to complete subscription setup
            if (userData.role === 'business_owner' && userData.subscription_status === 'inactive') {
              console.log('Redirecting to subscription setup');
              navigate('/stripe-setup', { replace: true });
            } else {
              console.log('Redirecting to dashboard');
              navigate('/dashboard', { replace: true });
            }
          }
        } catch (error) {
          if (!mountedRef.current) return;
          console.error('Error fetching/creating account:', error);
          throw error;
        }
      }
    } catch (error) {
      if (!mountedRef.current) return;
      console.error('Auth state change error:', error.message);
      setAuthError(error.message);

      // Clear everything on error
      dispatch(clearUser());
      setLocalUser(null);
      setSession(null);
      setIsAuthenticated(false);
      lastProcessedEventRef.current = null;
      lastUserIdRef.current = null;

      // Only navigate to login if we're not already there
      if (window.location.pathname !== '/login') {
        navigate('/login', { replace: true });
      }
    } finally {
      if (mountedRef.current) {
        setIsLoading(false);
        // Clear initializing flag after first auth state change
        initializingRef.current = false;
      }
    }
  }, [dispatch, navigate, isAuthenticated]);

  useEffect(() => {
    console.log("mountedRef.current :", mountedRef.current);

    const fetchData = (async () => {
      const { data, error } = await supabase.auth.getSession();

      if (data.session) {
        const userId = data.session.user.id;
        const userEmail = data.session.user.email;
        const contactName = data.session.user.user_metadata.contact_name;
        let accountData = null;

        console.log("contactName :", contactName);

        const accountResult = await supabase
          .from('accounts')
          .select('*')
          .eq('id', userId)
          .single();

        if (accountResult.error) {
          if (accountResult.error.code === 'PGRST116') {
            console.log('Account not found, creating new account');

            const createResult = await supabase
              .from('accounts')
              .insert([{
                id: userId,
                email: userEmail,
                name: contactName,
                role: 'business_owner',
                subscription_status: 'inactive'
              }])
              .single();

            console.log("createResult :", createResult);
            accountData = createResult.data;
          }
        } else {
          accountData = accountResult.data;
        }

        accountDataRef.current = accountData || null;

        // if (accountData != null) {
        //   mountedRef.current = true;
        // }
      }
    });

    fetchData();
    console.log("././//././././././");

    mountedRef.current = true;
    initializingRef.current = true;

    const initialize = async () => {
      try {
        // Get initial session
        const { data: { session }, error } = await supabase.auth.getSession();
        if (error) throw error;

        if (session && mountedRef.current) {
          await handleAuthStateChange('SIGNED_IN', session);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error initializing auth:', error);
        setAuthError(error.message);
        setIsLoading(false);
      } finally {
        initializingRef.current = false;
      }
    };

    // Set up auth state change listener only if we don't have one
    if (!authSubscriptionRef.current) {
      const { data: { subscription } } = supabase.auth.onAuthStateChange(handleAuthStateChange);
      authSubscriptionRef.current = subscription;
    }

    initialize();

    return () => {
      mountedRef.current = false;
      if (authSubscriptionRef.current) {
        console.log('Cleaning up auth subscription on unmount');
        authSubscriptionRef.current.unsubscribe();
        authSubscriptionRef.current = null;
      }
    };
  }, [handleAuthStateChange]);

  const signIn = async (email, password) => {
    try {
      setIsLoading(true);
      setAuthError(null);

      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;

      // The auth state change handler will handle the rest
      return { data, error: null };
    } catch (error) {
      console.error('Error signing in:', error);
      setAuthError(error.message);
      return { data: null, error };
    } finally {
      setIsLoading(false);
    }
  };

  const signOut = async () => {
    try {
      setIsLoading(true);
      setAuthError(null);

      const { error } = await supabase.auth.signOut();
      if (error) throw error;

      // Clear all auth state
      dispatch(clearUser());
      setLocalUser(null);
      setSession(null);
      setIsAuthenticated(false);

      return { error: null };
    } catch (error) {
      console.error('Error signing out:', error);
      setAuthError(error.message);
      return { error };
    } finally {
      setIsLoading(false);
    }
  };

  const refreshSession = async () => {
    try {
      setIsLoading(true);
      setAuthError(null);

      const { data: { session }, error } = await supabase.auth.getSession();
      if (error) throw error;

      if (!session) {
        throw new Error('No session available to refresh');
      }

      const { data: { user }, error: refreshError } = await supabase.auth.refreshSession();
      if (refreshError) throw refreshError;

      await handleAuthStateChange('TOKEN_REFRESHED', session);

      return { session, error: null };
    } catch (error) {
      console.error('Error refreshing session:', error);
      setAuthError(error.message);
      return { session: null, error };
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    isAuthenticated,
    isLoading,
    user,
    session,
    authError,
    signIn,
    signOut,
    refreshSession,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuth, AuthContext }; 