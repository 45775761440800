import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe with the correct public key directly from env
const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

// Product and Price IDs
const STRIPE_PRICE_IDS = {
  INDIVIDUAL: {
    MONTHLY: process.env.REACT_APP_STRIPE_PRICE_ID_INDIVIDUAL_MONTHLY,
    YEARLY: process.env.REACT_APP_STRIPE_PRICE_ID_INDIVIDUAL_YEARLY
  },
  BUSINESS: {
    MONTHLY: process.env.REACT_APP_STRIPE_PRICE_ID_BUSINESS_MONTHLY,
    YEARLY: process.env.REACT_APP_STRIPE_PRICE_ID_BUSINESS_YEARLY
  }
};

// Subscription Prices (in USD)
const SUBSCRIPTION_PRICES = {
  INDIVIDUAL: {
    MONTHLY: 12.99,
    YEARLY: 129.90
  },
  BUSINESS: {
    MONTHLY: 99.99,
    YEARLY: 999.90
  }
};

const API_COSTS = {
  CREATE_GRID: 3.50,
  UPDATE_GRID: 1.50,
  GET_GRID: 0.00,
  LIST_GRIDS: 0.00,
};

const VOLUME_DISCOUNTS = {
  API_CALLS: {
    100: 0.90,  // 10% discount above 100 calls
    500: 0.80,  // 20% discount above 500 calls
    1000: 0.70, // 30% discount above 1000 calls
  }
};

// Plan details configuration
const PLAN_DETAILS = {
  basic: {
    name: 'Individual Plan',
    features: [
      '1 user account',
      'Full access to CAD application',
      'Personalized dashboard',
      'Unlimited grid creation & saves',
      '5 GB cloud storage',
      'Priority updates',
      'Email support',
      'Mobile app access',
      '30-day version history'
    ]
  },
  business: {
    name: 'Business Plan',
    features: [
      '1 business account + 9 team members included',
      'Additional users just $10/month each',
      'Advanced admin console',
      'Team collaboration tools',
      '50 GB shared cloud storage',
      'Priority 24/7 support',
      'Advanced analytics & reporting',
      'API access with 10,000 calls/month',
      'Custom branding options',
      '90-day version history',
      'Dedicated account manager'
    ]
  }
};

// Debug logging in development
if (process.env.NODE_ENV === 'development') {
  console.log('Environment Variables:', {
    STRIPE_PUBLIC_KEY: STRIPE_PUBLIC_KEY ? '✓ Set' : '✗ Missing',
    PRICE_ID_INDIVIDUAL_MONTHLY: STRIPE_PRICE_IDS.INDIVIDUAL.MONTHLY ? '✓ Set' : '✗ Missing',
    PRICE_ID_INDIVIDUAL_YEARLY: STRIPE_PRICE_IDS.INDIVIDUAL.YEARLY ? '✓ Set' : '✗ Missing',
    PRICE_ID_BUSINESS_MONTHLY: STRIPE_PRICE_IDS.BUSINESS.MONTHLY ? '✓ Set' : '✗ Missing',
    PRICE_ID_BUSINESS_YEARLY: STRIPE_PRICE_IDS.BUSINESS.YEARLY ? '✓ Set' : '✗ Missing'
  });

  if (!STRIPE_PUBLIC_KEY) {
    console.warn(`
      Missing Stripe publishable key!
      Please add REACT_APP_STRIPE_PUBLISHABLE_KEY to your .env file
      Example: REACT_APP_STRIPE_PUBLISHABLE_KEY=pk_test_your_key_here
      
      Stripe functionality will be disabled.
    `);
  }

  if (!STRIPE_PRICE_IDS.INDIVIDUAL.MONTHLY || !STRIPE_PRICE_IDS.INDIVIDUAL.YEARLY || !STRIPE_PRICE_IDS.BUSINESS.MONTHLY || !STRIPE_PRICE_IDS.BUSINESS.YEARLY) {
    console.warn(`
      Missing Stripe price IDs!
      Please add the following to your .env file:
      REACT_APP_STRIPE_PRICE_ID_INDIVIDUAL_MONTHLY=price_xyz...
      REACT_APP_STRIPE_PRICE_ID_INDIVIDUAL_YEARLY=price_xyz...
      REACT_APP_STRIPE_PRICE_ID_BUSINESS_MONTHLY=price_xyz...
      REACT_APP_STRIPE_PRICE_ID_BUSINESS_YEARLY=price_xyz...
      
      Subscription functionality will be limited.
    `);
  }
}

// Create Stripe instance only if we have a public key
let stripePromise = null;
try {
  if (STRIPE_PUBLIC_KEY) {
    stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
  } else {
    console.warn('Stripe functionality disabled due to missing public key');
  }
} catch (error) {
  console.error('Error initializing Stripe:', error);
}

// Debug environment variables in development
if (process.env.NODE_ENV === 'development') {
  const envVars = {
    STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    PRICE_ID_INDIVIDUAL_MONTHLY: STRIPE_PRICE_IDS.INDIVIDUAL.MONTHLY,
    PRICE_ID_INDIVIDUAL_YEARLY: STRIPE_PRICE_IDS.INDIVIDUAL.YEARLY,
    PRICE_ID_BUSINESS_MONTHLY: STRIPE_PRICE_IDS.BUSINESS.MONTHLY,
    PRICE_ID_BUSINESS_YEARLY: STRIPE_PRICE_IDS.BUSINESS.YEARLY,
    API_URL: process.env.REACT_APP_API_URL,
    NODE_ENV: process.env.NODE_ENV
  };

  console.log('Stripe Config Environment Variables:', envVars);

  // Validate required environment variables
  const requiredEnvVars = [
    'REACT_APP_API_URL'
  ];

  const missingEnvVars = requiredEnvVars.filter(varName => !process.env[varName]);
  if (missingEnvVars.length > 0) {
    console.warn('Missing environment variables:', missingEnvVars);
    console.warn('Using default values where possible. Please check your .env file.');
  }

  // Validate price ID format
  const validatePriceId = (priceId, name) => {
    if (!priceId) {
      console.warn(`Missing ${name} price ID`);
      return false;
    }
    return typeof priceId === 'string' && priceId.startsWith('price_');
  };

  // Validate price IDs
  Object.entries(STRIPE_PRICE_IDS).forEach(([plan, priceIds]) => {
    Object.entries(priceIds).forEach(([period, priceId]) => {
      if (!validatePriceId(priceId, `${plan} ${period}`)) {
        console.warn(`Invalid price ID for ${plan} ${period}. Please check your Stripe dashboard.`);
      }
    });
  });
}

// Export everything at the end
export {
  stripePromise,
  STRIPE_PRICE_IDS,
  SUBSCRIPTION_PRICES,
  API_COSTS,
  VOLUME_DISCOUNTS,
  PLAN_DETAILS
};
