import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { SUBSCRIPTION_PRICES } from './stripeConfig';
import ManageSubscription from './components/ManageSubscription/ManageSubscription';

const PageContainer = styled.div`
  min-height: 100vh;
  background: linear-gradient(135deg, #1a202c, #2d3748);
  padding: 2rem;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
  overflow-y: auto;
  position: relative;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 2rem;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 3rem;

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.125rem;
    color: #A0AEC0;
    max-width: 600px;
    margin: 0 auto;
  }
`;

const PlansContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const PlanCard = styled(motion.div)`
  background: rgba(26, 32, 44, 0.3);
  border-radius: 1rem;
  padding: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: fit-content;

  ${props => props.recommended && `
    border-color: #4299e1;
    &::before {
      content: 'Recommended';
      position: absolute;
      top: 1rem;
      right: -2rem;
      background: #4299e1;
      padding: 0.25rem 2rem;
      transform: rotate(45deg);
      font-size: 0.75rem;
      font-weight: 500;
    }
  `}
`;

const PlanHeader = styled.div`
  margin-bottom: 2rem;
  
  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .price {
    font-size: 2.5rem;
    font-weight: 700;
    color: #4299e1;
    margin-bottom: 0.25rem;
  }

  .period {
    color: #A0AEC0;
    font-size: 0.875rem;
  }

  .description {
    margin-top: 1rem;
    color: #A0AEC0;
    font-size: 0.875rem;
    line-height: 1.5;
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    color: #E2E8F0;
    font-size: 0.875rem;

    svg {
      color: #48BB78;
      margin-right: 0.75rem;
      font-size: 1rem;
    }
  }
`;

const SelectButton = styled.button`
  width: 100%;
  padding: 1rem;
  margin-top: 2rem;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: ${props => props.primary ? 'linear-gradient(135deg, #4299e1 0%, #3182ce 100%)' : 'transparent'};
  color: #ffffff;
  border: 1px solid ${props => props.primary ? 'transparent' : 'rgba(255, 255, 255, 0.1)'};
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(66, 153, 225, 0.3);
    background: ${props => props.primary ? 'linear-gradient(135deg, #3182ce 0%, #2c5282 100%)' : 'rgba(255, 255, 255, 0.1)'};
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const SuccessMessage = styled(motion.div)`
  background: rgba(72, 187, 120, 0.1);
  border: 1px solid rgba(72, 187, 120, 0.2);
  color: #48BB78;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

// Add a global style to ensure the body can scroll
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background: linear-gradient(135deg, #1a202c, #2d3748);
    color: #ffffff;
    overflow-y: auto;
  }
`;

const SubscriptionPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const hasActiveSubscription = user?.subscription_status === 'active';
  const { fromManage, isManageMode } = location.state || {};

  const handleSelectPlan = (selectedPlan) => {
    // If user is not authenticated, redirect to signup
    if (!user) {
      navigate('/signup');
      return;
    }

    // If user is authenticated, proceed to subscription setup
    navigate('/stripe-setup', {
      state: {
        plan: selectedPlan,
        isManageMode: false
      }
    });
  };

  // If user has an active subscription and is coming from manage subscription button,
  // redirect them directly to the subscription setup page with manage mode
  if (hasActiveSubscription && isManageMode) {
    navigate('/subscription-setup', { 
      state: { 
        plan: user.subscription_plan.toLowerCase(),
        isManageMode: true,
        currentPlan: user.subscription_plan
      } 
    });
    return null;
  }

  // If user has an active subscription and wants to change plans (fromManage),
  // show them the plan selection interface
  if (hasActiveSubscription && !fromManage) {
    navigate('/subscription-setup', { 
      state: { 
        plan: user.subscription_plan.toLowerCase(),
        isManageMode: true,
        currentPlan: user.subscription_plan
      } 
    });
    return null;
  }

  const plans = [
    {
      id: 'individual',
      name: 'Individual',
      price: SUBSCRIPTION_PRICES.INDIVIDUAL,
      description: 'Perfect for freelancers and individual professionals',
      features: [
        '1 user account',
        'Full access to CAD application',
        'Personalized dashboard',
        'Unlimited grid creation & saves',
        '5 GB cloud storage',
        'Priority updates',
        'Email support',
        'Mobile app access',
        '30-day version history'
      ]
    },
    {
      id: 'business',
      name: 'Business',
      price: SUBSCRIPTION_PRICES.BUSINESS,
      description: 'Ideal for teams and growing businesses',
      recommended: true,
      features: [
        '1 business account + 9 team members included',
        'Additional users just $10/month each',
        'Advanced admin console',
        'Team collaboration tools',
        '50 GB shared cloud storage',
        'Priority 24/7 support',
        'Advanced analytics & reporting',
        'API access with 10,000 calls/month',
        'Custom branding options',
        '90-day version history',
        'Dedicated account manager'
      ]
    }
  ];

  return (
    <>
      <GlobalStyle />
      <PageContainer>
        <ContentWrapper>
          {location.state?.success && (
            <SuccessMessage
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <FontAwesomeIcon icon={faCheck} />
              {location.state.message}
            </SuccessMessage>
          )}

          <Header>
            <h1>{hasActiveSubscription ? 'Change Subscription Plan' : 'Choose Your Plan'}</h1>
            <p>
              {hasActiveSubscription 
                ? 'Select a new plan to upgrade or downgrade your subscription'
                : 'Select the plan that best fits your needs. All plans include full access to our core features.'}
            </p>
          </Header>

          <PlansContainer>
            {plans.map((plan) => (
              <PlanCard
                key={plan.id}
                recommended={plan.recommended}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <PlanHeader>
                  <h2>{plan.name}</h2>
                  <div className="price">${plan.price}</div>
                  <div className="period">per month</div>
                  <div className="description">{plan.description}</div>
                </PlanHeader>

                <FeatureList>
                  {plan.features.map((feature, index) => (
                    <li key={index}>
                      <FontAwesomeIcon icon={faCheck} />
                      {feature}
                    </li>
                  ))}
                </FeatureList>

                <SelectButton 
                  onClick={() => handleSelectPlan(plan.id)}
                  primary={plan.recommended}
                >
                  {hasActiveSubscription 
                    ? user?.subscription_plan === plan.id.toUpperCase()
                      ? 'Current Plan'
                      : 'Switch to This Plan'
                    : 'Select Plan'}
                  <FontAwesomeIcon icon={faArrowRight} />
                </SelectButton>
              </PlanCard>
            ))}
          </PlansContainer>
        </ContentWrapper>
      </PageContainer>
    </>
  );
};

export default SubscriptionPage;
