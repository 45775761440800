import { API_COSTS, VOLUME_DISCOUNTS, SUBSCRIPTION_PRICES } from './stripeConfig.js';
import { supabase } from './supabaseClient.js';

export async function calculateMonthlyApiCosts(businessId, billingPeriodStart, billingPeriodEnd) {
  const { data: logs, error } = await supabase
    .from('api_usage_logs')
    .select('*')
    .eq('business_id', businessId)
    .gte('created_at', billingPeriodStart)
    .lt('created_at', billingPeriodEnd);

  if (error) throw error;

  const usage = logs.reduce((acc, log) => {
    if (!acc[log.action_type]) {
      acc[log.action_type] = 0;
    }
    acc[log.action_type]++;
    return acc;
  }, {});

  let totalCost = 0;
  
  Object.entries(usage).forEach(([action, count]) => {
    const baseCost = API_COSTS[action] || 0;
    let discountMultiplier = 1;

    // Apply volume discounts
    Object.entries(VOLUME_DISCOUNTS.API_CALLS)
      .sort(([threshold1], [threshold2]) => Number(threshold2) - Number(threshold1))
      .forEach(([threshold, discount]) => {
        if (count > Number(threshold)) {
          discountMultiplier = discount;
        }
      });

    totalCost += baseCost * count * discountMultiplier;
  });

  return {
    totalCost: Number(totalCost.toFixed(2)),
    usage
  };
}

export async function calculateAdditionalUsersCost(businessId) {
  const { count, error } = await supabase
    .from('accounts')
    .select('id', { count: 'exact' })
    .eq('business_id', businessId)
    .eq('status', 'active');

  if (error) throw error;

  const additionalUsers = Math.max(0, count - 10); // Business plan includes 10 users
  return additionalUsers * SUBSCRIPTION_PRICES.ADDITIONAL_USER;
}
