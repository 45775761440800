import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism/index.js';
import { Link } from 'react-router-dom';

const APIDocumentation = () => {
  return (
    <div className="api-documentation-wrapper flex h-screen overflow-hidden">
      <nav className="w-64 bg-gray-100 p-8 overflow-y-auto">
        <Link to="/admin" className="back-link mb-4 inline-block text-sm">
          <svg 
            className="inline-block w-3 h-3 mr-1" 
            style={{ width: '12px', height: '12px' }} 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <path d="M19 12H5M12 19l-7-7 7-7" />
          </svg>
          Back to Admin Console
        </Link>
        <h1 className="text-2xl font-bold mb-4">Grid API Documentation</h1>
        <ul className="space-y-2">
          <li><a href="#introduction" className="text-blue-600 hover:underline">Introduction</a></li>
          <li><a href="#frontend-integration" className="text-blue-600 hover:underline">Frontend Integration</a></li>
          <li><a href="#authentication" className="text-blue-600 hover:underline">Authentication</a></li>
          <li><a href="#base-url" className="text-blue-600 hover:underline">Base URL</a></li>
          <li><a href="#endpoints" className="text-blue-600 hover:underline">Endpoints</a></li>
          <li><a href="#error-handling" className="text-blue-600 hover:underline">Error Handling</a></li>
          <li><a href="#rate-limiting" className="text-blue-600 hover:underline">Rate Limiting</a></li>
          <li><a href="#pagination" className="text-blue-600 hover:underline">Pagination</a></li>
          <li><a href="#versioning" className="text-blue-600 hover:underline">Versioning</a></li>
          <li><a href="#api-pricing" className="text-blue-600 hover:underline">API Pricing</a></li>
          <li><a href="#support" className="text-blue-600 hover:underline">Support</a></li>
          <li><a href="#changelog" className="text-blue-600 hover:underline">Changelog</a></li>
        </ul>
      </nav>
      <main className="flex-grow p-8 overflow-y-auto">
        <div className="max-w-4xl mx-auto">
          <section id="introduction" className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">Introduction</h2>
            <p className="mb-4">
              Welcome to the Grid API documentation. This API is designed primarily for frontend integration,
              providing a seamless connection between your frontend application and our backend services for
              comprehensive grid management. With this API, you can create, retrieve, update, and manage grids
              efficiently, while also accessing our powerful CAD application for grid drawing and editing.
            </p>
            <p className="mb-4">
              The Grid API offers a robust set of endpoints that allow your frontend to interact with grid data
              stored on our servers. This enables you to build feature-rich applications with advanced grid
              management capabilities, all while maintaining a smooth and responsive user experience.
            </p>
          </section>

          <section id="frontend-integration" className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">Frontend Integration</h2>
            <p className="mb-4">
              The Grid API provides a straightforward integration method using vanilla JavaScript, 
              which can be used with any JavaScript framework or vanilla JS applications.
              It uses our CAD API wrapper to handle grid operations.
            </p>

            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-2">Vanilla JavaScript Integration</h3>
              <p className="mb-4">
                This implementation provides full control over grid operations and can be integrated into any web application.
              </p>
              <ul className="list-disc pl-8 mb-4">
                <li>✓ Framework-agnostic implementation</li>
                <li>✓ Full control over grid operations</li>
                <li>✓ Lightweight implementation</li>
                <li>✓ Direct API access</li>
              </ul>
              <SyntaxHighlighter language="javascript" style={tomorrow}>
{`// Initialize CAD API
const cadApp = new CADAppAPI(YOUR_API_KEY, {
  onGridSave: handleGridSave,
  onGridLoad: handleGridLoad,
  onError: handleError,
  store: customStore, // Optional
  apiBaseUrl: 'your_api_endpoint'
});

// Initialize the application
await cadApp.initialize('cad-container');

// Handle grid operations manually
async function handleGridSave(data) {
  const response = await fetch('your_api_endpoint/grids', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': \`Bearer \${YOUR_API_KEY}\`,
      'X-API-Key': YOUR_API_KEY
    },
    body: JSON.stringify(data)
  });
  return response.json();
}`}
              </SyntaxHighlighter>
            </div>

            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-2">Auto-Loading Saved Grids</h3>
              <p className="mb-4">
                You can configure the CAD application to automatically load a specific saved grid when initializing.
                This is useful for embedding pre-configured grids in your application.
              </p>

              <SyntaxHighlighter language="javascript" style={tomorrow}>
{`// Add this function to handle finding and loading a specific grid
async function findAndLoadSpecificGrid() {
    try {
        const grids = await fetchSavedGrids();
        const targetGrid = grids.find(grid => grid.name === "Your Grid Name");
        
        if (targetGrid) {
            await handleGridLoad({ id: targetGrid.id });
            console.log('Successfully loaded specific grid');
        } else {
            console.error('Could not find specified grid');
        }
    } catch (error) {
        console.error('Failed to load specific grid:', error);
    }
}

// Modify your initialization function to include auto-loading
async function initializeCAD() {
    try {
        // Normal initialization code
        const cadApp = new CADAppAPI(YOUR_API_KEY, {
            onGridSave: handleGridSave,
            onGridLoad: handleGridLoad,
            onError: handleError,
            apiBaseUrl: 'your_api_endpoint'
        });

        await cadApp.initialize('cad-container');

        // Add this line after initialization to auto-load the grid
        await findAndLoadSpecificGrid();
        
    } catch (error) {
        console.error('Initialization error:', error);
    }
}`}
              </SyntaxHighlighter>

              <h4 className="text-lg font-semibold mt-4 mb-2">Important Considerations</h4>
              <ul className="list-disc pl-8 mb-4">
                <li>The grid name must match exactly (including case and spacing)</li>
                <li>The grid must be accessible to your API key</li>
                <li>The grid must either be created via API or have a matching business_id</li>
                <li>Auto-loading will trigger a grid load operation charge according to our pricing</li>
                <li>Handle potential errors appropriately in production environments</li>
              </ul>

              <h4 className="text-lg font-semibold mt-4 mb-2">Error Handling</h4>
              <p className="mb-4">
                When implementing auto-loading, make sure to handle these common scenarios:
              </p>
              <SyntaxHighlighter language="javascript" style={tomorrow}>
{`async function findAndLoadSpecificGrid() {
    try {
        const grids = await fetchSavedGrids();
        const targetGrid = grids.find(grid => grid.name === "Your Grid Name");
        
        if (!targetGrid) {
            throw new Error('Specified grid not found');
        }
        
        await handleGridLoad({ id: targetGrid.id });
        
    } catch (error) {
        // Handle specific error cases
        switch(error.message) {
            case 'Specified grid not found':
                // Handle missing grid
                console.error('The specified grid could not be found');
                break;
            case 'Unauthorized':
                // Handle authentication errors
                console.error('API key does not have access to this grid');
                break;
            default:
                // Handle other errors
                console.error('Failed to load grid:', error.message);
        }
        
        // Optionally fall back to empty grid
        initializeEmptyGrid();
    }
}`}
              </SyntaxHighlighter>
            </div>
          </section>

          <section id="authentication" className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">Authentication</h2>
            <p className="mb-4">All API requests must include your API key in the `x-api-key` header. This key is unique to your business account and should be kept secure.</p>
            <SyntaxHighlighter language="http" style={tomorrow}>
{`GET /api/grids
x-api-key: your_api_key_here`}
            </SyntaxHighlighter>
          </section>

          <section id="base-url" className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">Base URL</h2>
            <p className="mb-4">All API requests should be made to:</p>
            <SyntaxHighlighter language="bash" style={tomorrow}>
              https://api.dynamicdrafts.io/api
            </SyntaxHighlighter>
          </section>

          <section id="endpoints" className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">Endpoints</h2>
            <p className="mb-4">
              Our API provides a comprehensive set of endpoints to manage grids. These endpoints are designed
              to be used in conjunction with our frontend CAD component, but can also be accessed directly
              for custom implementations.
            </p>

            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-2">Get All Grids</h3>
              <SyntaxHighlighter language="http" style={tomorrow}>
                GET /api/grids
              </SyntaxHighlighter>
              <p className="mt-2">Returns a list of all grids associated with your business account.</p>
              <h4 className="text-lg font-semibold mt-4 mb-2">Query Parameters</h4>
              <ul className="list-disc pl-8 mb-4">
                <li><code>page</code> (optional): Page number for pagination (default: 1)</li>
                <li><code>limit</code> (optional): Number of items per page (default: 20, max: 100)</li>
              </ul>
              <h4 className="text-lg font-semibold mt-4 mb-2">Response</h4>
              <SyntaxHighlighter language="json" style={tomorrow}>
{`{
  "data": [
    {
      "id": "grid_123",
      "name": "My Grid",
      "created_at": "2023-04-01T12:00:00Z",
      "updated_at": "2023-04-01T14:30:00Z"
    },
    ...
  ],
  "meta": {
    "current_page": 1,
    "total_pages": 5,
    "total_count": 100
  }
}`}
              </SyntaxHighlighter>
            </div>

            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-2">Get a Specific Grid</h3>
              <SyntaxHighlighter language="http" style={tomorrow}>
                GET /api/grids/:id
              </SyntaxHighlighter>
              <p className="mt-2">Returns details of a specific grid.</p>
              <h4 className="text-lg font-semibold mt-4 mb-2">Response</h4>
              <SyntaxHighlighter language="json" style={tomorrow}>
{`{
  "id": "grid_123",
  "name": "My Grid",
  "pages": [
    {
      "id": "page_1",
      "shapes": [...],
      "viewBox": {...}
    },
    ...
  ],
  "gridMeasurement": 1,
  "gridUnit": "feet",
  "created_at": "2023-04-01T12:00:00Z",
  "updated_at": "2023-04-01T14:30:00Z"
}`}
              </SyntaxHighlighter>
            </div>

            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-2">Create a New Grid</h3>
              <SyntaxHighlighter language="http" style={tomorrow}>
{`POST /api/grids
Content-Type: application/json`}
              </SyntaxHighlighter>
              <h4 className="text-lg font-semibold mt-4 mb-2">Request Body</h4>
              <SyntaxHighlighter language="json" style={tomorrow}>
{`{
  "name": "My New Grid",
  "pages": [
    {
      "shapes": [...],
      "viewBox": {...}
    }
  ],
  "gridMeasurement": 1,
  "gridUnit": "feet"
}`}
              </SyntaxHighlighter>
              <p className="mt-2">Creates a new grid and returns the created grid object.</p>
              <h4 className="text-lg font-semibold mt-4 mb-2">Response</h4>
              <SyntaxHighlighter language="json" style={tomorrow}>
{`{
  "id": "grid_124",
  "name": "My New Grid",
  "pages": [...],
  "gridMeasurement": 1,
  "gridUnit": "feet",
  "created_at": "2023-04-02T10:00:00Z",
  "updated_at": "2023-04-02T10:00:00Z"
}`}
              </SyntaxHighlighter>
            </div>

            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-2">Update a Grid</h3>
              <SyntaxHighlighter language="http" style={tomorrow}>
{`PUT /api/grids/:id
Content-Type: application/json`}
              </SyntaxHighlighter>
              <h4 className="text-lg font-semibold mt-4 mb-2">Request Body</h4>
              <SyntaxHighlighter language="json" style={tomorrow}>
{`{
  "name": "Updated Grid Name",
  "pages": [...],
  "gridMeasurement": 2,
  "gridUnit": "meters"
}`}
              </SyntaxHighlighter>
              <p className="mt-2">Updates an existing grid and returns the updated grid object.</p>
              <h4 className="text-lg font-semibold mt-4 mb-2">Response</h4>
              <SyntaxHighlighter language="json" style={tomorrow}>
{`{
  "id": "grid_123",
  "name": "Updated Grid Name",
  "pages": [...],
  "gridMeasurement": 2,
  "gridUnit": "meters",
  "created_at": "2023-04-01T12:00:00Z",
  "updated_at": "2023-04-02T11:30:00Z"
}`}
              </SyntaxHighlighter>
            </div>

            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-2">Delete a Grid</h3>
              <SyntaxHighlighter language="http" style={tomorrow}>
                DELETE /api/grids/:id
              </SyntaxHighlighter>
              <p className="mt-2">Deletes a specific grid.</p>
              <h4 className="text-lg font-semibold mt-4 mb-2">Response</h4>
              <p>Returns a 204 No Content status on successful deletion.</p>
            </div>

            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-2">CAD Application</h3>
              <SyntaxHighlighter language="http" style={tomorrow}>
                GET /api/cad?api_key=YOUR_API_KEY
              </SyntaxHighlighter>
              <p className="mt-2">This endpoint serves the CAD application for grid drawing. Include your API key as a query parameter to authenticate and use the application.</p>
            </div>
          </section>
  
          <section id="error-handling" className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">Error Handling</h2>
            <p className="mb-4">The API uses standard HTTP status codes to indicate the success or failure of requests. In case of an error, the response will include a JSON object with an `error` field describing the issue.</p>
            <h4 className="text-lg font-semibold mt-4 mb-2">Example Error Response</h4>
            <SyntaxHighlighter language="json" style={tomorrow}>
{`{
  "error": "Invalid API key",
  "status": 401
}`}
            </SyntaxHighlighter>
            <h4 className="text-lg font-semibold mt-4 mb-2">Common Error Codes</h4>
            <ul className="list-disc pl-8 mb-4">
              <li><code>400</code> - Bad Request: The request was invalid or cannot be served.</li>
              <li><code>401</code> - Unauthorized: The request requires authentication.</li>
              <li><code>403</code> - Forbidden: The server understood the request but refuses to authorize it.</li>
              <li><code>404</code> - Not Found: The requested resource could not be found.</li>
              <li><code>429</code> - Too Many Requests: You have sent too many requests in a given amount of time.</li>
              <li><code>500</code> - Internal Server Error: We had a problem with our server.</li>
            </ul>
          </section>

          <section id="rate-limiting" className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">Rate Limiting</h2>
            <p className="mb-4">To ensure fair usage, API requests are rate-limited to 100 requests per minute per API key. If you exceed the rate limit, you'll receive a 429 (Too Many Requests) status code. Please wait and try again later.</p>
            <p className="mb-4">The following headers are sent with each response to inform you about your current rate limit status:</p>
            <ul className="list-disc pl-8 mb-4">
              <li><code>X-RateLimit-Limit</code>: The maximum number of requests you're permitted to make per minute.</li>
              <li><code>X-RateLimit-Remaining</code>: The number of requests remaining in the current rate limit window.</li>
              <li><code>X-RateLimit-Reset</code>: The time at which the current rate limit window resets in UTC epoch seconds.</li>
            </ul>
          </section>

          <section id="pagination" className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">Pagination</h2>
            <p className="mb-4">For endpoints that return lists of items, such as GET /api/grids, the API supports pagination to limit the number of items returned in a single request.</p>
            <h4 className="text-lg font-semibold mt-4 mb-2">Pagination Parameters</h4>
            <ul className="list-disc pl-8 mb-4">
              <li><code>page</code>: The page number (default: 1)</li>
              <li><code>limit</code>: The number of items per page (default: 20, max: 100)</li>
            </ul>
            <p className="mb-4">The response will include a <code>meta</code> object with pagination information:</p>
            <SyntaxHighlighter language="json" style={tomorrow}>
{`{
  "data": [...],
  "meta": {
    "current_page": 1,
    "total_pages": 5,
    "total_count": 100
  }
}`}
            </SyntaxHighlighter>
          </section>

          <section id="versioning" className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">Versioning</h2>
            <p className="mb-4">The API is versioned to ensure backwards compatibility as we evolve our services. Currently, we are on version 1 (v1) which is our initial stable release.</p>
            <SyntaxHighlighter language="bash" style={tomorrow}>
              https://api.dynamicdrafts.io/v1/grids
            </SyntaxHighlighter>
            <p className="mt-4">While we only have one version at present, we recommend always specifying the API version in your requests to ensure your integration remains stable when future versions are released.</p>
          </section>

          <section id="api-pricing" className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">API Pricing</h2>
            <p className="mb-4">
              API access is available exclusively for Business Plan subscribers ($100/month base plan). Our pricing model is based on grid operations.
            </p>

            <div className="mb-8">
              <h3 className="text-xl font-semibold mb-4">Grid Operation Pricing</h3>
              <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-gradient-to-r from-blue-50 to-blue-100">
                      <th className="border-b border-gray-300 p-4 text-center text-gray-700">Operation</th>
                      <th className="border-b border-gray-300 p-4 text-center text-gray-700">Monthly Price</th>
                      <th className="border-b border-gray-300 p-4 text-center text-gray-700">Annual Price (20% off)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="hover:bg-gray-50">
                      <td className="border-b border-gray-200 p-4 text-center font-medium">Create New Grid</td>
                      <td className="border-b border-gray-200 p-4 text-center">$3.50 per grid</td>
                      <td className="border-b border-gray-200 p-4 text-center">$2.80 per grid</td>
                    </tr>
                    <tr className="hover:bg-gray-50">
                      <td className="border-b border-gray-200 p-4 text-center font-medium">Recall & Edit Grid*</td>
                      <td className="border-b border-gray-200 p-4 text-center">$1.50 per recall & edit</td>
                      <td className="border-b border-gray-200 p-4 text-center">$1.20 per recall & edit</td>
                    </tr>
                    <tr className="hover:bg-gray-50">
                      <td className="border-b border-gray-200 p-4 text-center font-medium">Retrieve Grid</td>
                      <td className="border-b border-gray-200 p-4 text-center">Free</td>
                      <td className="border-b border-gray-200 p-4 text-center">Free</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className="text-sm text-gray-600 mt-2">* Recall & Edit charges apply only when loading an existing grid for modification. Edits made during the initial grid creation session are included in the creation price.</p>
            </div>
            
            <div className="space-y-4">
              <h3 className="text-xl font-semibold">Additional Information</h3>
              <ul className="list-disc pl-8 space-y-2">
                <li>All prices are in USD and billed monthly</li>
                <li>Annual subscriptions receive a 20% discount on all pricing</li>
                <li>Development environment testing is discounted 75%</li>
                <li>Enterprise plans include custom features, dedicated support, and flexible billing</li>
                <li>Volume discounts are applied automatically</li>
                <li>Unused included operations don't roll over</li>
                <li>Grid retrievals are always free to encourage data access</li>
                <li>Initial edits during grid creation are included in the creation price</li>
              </ul>
            </div>
          </section>

          <section id="support" className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">Support</h2>
            <p className="mb-4">If you encounter any issues or have questions about the API, please contact our support team at <a href="mailto:support@dynamicdrafts.io" className="text-blue-600 hover:underline">support@dynamicdrafts.io</a>.</p>
            <p className="mb-4">When reporting issues, please include:</p>
            <ul className="list-disc pl-8 mb-4">
              <li>A detailed description of the problem</li>
              <li>The endpoint and method you're trying to access</li>
              <li>Any error messages or codes you received</li>
              <li>Your API key (only the first 4 and last 4 characters for security)</li>
            </ul>
          </section>

          <section id="changelog" className="mb-12">
            <h2 className="text-2xl font-semibold mb-4">Changelog</h2>
            <h3 className="text-xl font-semibold mb-2">v1.0.0 (Current Version)</h3>
            <p className="mb-4">Initial stable release of the Grid API with the following features:</p>
            <ul className="list-disc pl-8 mb-4">
              <li>Complete CRUD operations for grid management</li>
              <li>Authentication via API keys</li>
              <li>Pagination support for list endpoints</li>
              <li>Rate limiting implementation</li>
              <li>Error handling with detailed responses</li>
              <li>CAD application integration</li>
              <li>Support for both React component and Vanilla JavaScript integration methods</li>
            </ul>
          </section>

        </div>
      </main>
    </div>
  );
};

export default APIDocumentation;
