import CryptoJS from 'crypto-js';
import React, { Component, useState, useEffect, useMemo, memo } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchUserProfile,
  addUserwithPassword,
  addBusiness,
  fetchUsers,
  fetchBusiness,
  removeUser,
  deleteBusinessAndAccounts,
  updateUserStatus,
  updateUserSubscription,
  updateBusinessStatus,
  setUserWithApiKey,
  setUser,
  toggleApiKey,
  clearApiKey,
} from "./store.js";
import { getApiKey, saveApiKey, useBusinessId } from "./apiKeyManager.js";
import { generateApiKey } from "./apiKeyManager.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";
import { supabase } from "./supabaseClient.js";
import BillingManagement from "./components/BillingManagement.js";
import { ADMIN_CONSOLE_SECTIONS, DEFAULT_PERMISSIONS } from "./rolePermissions.js";

// Define fetchApiUsageLogs function at the top level
const fetchApiUsageLogs = async (businessId) => {
  if (!businessId) return [];

  try {
    const { data, error } = await supabase
      .from("api_usage_logs")
      .select(`
        *,
        grids (
          name
        )
      `)
      .eq("business_id", businessId)
      .order("created_at", { ascending: false });

    if (error) throw error;

    return data.map((log) => ({
      ...log,
      grid_name: log.grids?.name || null,
    }));
  } catch (error) {
    console.error("Error fetching API logs:", error);
    return [];
  }
};

// Memoized ApiUsageLogs component
const ApiUsageLogs = memo(({ businessId }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const loadLogs = async () => {
      if (!businessId) return;

      try {
        setLoading(true);
        setError(null);
        const data = await fetchApiUsageLogs(businessId);
        if (isMounted) {
          setLogs(data);
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    loadLogs();

    return () => {
      isMounted = false;
    };
  }, [businessId]);

  if (loading && !logs.length) {
    return <div className="text-center py-4">Loading API usage logs...</div>;
  }

  if (error) {
    return <div className="text-red-500 py-4">Error loading logs: {error}</div>;
  }

  return (
    <div className="table-container">
      <table className="api-usage-table">
        <thead>
          <tr>
            <th>Action</th>
            <th>Grid</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {logs.length > 0 ? (
            logs.map((log) => (
              <tr key={log.id}>
                <td>
                  <span className={`action-type ${log.action_type.toLowerCase()}`}>
                    {log.action_type}
                  </span>
                </td>
                <td>
                  {log.grid_name ? (
                    <span title={log.grid_id}>{log.grid_name}</span>
                  ) : (
                    <span className="text-gray-400">-</span>
                  )}
                </td>
                <td>{new Date(log.created_at).toLocaleString()}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="text-center py-4">
                No API usage logs found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
});

// ApiKeyDisplay component
const ApiKeyDisplay = memo(({ apiKey, isVisible, onClose }) => {
  const [isRevealed, setIsRevealed] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(apiKey);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  if (!isVisible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" style={{ 
        maxWidth: "500px",
        maxHeight: "140px",
        backgroundColor: "#1f2937",
        padding: "8px",
        display: "flex",
        flexDirection: "column"
      }}>
        <div className="modal-header" style={{ 
          borderBottom: "1px solid #374151",
          padding: "4px 8px",
          marginBottom: "4px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}>
          <h2 className="modal-title" style={{ 
            color: "#f3f4f6",
            fontSize: "0.9rem",
            margin: 0 
          }}>API Key</h2>
          <button
            onClick={onClose}
            className="close-button"
            style={{
              color: "#9ca3af",
              padding: "0 4px",
              fontSize: "14px",
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
            }}
          >
            ×
          </button>
        </div>
        <div className="modal-body" style={{ 
          padding: "4px",
          display: "flex",
          alignItems: "center",
          gap: "8px"
        }}>
          <code style={{ 
            flex: 1,
            padding: "4px 8px",
            backgroundColor: "#111827",
            borderRadius: "4px",
            border: "1px solid #374151",
            fontSize: "12px",
            color: "#f3f4f6",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}>
            {isRevealed ? apiKey : "•".repeat(apiKey.length)}
          </code>
          <div style={{ display: "flex", gap: "4px" }}>
            <button
              onClick={() => setIsRevealed(!isRevealed)}
              className="toolbar-button"
              style={{ padding: "4px 8px", fontSize: "12px" }}
            >
              <FontAwesomeIcon
                icon={isRevealed ? faEyeSlash : faEye}
                style={{ marginRight: "4px" }}
              />
              {isRevealed ? "Hide" : "Show"}
            </button>
            <button
              onClick={handleCopy}
              className="toolbar-button"
              style={{ padding: "4px 8px", fontSize: "12px" }}
            >
              <FontAwesomeIcon
                icon={isCopied ? faCheck : faCopy}
                style={{ marginRight: "4px" }}
              />
              {isCopied ? "Copied!" : "Copy"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

// Add this class component before the MemoizedBillingSection
class BillingErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Billing Error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-container">
          <h3>Something went wrong with the billing section.</h3>
          <button 
            onClick={() => this.setState({ hasError: false })}
            className="retry-button"
          >
            Try Again
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

// Move this outside the AdminConsole component
const MemoizedBillingSection = memo(({ businessId, apiUsageLogs }) => {
  return (
    <div className="card billing-section">
      <BillingErrorBoundary>
        <BillingManagement 
          businessId={businessId} 
          apiLogs={apiUsageLogs.filter(log => log.business_id === businessId)} 
        />
      </BillingErrorBoundary>
    </div>
  );
});

const PermissionsModal = ({ isOpen, onClose, user, onSave }) => {
  const [selectedPermissions, setSelectedPermissions] = useState(user.permissions || []);

  const handlePermissionChange = (permission) => {
    setSelectedPermissions(prev => {
      if (prev.includes(permission)) {
        return prev.filter(p => p !== permission);
      } else {
        return [...prev, permission];
      }
    });
  };

  const handleSave = () => {
    onSave(user.id, selectedPermissions);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2 className="modal-title">Edit Permissions - {user.name}</h2>
        <div className="permissions-grid">
          {Object.entries(ADMIN_CONSOLE_SECTIONS).map(([key, value]) => (
            <label key={key} className="permission-item">
              <input
                type="checkbox"
                checked={selectedPermissions.includes(value)}
                onChange={() => handlePermissionChange(value)}
              />
              <span>{key.replace(/_/g, ' ')}</span>
            </label>
          ))}
        </div>
        <div className="modal-actions">
          <button className="cancel-button" onClick={onClose}>Cancel</button>
          <button className="save-button" onClick={handleSave}>Save Changes</button>
        </div>
      </div>
    </div>
  );
};

const AdminConsole = () => {
  const [profileRole, setProfileRole] = useState(null);
  const [users, setUsers] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [businessSearchTerm, setBusinessSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredBusiness, setFilteredBusiness] = useState([]);
  const [isApiKeyModalOpen, setIsApiKeyModalOpen] = useState(false);
  const [currentApiKey, setCurrentApiKey] = useState("");
  const [businessId, setBusinessId] = useState(null);
  const [userStats, setUserStats] = useState({
    totalUsers: 0,
    activeUsers: 0,
    adminUsers: 0,
    businessUsers: 0,
    individualUsers: 0,
    totalBusinesses: 0,
    activeBusiness: 0,
  });
  const [apiLogs, setApiLogs] = useState([]);
  const [apiUsageLogs, setApiUsageLogs] = useState([]);
  const [currentBusinessLogs, setCurrentBusinessLogs] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    status: "active",
    subscription_status: "active",
    business_id: "",
    permissions: [],
  });
  const [businessFormData, setBusinessFormData] = useState({
    businessName: "",
    adminName: "",
    adminEmail: "",
    adminPassword: "",
    businessStatus: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isPermissionsModalOpen, setIsPermissionsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  // Memoize ApiUsageLogs component
  const MemoizedApiUsageLogs = useMemo(
    () => <ApiUsageLogs businessId={businessId} />,
    [businessId]
  );

  const secretKey = 'P)9G=*vJ5}ievOzOYeNn&~KX,nz<V2=}-Y<?M3"9^P<A';

  // Decrypt user data
  const decryptData = (cipherText, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  // First useEffect - User check
  useEffect(() => {
    const checkAccess = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          navigate('/login');
          return;
        }

        // Get user's account details with business information
        const { data: account, error: accountError } = await supabase
          .from('accounts')
          .select(`
            role,
            id,
            business_id,
            business:business_id (
              id,
              name,
              status,
              api_key_enabled,
              api_key_last_generated
            )
          `)
          .eq('id', user.id)
          .single();

        if (accountError) {
          console.error('Error fetching account:', accountError);
          navigate('/dashboard');
          return;
        }

        setProfileRole(account.role);
        
        if (account.role === 'business_owner' || account.role === 'business') {
          if (account.business) {
            setBusinessId(account.business.id);
            setBusinesses([account.business]);
            setFilteredBusiness([account.business]);
            await fetchData(account.business.id);
          } else {
            console.error('No business association found');
            navigate('/dashboard');
          }
        } else {
          // Admin can see all data
          await fetchData();
        }
      } catch (error) {
        console.error('Error in checkAccess:', error);
        navigate('/dashboard');
      } finally {
        setLoading(false);
      }
    };

    checkAccess();
  }, [navigate]);

  // Second useEffect - Permissions
  useEffect(() => {
    const fetchPermissions = async () => {
      if (!user?.id) return;

      try {
        const { data: account, error } = await supabase
          .from('accounts')
          .select('role, permissions')
          .eq('id', user.id)
          .single();

        if (error) throw error;

        if (account.role === 'business_owner') {
          // Set all permissions for business owner
          const allPermissions = Object.values(ADMIN_CONSOLE_SECTIONS);
          setUserPermissions(allPermissions);
          
          // Update the database to ensure permissions are saved
          await supabase
            .from('accounts')
            .update({ permissions: allPermissions })
            .eq('id', user.id);
        } else if (account.permissions) {
          setUserPermissions(account.permissions);
        }
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    };

    fetchPermissions();
  }, [user?.id]);

  // Third useEffect - Search filtering
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      const results = users.filter((user) => 
        user.name?.toLowerCase().includes(searchTerm.toLowerCase()) || 
        user.email?.toLowerCase().includes(searchTerm.toLowerCase()) || 
        user?.business?.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredUsers(results);
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [searchTerm, users]);

  // Fourth useEffect - Business search filtering
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      const businessResults = businesses.filter((business) => 
        (business.name?.toLowerCase().includes(businessSearchTerm.toLowerCase()) || false) ||
        (business.account_email?.toLowerCase().includes(businessSearchTerm.toLowerCase()) || false)
      );
      setFilteredBusiness(businessSearchTerm !== "" ? businessResults : businesses);
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [businessSearchTerm, businesses]);

  // Fetch data function
  const fetchData = async (initialBusinessId) => {
    try {
      setLoading(true);
      setError(null);
      
      if (!user) {
        throw new Error('No user found');
      }
      
      // Fetch business data with API keys
      let businessQuery = supabase
        .from('business')
        .select(`
          *,
          api_keys (
            id,
            api_key,
            last_used_at,
            usage_count
          )
        `);

      // Only fetch specific business if not admin or if initialBusinessId is provided
      if (initialBusinessId) {
        businessQuery = businessQuery.eq('id', initialBusinessId);
      } else if (profileRole !== 'admin') {
        // For non-admin users without a specific business ID, use their assigned business
        const { data: account } = await supabase
          .from('accounts')
          .select('business_id')
          .eq('id', user.id)
          .single();
        
        if (account?.business_id) {
          businessQuery = businessQuery.eq('id', account.business_id);
        }
      }

      const { data: businessData, error: businessError } = await businessQuery;
      
      if (businessError) throw businessError;
      
      setBusinesses(businessData || []);
      setFilteredBusiness(businessData || []);

      // Fetch users based on business association
      if (businessData && businessData.length > 0) {
        let userQuery = supabase
          .from('accounts')
          .select(`
            *,
            business:business_id (
              name
            )
          `)
          .order('created_at', { ascending: false });

        // Filter users by business_id if not admin
        if (profileRole !== 'admin') {
          userQuery = userQuery.eq('business_id', initialBusinessId);
        } else {
          // For admin, get users from all fetched businesses
          const businessIds = businessData.map(b => b.id);
          userQuery = userQuery.in('business_id', businessIds);
        }

        const { data: userData, error: userError } = await userQuery;
        if (userError) throw userError;

        setUsers(userData || []);
        setFilteredUsers(userData || []);
      }

      // Fetch API usage logs for the specific business
      if (initialBusinessId) {
        const logs = await fetchApiUsageLogs(initialBusinessId);
        setApiUsageLogs(logs);
      }
    } catch (error) {
      console.error('Error in fetchData:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading || !user) {
    return (
      <div style={{ 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center", 
        height: "100vh", 
        backgroundColor: "#111827" 
      }}>
        <div style={{ 
          fontSize: "1.5rem", 
          fontWeight: 600, 
          color: "#F3F4F6",
          fontFamily: "'Roboto', sans-serif"
        }}>
          Loading...
        </div>
      </div>
    );
  }

  if (!user) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const consolidateApiKeys = async (businessId) => {
    try {
      // Get all API keys for the business
      const { data: apiKeys, error: fetchError } = await supabase
        .from('api_keys')
        .select('*')
        .eq('business_id', businessId);

      if (fetchError) throw fetchError;

      // Keep the most recently used key
      const primaryKey = apiKeys.reduce((latest, current) => {
        if (!latest.last_used_at) return current;
        if (!current.last_used_at) return latest;
        return new Date(current.last_used_at) > new Date(latest.last_used_at) ? current : latest;
      }, apiKeys[0]);

      // Delete all other keys
      if (apiKeys.length > 1) {
        const { error: deleteError } = await supabase
          .from('api_keys')
          .delete()
          .eq('business_id', businessId)
          .neq('id', primaryKey.id);

        if (deleteError) throw deleteError;
      }

      return primaryKey;
    } catch (error) {
      console.error('Error consolidating API keys:', error);
      throw error;
    }
  };

  const handleViewApiKey = async (businessId) => {
    if (!businessId) {
      console.error('Business ID is undefined');
      setError('Invalid business ID');
      return;
    }

    try {
      // Get the business's API key directly from the businesses state
      const business = businesses.find(b => b.id === businessId);
      if (!business) {
        throw new Error('Business not found');
      }

      let apiKey;
      
      // Check if business already has an API key in the api_keys array
      if (business.api_keys?.length > 0) {
        // If multiple keys exist, use the first one (most recent)
        apiKey = business.api_keys[0].api_key;
      } else {
        // Generate new API key only if none exists
        const newApiKey = generateApiKey();
        await saveApiKey(businessId, newApiKey, user.id);
        apiKey = newApiKey;
        
        // Update business record
        await supabase
          .from('business')
          .update({ 
            api_key_last_generated: new Date().toISOString(),
            api_key_enabled: true 
          })
          .eq('id', businessId);
      }

      setCurrentApiKey(apiKey);
      setIsApiKeyModalOpen(true);
      
      // Only fetch new data if we generated a new key
      if (!business.api_keys?.length) {
        await fetchData(businessId);
      }
    } catch (error) {
      console.error('Error handling API key:', error);
      setError('Failed to handle API key operation');
    }
  };
  
  const handleRegenerateApiKey = async (businessId) => {
    try {
      const newApiKey = generateApiKey();
      await saveApiKey(businessId, newApiKey);

      // Update the business record with the generation timestamp
      await supabase
        .from('business')
        .update({ 
          api_key_last_generated: new Date().toISOString(),
          api_key_enabled: true 
        })
        .eq('id', businessId);

      // Refresh the business data
      await fetchData();
      
      setCurrentApiKey(newApiKey);
      setIsApiKeyModalOpen(true);
    } catch (error) {
      console.error('Error regenerating API key:', error);
      setError('Failed to regenerate API key');
    }
  };
  
  const handleViewDocumentation = () => {
    navigate('/api-documentation');
  };

  const handleBusinessChange = (e) => {
    const { name, value } = e.target;
    setBusinessFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUserStatusChange = async (userId, newStatus) => {
    try {
      setUsers(users.map((user) => (user.id === userId ? { ...user, status: newStatus } : user)));
      await handleUpdateUserStatus(userId, newStatus);
    } catch (error) {
      console.error("Error updating user status:", error);
      setError("Failed to update user status. Please try again.");
    }
  };

  const handleUserSupscriptionChange = async (userId, newStatus) => {
    try {
      setUsers(users.map((user) => (user.id === userId ? { ...user, subscription_status: newStatus } : user)));
      await handleUpdateUserSupscription(userId, newStatus);
    } catch (error) {
      console.error("Error updating user status:", error);
      setError("Failed to Update user status. Please try again.");
    }
  };

  const handleBusinessStatusChange = async (businessId, newStatus) => {
    try {
      setBusinesses(businesses.map((business) => (business.id === businessId ? { ...business, status: newStatus } : business)));
      await dispatch(updateBusinessStatus({ id: businessId, status: newStatus }));
    } catch (error) {
      console.error("Error updating business status:", error);
      setError("Failed to update business status. Please try again.");
    }
  };

  const handleAddUser = async () => {
    if (!formData.name || !formData.email || !formData.password || !formData.role || !formData.business_id) {
      setError('Please fill in all required fields');
      return;
    }

    setIsLoading(true);
    try {
      // Store the current session before creating new user
      const { data: { session: currentSession } } = await supabase.auth.getSession();

      const userData = {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        role: formData.role,
        status: 'active',
        subscription_status: 'active',
        business_id: formData.business_id,
        permissions: formData.permissions || []
      };

      await dispatch(addUserwithPassword(userData)).unwrap();

      // Restore the original session
      if (currentSession) {
        await supabase.auth.setSession(currentSession);
      }

      // Reset form
      setFormData({
        name: '',
        email: '',
        password: '',
        role: '',
        status: 'active',
        subscription_status: 'active',
        business_id: '',
        permissions: []
      });

      // Refresh data
      await fetchData(formData.business_id);
      await fetchUserStats(formData.business_id);
      
    } catch (error) {
      console.error('Error adding user:', error);
      setError(error.message || 'Failed to add user');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddBusiness = async () => {
    if (!businessFormData.businessStatus) {
      alert("Please select all dropdown fields.");
      return;
    }

    try {
      const actionResult = await dispatch(addBusiness(businessFormData)).unwrap();
      console.log("Business Created Successfully:", actionResult);
      alert("Business Created Successfully");

      setBusinessFormData({
        businessName: "",
        adminName: "",
        adminEmail: "",
        adminPassword: "",
        businessStatus: "",
      });

      await fetchBusiness(businessId);
      await fetchUserStats(businessId);
    } catch (err) {
      if (err.message.includes("User with this email already exists")) {
        alert("User with this email already exists.");
      } else {
        alert("Error adding user. Please try again.");
      }
      console.error("Error adding user:", err);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await dispatch(removeUser(userId));
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
      await fetchUserStats(businessId);
    } catch (error) {
      console.error("Error deleting user:", error);
      setError("Failed to delete user. Please try again.");
    }
  };

  const handleDeleteBusiness = async (businessId) => {
    try {
      await dispatch(deleteBusinessAndAccounts(businessId));
      setBusinesses((prevBusinesses) => prevBusinesses.filter((business) => business.id !== businessId));
      await fetchData();
      await fetchUserStats(null);
    } catch (error) {
      console.error("Error deleting business:", error);
      setError("Failed to delete business. Please try again.");
    }
  };

  const handleUpdateUserStatus = async (userId, userStatus) => {
    try {
      await dispatch(updateUserStatus({ id: userId, status: userStatus }));
    } catch (error) {
      console.error("Error Updating user status:", error);
      setError("Failed to Update user status. Please try again.");
    }
  };

  const handleUpdateUserSupscription = async (userId, userSupscription) => {
    try {
      await dispatch(updateUserSubscription({ id: userId, subscription_status: userSupscription }));
    } catch (error) {
      console.error("Error Updating user status:", error);
      setError("Failed to Update user status. Please try again.");
    }
  };

  const fetchProfile = async (userId) => {
    try {
      const profile = await dispatch(fetchUserProfile(userId)).unwrap();
      console.log('Fetched profile:', profile);

      if (!profile) {
        navigate('/login');
        return;
      }

      setProfileRole(profile.role);
      
      if (profile.payload.role !== "admin" && profile.payload.role !== "business_owner") {
        navigate("/dashboard");
      } else {
        if (profile.payload.role === "business_owner") {
          fetchData(profile.payload.businessid);
        } else {
          fetchData();
        }
      }
      
      setLoading(false);
    } catch (err) {
      console.error('Error in fetchProfile:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleBusinessSearch = (e) => {
    setBusinessSearchTerm(e.target.value);
  };

  const handleApiKeyStatusChange = async (businessId, enabled) => {
    try {
      await dispatch(toggleApiKey({ businessId, enabled }));
      
      setBusinesses(prevBusinesses => 
        prevBusinesses.map(business => 
          business.id === businessId 
            ? { ...business, api_key_enabled: enabled }
            : business
        )
      );

      if (!enabled) {
        await dispatch(clearApiKey(businessId));
      }
    } catch (error) {
      console.error('Error updating API key status:', error);
      setError('Failed to update API key status');
    }
  };

  const fetchUserStats = async (businessId) => {
    try {
      let userQuery = supabase
        .from('business_users')
        .select('account_id');

      let businessQuery = supabase
        .from('business')
        .select('*');

      if (profileRole !== 'admin' && businessId) {
        userQuery = userQuery.eq('business_id', businessId);
        businessQuery = businessQuery.eq('id', businessId);
      }

      const [{ data: userJoins, error: userError }, { data: businesses, error: businessError }] = 
        await Promise.all([userQuery, businessQuery]);

      if (userError) throw userError;
      if (businessError) throw businessError;

      const accountIds = userJoins?.map(join => join.account_id) || [];
      
      const { data: users, error: accountsError } = await supabase
        .from('accounts')
        .select('*')
        .in('id', accountIds);

      if (accountsError) throw accountsError;

      const stats = {
        totalUsers: users.length,
        activeUsers: users.filter(user => user.status === 'active').length,
        adminUsers: users.filter(user => user.role === 'admin').length,
        businessOwnerUsers: users.filter(user => user.role === 'business_owner').length,
        businessUsers: users.filter(user => user.role === 'user').length,
        individualUsers: users.filter(user => user.role === 'individual').length,
        totalBusinesses: businesses.length,
        activeBusiness: businesses.filter(business => business.status === 1).length
      };

      setUserStats(stats);
    } catch (error) {
      console.error('Error fetching user stats:', error);
      setError('Failed to fetch user statistics');
    }
  };

  const handleUserRoleChange = async (userId, newRole) => {
    try {
      // If changing to business_owner, set all permissions
      const updatedPermissions = newRole === 'business_owner' 
        ? Object.values(ADMIN_CONSOLE_SECTIONS)
        : [];

      setUsers(prevUsers => 
        prevUsers.map((user) => 
          user.id === userId 
            ? { ...user, role: newRole, permissions: updatedPermissions } 
            : user
        )
      );

      const { error } = await supabase
        .from('accounts')
        .update({ 
          role: newRole,
          permissions: updatedPermissions
        })
        .eq('id', userId);
      
      if (error) throw error;
    } catch (error) {
      console.error("Error updating user role:", error);
      setError("Failed to update user role. Please try again.");
      setUsers(prevUsers => 
        prevUsers.map((user) => 
          user.id === userId ? { ...user, role: user.role } : user
        )
      );
    }
  };

  const handleUserPermissionsChange = async (userId, permissions) => {
    try {
      const { error } = await supabase
        .from('accounts')
        .update({ permissions })
        .eq('id', userId);
      
      if (error) throw error;
      
      setUsers(prevUsers => 
        prevUsers.map(user => 
          user.id === userId ? { ...user, permissions } : user
        )
      );
    } catch (error) {
      console.error("Error updating user permissions:", error);
      setError("Failed to update user permissions. Please try again.");
    }
  };

  const handleEditPermissions = (user) => {
    setSelectedUser(user);
    setIsPermissionsModalOpen(true);
  };

  const renderPermissionsSelect = () => {
    if (formData.role !== 'admin') return null;
    
    return (
      <div className="permissions-select">
        <label>Admin Permissions</label>
        <div className="checkbox-group">
          {Object.entries(ADMIN_CONSOLE_SECTIONS).map(([key, value]) => (
            <label key={key}>
              <input
                type="checkbox"
                name="permissions"
                value={value}
                checked={formData.permissions.includes(value)}
                onChange={(e) => {
                  const permissions = e.target.checked
                    ? [...formData.permissions, value]
                    : formData.permissions.filter(p => p !== value);
                  setFormData(prev => ({ ...prev, permissions }));
                }}
              />
              {key.replace(/_/g, ' ')}
            </label>
          ))}
        </div>
      </div>
    );
  };

   const renderApiManagement = () => (
    <div className="card api-management">
      <h2>API Management</h2>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>API Key Status</th>
              <th>Last Used</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {(profileRole === "admin" ? filteredBusiness : businesses.filter(b => b.id === businessId))
              .filter(business => business !== undefined)
              .map((business) => {
                const hasApiKey = business?.api_keys?.length > 0;
                return (
                  <tr key={business.id}>
                    <td>
                      <span className={`status-badge ${hasApiKey ? 'enabled' : 'disabled'}`}>
                        {hasApiKey ? 'Enabled' : 'Disabled'}
                      </span>
                    </td>
                    <td>
                      {business.api_keys?.[0]?.last_used_at
                        ? new Date(business.api_keys[0].last_used_at).toLocaleDateString()
                        : 'Never'}
                    </td>
                    <td>
                      <button 
                        className="api-button view-button" 
                        onClick={() => handleViewApiKey(business.id)}
                      >
                        {hasApiKey ? 'View API Key' : 'Generate API Key'}
                      </button>
                      <button 
                        className="api-button docs-button"
                        onClick={handleViewDocumentation}
                      >
                        View Documentation
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      
      {(profileRole === "admin" ? filteredBusiness : businesses.filter(b => b.id === businessId))
        .filter(business => business !== undefined)
        .map((business) => (
          <div key={`logs-${business.id}`} className="api-usage-section">
            <h3>API Usage Logs - {business.name}</h3>
            {businessId === business.id && MemoizedApiUsageLogs}
          </div>
        ))}
      
      {isApiKeyModalOpen && (
        <ApiKeyDisplay 
          apiKey={currentApiKey}
          isVisible={isApiKeyModalOpen}
          onClose={() => setIsApiKeyModalOpen(false)}
        />
      )}
    </div>
  );

  // Render user management table
  const renderUserManagement = () => (
    <div className="card user-management">
      <h2>User Management</h2>
      <div className="user-management-section">
        <h3>Add User</h3>
        <div className="add-user-section">
          <select 
            name="business_id" 
            className="add-user-select" 
            value={formData.business_id} 
            onChange={handleChange}
            required
          >
            <option value="">Select Business</option>
            {businesses.map((business) => (
              <option key={business.id} value={business.id}>
                {business.name}
              </option>
            ))}
          </select>
          <input 
            type="text" 
            name="name" 
            placeholder="Name" 
            className="add-user-input" 
            value={formData.name} 
            onChange={handleChange} 
            required 
          />
          <input 
            type="email" 
            name="email" 
            placeholder="Email" 
            className="add-user-input" 
            value={formData.email} 
            onChange={handleChange} 
            required 
          />
          <input 
            type="password" 
            name="password" 
            placeholder="Password" 
            className="add-user-input" 
            value={formData.password} 
            onChange={handleChange} 
            required 
          />
          <select 
            name="role" 
            className="add-user-select" 
            value={formData.role} 
            onChange={handleChange}
            required
          >
            <option value="admin">Business Admin</option>
            <option value="user">Business User</option>
          </select>
          {renderPermissionsSelect()}
          <button 
            className="add-user-button" 
            onClick={handleAddUser}
            disabled={isLoading}
          >
            {isLoading ? 'Adding...' : 'Add User'}
          </button>
        </div>
      </div>
      <div className="user-management-section">
        <h3>Search Users</h3>
        <input type="text" placeholder="Search by name or email or business" className="search-input" value={searchTerm} onChange={handleSearch} />
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Status</th>
              <th>Subscription</th>
              <th>Permissions</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>
                  {user.role === 'business_owner' ? (
                    <span>Business Owner</span>
                  ) : (
                    <select
                      value={user.role}
                      onChange={(e) => handleUserRoleChange(user.id, e.target.value)}
                      disabled={user.role === 'business_owner'}
                    >
                      <option value="admin">Business Admin</option>
                      <option value="user">Business User</option>
                    </select>
                  )}
                </td>
                <td>
                  <select 
                    value={user.status} 
                    onChange={(e) => handleUserStatusChange(user.id, e.target.value)}
                  >
                    <option value="active">Active</option>
                    <option value="suspended">Suspended</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </td>
                <td>
                  <select 
                    value={user.subscription_status} 
                    onChange={(e) => handleUserSupscriptionChange(user.id, e.target.value)}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    <option value="cancelled">Cancelled</option>
                    <option value="suspended">Suspended</option>
                    <option value="trial">Trial</option>
                  </select>
                </td>
                <td>
                  <button
                    className="edit-permissions-button"
                    onClick={() => handleEditPermissions(user)}
                  >
                    Edit Permissions
                  </button>
                </td>
                <td>
                  <button 
                    className="delete-button" 
                    onClick={() => handleDeleteUser(user.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const BusinessCard = ({ business }) => {
    return (
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <div className="flex justify-between items-start mb-4">
          <div>
            <h2 className="text-xl font-bold">{business.name}</h2>
            <p className="text-gray-600">{business.email}</p>
          </div>
          <div className="text-right">
            <p className="text-sm text-gray-600">
              API Key Last Generated: {business.api_key_last_generated ? 
                new Date(business.api_key_last_generated).toLocaleDateString() : 'Never'}
            </p>
            <p className="text-sm text-gray-600">
              Last API Usage: {business.api_keys?.last_used_at ? 
                new Date(business.api_keys.last_used_at).toLocaleDateString() : 'Never'}
            </p>
          </div>
        </div>
        
        <div className="flex gap-2 mb-4">
          <button
            onClick={() => handleRegenerateApiKey(business.id)}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Regenerate API Key
          </button>
          <button
            onClick={handleViewDocumentation}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
          >
            View API Documentation
          </button>
        </div>

        {businessId === business.id && MemoizedApiUsageLogs}
      </div>
    );
  };

  const canAccessSection = (section) => {
    return profileRole === 'business_owner' || userPermissions.includes(section);
  };

  // Render component
  if (loading || !user) {
    return <div>Loading...</div>;
  }
  

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="text-center mt-8 text-red-500 bg-red-100 p-4 rounded-lg shadow-md">{error}</div>
      </div>
    );
  }

  return (
    <div className="admin-console">
      <header className="admin-header">
        <Link to="/dashboard" className="back-link">
          <svg className="back-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M19 12H5M12 19l-7-7 7-7" />
          </svg>
          <span className="back-text">Back to Dashboard</span>
        </Link>
        <h1>Admin Console</h1>
      </header>
      <div className="admin-content">
        <main className="admin-main">
          {canAccessSection(ADMIN_CONSOLE_SECTIONS.USER_MANAGEMENT) && renderUserManagement()}
          {canAccessSection(ADMIN_CONSOLE_SECTIONS.API_MANAGEMENT) && renderApiManagement()}
          {canAccessSection(ADMIN_CONSOLE_SECTIONS.BILLING_MANAGEMENT) && (
            <MemoizedBillingSection 
              businessId={businessId} 
              apiUsageLogs={apiUsageLogs}
            />
          )}
        </main>
      </div>

      {isPermissionsModalOpen && selectedUser && (
        <PermissionsModal
          isOpen={isPermissionsModalOpen}
          onClose={() => setIsPermissionsModalOpen(false)}
          user={selectedUser}
          onSave={handleUserPermissionsChange}
        />
      )}

      <style>{`
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        html, body, #root {
          margin: 0;
          padding: 0;
          background-color: #111827;
          min-height: 100vh;
          width: 100%;
          overflow-x: hidden;
          font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
        }

        .admin-console {
          display: flex;
          flex-direction: column;
          min-height: 100vh;
          background-color: #111827;
          color: #f3f4f6;
          margin: 0;
          padding: 0;
          width: 100%;
          overflow-x: hidden;
          font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
        }

        /* Update font-family in existing button styles */
        .retake-button,
        .undo-button {
          font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
          font-weight: 500;
        }

        /* Update all headings */
        h1, h2, h3, h4, h5, h6 {
          font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
        }

        /* Update form elements */
        .add-user-input,
        .add-user-select,
        .search-input {
          font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
        }

        /* Update table elements */
        table {
          font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
        }

        /* Update buttons */
        .add-user-button,
        .delete-button {
          font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
        }

        .admin-content {
          flex: 1;
          background-color: #111827;
          margin: 0;
          padding: 0;
          width: 100%;
        }

        .admin-main {
          max-width: 1200px;
          margin: 0 auto;
          padding: 1rem;
          background-color: #111827;
        }

        .card {
          background-color: #1f2937;
          border-radius: 0.5rem;
          padding: 1.5rem;
          margin-bottom: 1.5rem;
          border: none;
          box-shadow: none;
        }

        .table-container {
          margin: 0;
          padding: 0;
          width: 100%;
          background-color: #1f2937;
        }

        table {
          width: 100%;
          border-collapse: collapse;
          background-color: #1f2937;
          border: none;
        }

        th, td {
          padding: 0.75rem;
          text-align: left;
          border-bottom: 1px solid #374151;
          background-color: #1f2937;
          color: #f3f4f6;
        }

        th {
          background-color: #1f2937;
          font-weight: 600;
          color: #f3f4f6;
          border-bottom: 2px solid #374151;
        }

        .admin-header {
          background-color: #1f2937;
          padding: 1rem;
          margin: 0;
          border: none;
        }

        .admin-header {
          background-color: #1f2937;
          padding: 1rem 2rem;
          display: flex;
          align-items: center;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        .back-link {
          display: flex;
          align-items: center;
          color: #f3f4f6;
          text-decoration: none;
          margin-right: 2rem;
          transition: color 0.3s ease;
        }
        .back-link:hover {
          color: #9ca3af;
        }
        .back-arrow {
          width: 24px;
          height: 24px;
          margin-right: 0.5rem;
        }
        .back-text {
          opacity: 0;
          transform: translateX(-10px);
          transition: opacity 0.3s ease, transform 0.3s ease;
        }
        .back-link:hover .back-text {
          opacity: 1;
          transform: translateX(0);
        }
        .admin-header h1 {
          font-size: 1.5rem;
          font-weight: bold;
          color: #f3f4f6;
        }
        .admin-content {
          flex: 1;
          overflow-y: auto;
          padding-top: 2rem;
          border: none;
        }
        .admin-main {
          max-width: 1200px;
          margin: 0 auto;
          padding: 2rem;
          border: none;
        }
        .card {
          background-color: #1f2937;
          border-radius: 0.5rem;
          box-shadow: none;
          padding: 1.5rem;
          margin-bottom: 1.5rem;
          border: none;
        }
        h2 {
          color: #f3f4f6;
          font-size: 1.25rem;
          font-weight: 600;
          margin-bottom: 1rem;
        }
        .stat-cards {
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;
        }
        .stat-card {
          background-color: #374151;
          border-radius: 0.375rem;
          padding: 1rem;
          text-align: center;
          flex: 1;
          min-width: 110px;
        }
        .stat-card h3 {
          font-size: 0.875rem;
          font-weight: 500;
          color: #9ca3af;
          margin-bottom: 0.5rem;
        }
        .stat-card p {
          font-size: 1.5rem;
          font-weight: 700;
          color: #f3f4f6;
        }
        table {
          width: 100%;
          border-collapse: collapse;
          border: none;
        }
        th,
        td {
          padding: 0.75rem;
          text-align: left;
          border-bottom: 1px solid #374151;
          color: #f3f4f6;
          background-color: #1f2937;
        }
        th {
          background-color: #1f2937;
          font-weight: 600;
          color: #f3f4f6;
          border-bottom: 2px solid #374151;
        }
        select {
          padding: 0.375rem 0.75rem;
          border-radius: 0.375rem;
          border: 1px solid #374151;
          background-color: #1f2937;
          color: #f3f4f6;
          font-size: 0.875rem;
          cursor: pointer;
        }
        .chart-container {
          height: 300px;
        }
        @media (max-width: 768px) {
          .stat-cards {
            flex-direction: column;
          }
          .admin-header {
            flex-direction: column;
            align-items: flex-start;
          }
          .back-link {
            margin-right: 0;
            margin-bottom: 1rem;
          }
        }
        .user-email-link {
          color: #4338ca;
          text-decoration: none;
          transition: color 0.3s ease;
        }
        .user-email-link:hover {
          color: #3730a3;
          text-decoration: underline;
        }
        .add-user-section {
          display: flex;
          gap: 1rem;
          margin-bottom: 1rem;
        }
        .add-user-input,
        .add-user-select,
        .add-user-button {
          padding: 0.5rem;
          border-radius: 0.375rem;
          border: 1px solid #4b5563;
          font-size: 0.875rem;
          background-color: #374151;
          color: #f3f4f6;
        }
        .add-user-input {
          flex-grow: 1;
        }
        .add-user-button {
          background-color: #4338ca;
          color: white;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }
        .add-user-button:hover {
          background-color: #3730a3;
        }
        .user-management-section {
          margin-bottom: 1.5rem;
        }
        .user-management-section h3 {
          font-size: 1.1rem;
          font-weight: 600;
          color: #f3f4f6;
          margin-bottom: 0.75rem;
        }
        .search-input {
          width: 100%;
          padding: 0.5rem;
          border-radius: 0.375rem;
          border: 1px solid #4b5563;
          font-size: 0.875rem;
          background-color: #374151;
          color: #f3f4f6;
          margin-bottom: 1rem;
        }
        .user-name {
          display: flex;
          flex-direction: column;
        }
        .user-name-heading {
          font-weight: 600;
          color: #f3f4f6;
        }
        .user-name-subheading {
          font-size: 0.875rem;
          color: #9ca3af;
          margin-top: 0.25rem;
        }
        .api-button {
          border: none;
          border-radius: 0.5rem;
          padding: 0.5rem 1rem;
          font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
          font-weight: 500;
          font-size: 0.875rem;
          cursor: pointer;
          transition: background-color 0.2s ease;
          margin-right: 0.5rem;
        }

        .view-button {
          background-color: #4F46E5;
          color: #F3F4F6;
        }

        .view-button:hover {
          background-color: #4338CA;
        }

        .docs-button {
          background-color: #059669;
          color: #F3F4F6;
        }

        .docs-button:hover {
          background-color: #047857;
        }

        /* Update spacing between buttons in table cells */
        td .api-button:last-child {
          margin-right: 0;
        }

        /* Add to your existing styles */
        .regenerate-button {
          background-color: #2563eb;
          color: #F3F4F6;
        }

        .regenerate-button:hover {
          background-color: #1d4ed8;
        }

        .regenerate-button:disabled,
        .view-button:disabled {
          background-color: #6b7280;
          cursor: not-allowed;
          opacity: 0.7;
        }

        select {
          padding: 0.375rem 0.75rem;
          border-radius: 0.375rem;
          border: 1px solid #374151;
          background-color: #1f2937;
          color: #f3f4f6;
          font-size: 0.875rem;
          cursor: pointer;
        }

        select:focus {
          outline: 2px solid #4338ca;
          outline-offset: 2px;
        }

        .error-container {
          padding: 1rem;
          background-color: #374151;
          border-radius: 0.5rem;
          text-align: center;
        }

        .retry-button {
          margin-top: 1rem;
          padding: 0.5rem 1rem;
          background-color: #4338ca;
          color: white;
          border: none;
          border-radius: 0.375rem;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }

        .retry-button:hover {
          background-color: #3730a3;
        }

        .permissions-select {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }

        .permissions-select label {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-size: 0.875rem;
          color: #f3f4f6;
        }

        .permissions-select input[type="checkbox"] {
          width: 1rem;
          height: 1rem;
          cursor: pointer;
        }

        .checkbox-group {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          margin-top: 0.5rem;
        }

        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .modal-content {
          background-color: #1f2937;
          padding: 1rem;
          border-radius: 0.5rem;
          width: 90%;
          max-width: 400px;
          max-height: 300px;
          color: #f3f4f6;
          overflow-y: auto;
        }

        .modal-title {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 0.5rem;
          padding-bottom: 0.5rem;
          border-bottom: 1px solid #374151;
        }

        .permissions-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 0.5rem;
          margin: 0.75rem 0;
          padding: 0.5rem;
        }

        .permission-item {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-size: 0.875rem;
        }

        .modal-actions {
          display: flex;
          justify-content: flex-end;
          gap: 0.75rem;
          margin-top: 0.75rem;
          padding-top: 0.5rem;
          border-top: 1px solid #374151;
        }

        .cancel-button, .save-button {
          padding: 0.375rem 0.75rem;
          border-radius: 0.375rem;
          font-size: 0.875rem;
        }

        .edit-permissions-button {
          background-color: #4338ca;
          color: white;
          padding: 0.5rem 1rem;
          border-radius: 0.375rem;
          border: none;
          cursor: pointer;
          transition: background-color 0.2s;
        }

        .edit-permissions-button:hover {
          background-color: #3730a3;
        }

        .cancel-button, .save-button {
          padding: 0.375rem 0.75rem;
          border-radius: 0.375rem;
          font-size: 0.875rem;
        }

        .cancel-button {
          background-color: #4b5563;
          color: white;
        }

        .save-button {
          background-color: #4338ca;
          color: white;
        }

        .cancel-button:hover {
          background-color: #374151;
        }

        .save-button:hover {
          background-color: #3730a3;
        }
      `}</style>
    </div>
  );
};

export default AdminConsole;
