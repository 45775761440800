export const ADMIN_CONSOLE_SECTIONS = {
  USER_MANAGEMENT: 'user_management',
  API_MANAGEMENT: 'api_management',
  BILLING_MANAGEMENT: 'billing_management',
  VIEW_API_GRIDS: 'view_api_grids'
};

export const DEFAULT_PERMISSIONS = {
  business_owner: Object.values(ADMIN_CONSOLE_SECTIONS),
  business: [
    ADMIN_CONSOLE_SECTIONS.USER_MANAGEMENT,
    ADMIN_CONSOLE_SECTIONS.API_MANAGEMENT,
    ADMIN_CONSOLE_SECTIONS.BILLING_MANAGEMENT
  ],
  user: []
}; 