import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../../styles/App.css";
import { jsPDF } from "jspdf";
import "svg2pdf.js";
import html2canvas from "html2canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft, faPlus, faMinus, faArrowRight, faBars, faHome, faSave, faMap, faExpand, faUndo, faRedo, faTrash, faFilePdf, faDownload, faTimes, faChevronDown} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import MapSelector from './MapSelector.js';
import { useSelector } from 'react-redux';
import { supabase } from '../../supabaseClient';

const CADApp = ({ apiKey, onGridSave, onGridLoad, onError, isApiMode, currentGrid }) => {
  const user = useSelector(state => state.auth.user);
  const params = useParams();
  const navigate = useNavigate();
  const svgRef = useRef(null);
  
  // Determine gridId after hooks
  const gridId = isApiMode ? null : params?.gridId;
  
  // Move pages state here
  const [pages, setPages] = useState([
    {
      shapes: [],
      points: [],
      viewBox: [{ x: 0, y: 0, width: 800, height: 600, zoom: 1 }]
    }
  ]);
  
  const [currentPage, setCurrentPage] = useState(0);
  
  // Initialize other states that don't depend on pages
  const [gridName, setGridName] = useState("");
  const [savedGrids, setSavedGrids] = useState([]);
  const [currentGridId, setCurrentGridId] = useState(null);
  const baseGridSize = 10;
  const [viewBox, setViewBox] = useState({
    x: 0,
    y: 0,
    width: 800,
    height: 600,
    zoom: 1,
  });
  const [zoom, setZoom] = useState(1);
  const DEFAULT_VIEW_BOX = { x: 0, y: 0, width: 800, height: 600 };
  const DEFAULT_ZOOM = 1;
  const [previousViewBox, setPreviousViewBox] = useState(null);
  const [previousZoom, setPreviousZoom] = useState(null);
  const [minZoom, setMinZoom] = useState(0.1);
  const [maxZoom, setMaxZoom] = useState(5);
  const [currentZoom, setCurrentZoom] = useState(1);
  const updateViewBox = function (newViewBox, newZoom) {
    const sanitizedViewBox = sanitizeViewBox({
      ...newViewBox,
      zoom: newZoom,
    });
    setViewBox(sanitizedViewBox);
    setZoom(newZoom);
    setCurrentZoom(newZoom);
    console.log("Updated viewBox:", sanitizedViewBox);
  };
  const [gridDisplaySize, setGridDisplaySize] = useState(baseGridSize);
  const [isDragging, setIsDragging] = useState(false);
  const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 });
  const [points, setPoints] = useState([]);
  const [currentMousePos, setCurrentMousePos] = useState({ x: 0, y: 0 });
  const [shapes, setShapes] = useState([]);
  const [history, setHistory] = useState([]);
  const [future, setFuture] = useState([]);
  const [snapToGrid, setSnapToGrid] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [movingShape, setMovingShape] = useState(null);
  const [toolbarPosition, setToolbarPosition] = useState({ x: 20, y: 20 });
  const [toolbarOffset, setToolbarOffset] = useState({ x: 0, y: 0 });
  const [isMovingToolbar, setIsMovingToolbar] = useState(false);
  const [showGrid, setShowGrid] = useState(true);
  const [gridUnit, setGridUnit] = useState("feet");
  const [showMeasurements, setShowMeasurements] = useState(true);
  const [gridMeasurement, setGridMeasurement] = useState(1);
  const [rotatingShape, setRotatingShape] = useState(null);
  const [selectedShape, setSelectedShape] = useState(null);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [downloadOption, setDownloadOption] = useState("separatePages");
  const [pdfPreview, setPdfPreview] = useState(null);
  const [fileName, setFileName] = useState("drawing");
  const [orientation, setOrientation] = useState("portrait");
  const [labelPositions, setLabelPositions] = useState({});
  const [movingLabel, setMovingLabel] = useState(null);
  const [includeGridInPDF, setIncludeGridInPDF] = useState(true);
  const [contextMenu, setContextMenu] = useState(null);
  const [movingMultipleShapes, setMovingMultipleShapes] = useState(false);
  const [multiShapeOffset, setMultiShapeOffset] = useState({ x: 0, y: 0 });
  const [showMapSelector, setShowMapSelector] = useState(false);
  const [selecting, setSelecting] = useState(false);
  const [selectionBox, setSelectionBox] = useState(null);
  const [selectedShapes, setSelectedShapes] = useState(new Set());
  const [latestShapes, setLatestShapes] = useState([]);
  const [selectedShapeIndex, setSelectedShapeIndex] = useState(null);
  const [pendingRoofOutline, setPendingRoofOutline] = useState(null);
  const [isEditingGridName, setIsEditingGridName] = useState(false);
  const [isHoveringGridName, setIsHoveringGridName] = useState(false);
  const [editingShape, setEditingShape] = useState(null);
  const [localShapeLabels, setLocalShapeLabels] = useState([]);
  const [downloadShapeLabels, setDownloadShapeLabels] = useState([]);
  const [persistentShapeLabels, setPersistentShapeLabels] = useState([]);
    const [downloadTitle, setDownloadTitle] = useState(() => ({
    main: "Grid Layout",
    subtitles: [],
    shapeLabels: persistentShapeLabels
  }));

  // Update downloadTitle when pages or currentPage changes
  useEffect(() => {
    if (pages[currentPage]?.shapes) {
      setDownloadTitle(prev => ({
        ...prev,
        shapeLabels: pages[currentPage].shapes.map((_, index) => `Shape ${index + 1}`)
      }));
    }
  }, [pages, currentPage]);

  // Initialize localShapeLabels similarly
  useEffect(() => {
    if (pages[currentPage]?.shapes) {
      setLocalShapeLabels(
        pages[currentPage].shapes.map((_, index) => 
          downloadTitle.shapeLabels?.[index] || `Shape ${index + 1}`
        )
      );
    }
  }, [pages, currentPage, downloadTitle.shapeLabels]);

  // Add this effect to sync labels when shapes change
  useEffect(() => {
    if (pages[currentPage]?.shapes) {
      const newLabels = pages[currentPage].shapes.map((_, index) => 
        persistentShapeLabels[index] || `Shape ${index + 1}`
      );
      setPersistentShapeLabels(newLabels);
      setDownloadTitle(prev => ({
        ...prev,
        shapeLabels: newLabels
      }));
    }
  }, [pages, currentPage]);

  const handleOpenModal = () => {
    console.log("Opening download modal");
    const currentLabels = pages[currentPage].shapes.map((_, index) => 
      persistentShapeLabels[index] || `Shape ${index + 1}`
    );
    
    setLocalShapeLabels(currentLabels);
    setDownloadTitle(prevTitle => ({
      ...prevTitle,
      shapeLabels: currentLabels
    }));
    setShowDownloadModal(true);
    custom();
  };

  // Delete shape on delete key press
  useEffect(() => {
    // Define the event handler
    const handleKeyDown = (event) => {
      if (event.key === "Delete") {
        if (selectedShapeIndex !== null) {
          deleteSelectedShape();
        }
      }
    };

    // Attach the event listener
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedShapeIndex]); // Include selectedShapeIndex in the dependency array

  const navigateToDashboard = function () {
    navigate("/dashboard");
  };

  const unitConversions = {
    feet: 1,
    inches: 12,
    centimeters: 30.48,
    meters: 0.3048,
  };

  const unitAbbreviations = {
    feet: "ft",
    inches: "in",
    centimeters: "cm",
    meters: "m",
  };

  const gridMeasurementOptions = [0.1, 1, 2, 5, 10];

  const convertMetersToGridUnit = function (meters) {
    switch (gridUnit) {
      case "feet":
        return meters * 3.28084;
      case "inches":
        return meters * 39.3701;
      case "centimeters":
        return meters * 100;
      case "meters":
        return meters;
      default:
        return meters;
    }
  };

  const handleMapSelectorCancel = useCallback(() => {
    setShowMapSelector(false);
  }, []);

  const snapToGridPoint = function (point) {
    if (!snapToGrid) return point;
    return {
      x: Math.round(point.x / gridDisplaySize) * gridDisplaySize,
      y: Math.round(point.y / gridDisplaySize) * gridDisplaySize,
    };
  };

  const closeShape = useCallback(
    function () {
      if (pages[currentPage].points.length >= 3) {
        const newShape = {
          points: pages[currentPage].points.map(function (p) {
            return { x: p.x, y: p.y, gridMeasurement: p.gridMeasurement };
          }),
          gridMeasurement: gridMeasurement,
          origin: "drawn",
        };

        setPages(function (prevPages) {
          const newPages = [...prevPages];
          newPages[currentPage] = {
            ...newPages[currentPage],
            shapes: [...newPages[currentPage].shapes, newShape],
            points: [],
          };
          return newPages;
        });

        setHistory(function (prevHistory) {
          return [
            ...prevHistory,
            {
              type: "close",
              pages: pages.map((page, index) =>
                index === currentPage
                  ? { ...page, shapes: [...page.shapes, newShape], points: [] }
                  : page
              ),
            },
          ];
        });
        setFuture([]);

        return true;
      }
      return false;
    },
    [pages, currentPage, gridMeasurement]
  );

  const addPoint = useCallback(
    function (point) {
      // Don't add a point if there's an active selection
      if (selectedShapeIndex !== null || selectedShapes.size > 0) {
        return;
      }

      const snappedPoint = snapToGridPoint(point);

      if (pages[currentPage].points.length === 1) {
        const firstPoint = pages[currentPage].points[0];
        const distance = Math.sqrt(
          Math.pow(snappedPoint.x - firstPoint.x, 2) +
            Math.pow(snappedPoint.y - firstPoint.y, 2)
        );

        if (distance < 10) {
          setPages(function (prevPages) {
            const newPages = [...prevPages];
            newPages[currentPage] = { ...newPages[currentPage], points: [] };
            return newPages;
          });
          return;
        }
      }

      if (pages[currentPage].points.length >= 3) {
        const firstPoint = pages[currentPage].points[0];
        const distance = Math.sqrt(
          Math.pow(snappedPoint.x - firstPoint.x, 2) +
            Math.pow(snappedPoint.y - firstPoint.y, 2)
        );

        if (distance < 10) {
          const shapeClosed = closeShape();
          if (shapeClosed) {
            return;
          }
        }
      }

      const updatedPoint = {
        x: snappedPoint.x,
        y: snappedPoint.y,
        gridMeasurement: gridMeasurement,
      };
      setPages(function (prevPages) {
        const newPages = [...prevPages];
        newPages[currentPage] = {
          ...newPages[currentPage],
          points: [...newPages[currentPage].points, updatedPoint],
        };
        return newPages;
      });

      setHistory(function (prevHistory) {
        const newHistory = [
          ...prevHistory,
          {
            type: "add",
            pages: pages.map((page, index) =>
              index === currentPage
                ? {
                    ...page,
                    points: [...page.points, updatedPoint],
                    shapes: page.shapes,
                  }
                : page
            ),
          },
        ];
        return newHistory;
      });
      setFuture([]);
    },
    [
      pages,
      currentPage,
      gridMeasurement,
      snapToGridPoint,
      closeShape,
      selectedShapeIndex,
      selectedShapes,
    ]
  );

  const sendPayloadToAPI = async function (payload) {
    try {
      const response = await axios.post(
        "http://127.0.0.1:5000/api/detect-roof",
        payload
      );
      console.log("API Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error sending payload to API:", error);
      throw error;
    }
  };

  const convertGridUnitToMeters = useCallback(
    function (value) {
      switch (gridUnit) {
        case "feet":
          return value * 0.3048;
        case "inches":
          return value * 0.0254;
        case "centimeters":
          return value * 0.01;
        case "meters":
          return value;
        default:
          return value;
      }
    },
    [gridUnit]
  );

  const drawRoofOutline = function (apiResponse, center, zoom) {
    console.log("drawRoofOutline called with:", { apiResponse, center, zoom });

    if (!apiResponse.roofOutline || apiResponse.roofOutline.length === 0) {
      console.error("No valid roof outline received from API");
      return null;
    }

    const roofOutline = apiResponse.roofOutline[0];
    console.log("Roof outline:", roofOutline);

    const metersPerPixel =
      (156543.03392 * Math.cos((center.lat * Math.PI) / 180)) /
      Math.pow(2, zoom);

    const svgCoordinates = roofOutline.map(function (point) {
      const x = (point.x - 320) * metersPerPixel;
      const y = (point.y - 320) * metersPerPixel;
      return { x: x, y: y };
    });

    const realWorldLengths = roofOutline.map(function (point, index, arr) {
      const nextPoint = arr[(index + 1) % arr.length];
      return Math.sqrt(
        Math.pow((nextPoint.x - point.x) * metersPerPixel, 2) +
          Math.pow((nextPoint.y - point.y) * metersPerPixel, 2)
      );
    });

    const gridMeasurementInMeters = convertGridUnitToMeters(gridMeasurement);

    const scaleFactor = gridMeasurementInMeters / baseGridSize;

    const scaledSvgCoordinates = svgCoordinates.map(function (point) {
      return {
        x: Math.round(point.x / scaleFactor),
        y: Math.round(point.y / scaleFactor),
        gridMeasurement: gridMeasurement,
      };
    });

    const newShape = {
      points: scaledSvgCoordinates,
      gridMeasurement: gridMeasurement,
      realWorldLengths: realWorldLengths,
      scaleFactor: scaleFactor,
      origin: "imported",
    };

    console.log("drawRoofOutline completed", {
      gridMeasurement: gridMeasurement,
      realWorldLengths: realWorldLengths,
      scaleFactor: scaleFactor,
    });

    return newShape;
  };

  const fitShapeToScreen = function (shape) {
    if (!shape || !shape.points || shape.points.length === 0) {
      console.error("Invalid shape data");
      return;
    }

    const padding = 2 * baseGridSize;
    const minX =
      Math.floor(
        Math.min(
          ...shape.points.map(function (p) {
            return p.x;
          })
        ) / baseGridSize
      ) * baseGridSize;
    const maxX =
      Math.ceil(
        Math.max(
          ...shape.points.map(function (p) {
            return p.x;
          })
        ) / baseGridSize
      ) * baseGridSize;
    const minY =
      Math.floor(
        Math.min(
          ...shape.points.map(function (p) {
            return p.y;
          })
        ) / baseGridSize
      ) * baseGridSize;
    const maxY =
      Math.ceil(
        Math.max(
          ...shape.points.map(function (p) {
            return p.y;
          })
        ) / baseGridSize
      ) * baseGridSize;

    const shapeWidth = maxX - minX + 2 * padding;
    const shapeHeight = maxY - minY + 2 * padding;

    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const screenAspectRatio = screenWidth / screenHeight;
    const shapeAspectRatio = shapeWidth / shapeHeight;

    let newViewBox;

    if (shapeAspectRatio > screenAspectRatio) {
      const newWidth = shapeWidth;
      const newHeight = newWidth / screenAspectRatio;
      newViewBox = {
        x: minX - padding,
        y: minY - padding - (newHeight - shapeHeight) / 2,
        width: newWidth,
        height: newHeight,
        zoom: screenWidth / newWidth,
      };
    } else {
      const newHeight = shapeHeight;
      const newWidth = newHeight * screenAspectRatio;
      newViewBox = {
        x: minX - padding - (newWidth - shapeWidth) / 2,
        y: minY - padding,
        width: newWidth,
        height: newHeight,
        zoom: screenHeight / newHeight,
      };
    }

    return newViewBox;
  };

  const fitImportedShapesToView = function (shapes) {
    if (shapes.length === 0) return;
  
    let minX = Infinity,
        minY = Infinity,
        maxX = -Infinity,
        maxY = -Infinity;
  
    // Calculate the bounding box for all imported shapes
    shapes.forEach((shape) => {
      shape.points.forEach((point) => {
        minX = Math.min(minX, point.x);
        minY = Math.min(minY, point.y);
        maxX = Math.max(maxX, point.x);
        maxY = Math.max(maxY, point.y);
      });
    });
  
    const padding = baseGridSize * 4; // Add padding for better visibility
    const contentWidth = maxX - minX + 2 * padding;
    const contentHeight = maxY - minY + 2 * padding;
  
    const svgElement = svgRef.current;
    const svgRect = svgElement.getBoundingClientRect();
    const screenAspectRatio = svgRect.width / svgRect.height;
    const contentAspectRatio = contentWidth / contentHeight;
  
    let newWidth, newHeight;
    if (contentAspectRatio > screenAspectRatio) {
      newWidth = contentWidth;
      newHeight = newWidth / screenAspectRatio;
    } else {
      newHeight = contentHeight;
      newWidth = newHeight * screenAspectRatio;
    }
  
    const newZoom = Math.min(svgRect.width / newWidth, svgRect.height / newHeight);
    const newViewBox = {
      x: minX - padding,
      y: minY - padding,
      width: newWidth,
      height: newHeight,
      zoom: newZoom,
    };
  
    updateViewBox(newViewBox, newZoom);
    setZoom(newZoom);
    setCurrentZoom(newZoom);
    setMaxZoom(newZoom * 10); // Allow zooming in up to 10x from the fit view
    setMinZoom(newZoom / 10); // Allow zooming out up to 10x from the fit view
  
    console.log("Fitted imported shapes to view:", newViewBox);
  };

  const fitAllShapesToView = () => {
    if (pages[currentPage].shapes.length === 0) return;
  
    let minX = Infinity,
        minY = Infinity,
        maxX = -Infinity,
        maxY = -Infinity;
  
    // Calculate the bounding box for all shapes
    pages[currentPage].shapes.forEach((shape) => {
      shape.points.forEach((point) => {
        minX = Math.min(minX, point.x);
        minY = Math.min(minY, point.y);
        maxX = Math.max(maxX, point.x);
        maxY = Math.max(maxY, point.y);
      });
    });
  
    const padding = baseGridSize * 4; // Increased padding for better visibility
    const contentWidth = maxX - minX + 2 * padding;
    const contentHeight = maxY - minY + 2 * padding;
  
    const svgElement = svgRef.current;
    const svgRect = svgElement.getBoundingClientRect();
    const screenAspectRatio = svgRect.width / svgRect.height;
    const contentAspectRatio = contentWidth / contentHeight;
  
    let newWidth, newHeight;
    if (contentAspectRatio > screenAspectRatio) {
      newWidth = contentWidth;
      newHeight = newWidth / screenAspectRatio;
    } else {
      newHeight = contentHeight;
      newWidth = newHeight * screenAspectRatio;
    }
  
    const newZoom = Math.min(svgRect.width / newWidth, svgRect.height / newHeight);
    const newViewBox = {
      x: minX - padding,
      y: minY - padding,
      width: newWidth,
      height: newHeight,
      zoom: newZoom,
    };
  
    updateViewBox(newViewBox, newZoom);
    setZoom(newZoom);
    setCurrentZoom(newZoom);
    setMaxZoom(newZoom * 10); // Allow zooming in up to 10x from the fit-all view
    setMinZoom(newZoom / 10); // Allow zooming out up to 10x from the fit-all view
  
    console.log("Fitted all shapes to view:", newViewBox);
  };

const handleLocationSelect = function (location) {
  console.log("Selected Location:", location);
  setShowMapSelector(false);

  if (!location || !location.polygons || location.polygons.length === 0) {
    console.error("Invalid location data:", location);
    return;
  }

  const newShapes = location.polygons
    .map(function (polygon, index) {
      const roofOutline = polygon.points.map(function (point) {
        return {
          x: point.x,
          y: point.y,
          distance: point.distance,
        };
      });

      const shape = drawRoofOutline(
        { roofOutline: [roofOutline] },
        location.center,
        location.zoom
      );

      if (shape) {
        return {
          ...shape,
          id: `imported-${Date.now()}-${index}`,
        };
      }
      return null;
    })
    .filter(function (shape) {
      return shape !== null;
    });

  if (newShapes.length > 0) {
    setPages(function (prevPages) {
      const newPages = prevPages.map(function (page, pageIndex) {
        if (pageIndex === currentPage) {
          return {
            ...page,
            shapes: [...page.shapes, ...newShapes],
          };
        }
        return page;
      });
      return newPages;
    });

    setHistory(function (prevHistory) {
      return [
        ...prevHistory,
        {
          type: "import",
          pageIndex: currentPage,
          addedShapes: newShapes,
        },
      ];
    });
    setFuture([]);

    // Use setTimeout to ensure the state has been updated before fitting shapes
    setTimeout(() => {
      fitImportedShapesToView(newShapes);
    }, 0);
  }
};

  const isPointInsideShape = function (point, shape) {
    let inside = false;
    for (
      let i = 0, j = shape.points.length - 1;
      i < shape.points.length;
      j = i++
    ) {
      const xi = shape.points[i].x,
        yi = shape.points[i].y;
      const xj = shape.points[j].x,
        yj = shape.points[j].y;

      const intersect =
        yi > point.y !== yj > point.y &&
        point.x < ((xj - xi) * (point.y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  };

  const handleMouseDown = function (e) {
    if (e.target.tagName.toLowerCase() === "input") {
      return;
    }

    e.preventDefault();
    const point = screenToSVGCoords(e.clientX, e.clientY);

    if (e.button === 1) {
      // Middle mouse button
      setIsDragging(true);
      setLastPosition({ x: e.clientX, y: e.clientY });
    } else if (e.button === 0) {
      // Left mouse button
      // Close the context menu if it's open
      if (contextMenu) {
        closeContextMenu();
      }

      if (e.shiftKey) {
        if (!selecting) {
          setSelecting(true);
          setSelectionBox({
            startX: point.x,
            startY: point.y,
            endX: point.x,
            endY: point.y,
          });
        }
      } else {
        const clickedShapeIndex = pages[currentPage].shapes.findIndex(function (shape) {
          return isPointInsideShape(point, shape);
        });

        if (clickedShapeIndex === -1) {
          // Clicked outside any shape
          if (selectedShapeIndex !== null || selectedShapes.size > 0) {
            setSelectedShapes(new Set());
            setSelectedShapeIndex(null);
          } else {
            addPoint(point);
          }
          setSelecting(false);
          setSelectionBox(null);
          setMovingShape(null);
          setLatestShapes(pages[currentPage].shapes);
          setEditingShape(null);
        } else {
          const newSelectedShapes = new Set([clickedShapeIndex]);
          setSelectedShapes(newSelectedShapes);
          setSelectedShapeIndex(clickedShapeIndex);
        }
      }
    } else if (e.button === 2) {
      // Right mouse button
      e.preventDefault();
      
      // If a shape is already selected, show context menu at cursor position
      if (selectedShapeIndex !== null) {
        setContextMenu({
          x: e.clientX,
          y: e.clientY,
          shapeIndex: selectedShapeIndex,
        });
        return;
      }

      // Otherwise, check if we clicked on a shape
      let clickedShapeIndex = -1;
      for (let i = pages[currentPage].shapes.length - 1; i >= 0; i--) {
        if (isPointInsideShape(point, pages[currentPage].shapes[i])) {
          clickedShapeIndex = i;
          break;
        }
      }

      if (clickedShapeIndex !== -1) {
        setSelectedShapeIndex(clickedShapeIndex);
        setContextMenu({
          x: e.clientX,
          y: e.clientY,
          shapeIndex: clickedShapeIndex,
        });
      } else {
        setSelectedShapeIndex(null);
        closeContextMenu();
        setEditingShape(null);
      }
    }
  };
  

  const startEditingShape = function (shapeIndex) {
    const shape = pages[currentPage].shapes[shapeIndex];
    setEditingShape({
      index: shapeIndex,
      points: shape.points.map(function (point, index) {
        const nextPoint = shape.points[(index + 1) % shape.points.length];
        const length = Math.sqrt(
          Math.pow(nextPoint.x - point.x, 2) +
            Math.pow(nextPoint.y - point.y, 2)
        );
        return {
          ...point,
          length: parseFloat(
            calculateMeasurement(length, shape.gridMeasurement)
          ),
        };
      }),
    });
  };

  const startRotatingShape = function (shapeIndex, point, angle) {
    const shapesToRotate =
      selectedShapes.size > 0
        ? Array.from(selectedShapes).map(function (index) {
            return shapes[index];
          })
        : [shapes[shapeIndex]];

    const allPoints = shapesToRotate.flatMap(function (shape) {
      return shape.points;
    });
    const centerX =
      allPoints.reduce(function (sum, point) {
        return sum + point.x;
      }, 0) / allPoints.length;
    const centerY =
      allPoints.reduce(function (sum, point) {
        return sum + point.y;
      }, 0) / allPoints.length;

    const initialAngle = Math.atan2(point.y - centerY, point.x - centerX);

    setRotatingShape({
      indices:
        selectedShapes.size > 0 ? Array.from(selectedShapes) : [shapeIndex],
      initialAngle: initialAngle,
      rotationPoint: { x: centerX, y: centerY },
      initialShapes: shapesToRotate.map(function (shape) {
        return shape.points;
      }),
      currentAngle: initialAngle,
      startPoint: point,
      lastAngle: angle,
    });
  };

  const handleMouseMove = function (e) {
    if (isDragging) {
      const dx = e.clientX - lastPosition.x;
      const dy = e.clientY - lastPosition.y;
      setViewBox(function (vb) {
        const newX = vb.x - dx / vb.zoom;
        const newY = vb.y - dy / vb.zoom;
        return sanitizeViewBox({
          x: newX,
          y: newY,
          width: vb.width,
          height: vb.height,
          zoom: vb.zoom,
        });
      });
      setLastPosition({ x: e.clientX, y: e.clientY });
    } else if (movingShape || movingMultipleShapes) {
      const currentPoint = screenToSVGCoords(e.clientX, e.clientY);
      let dx, dy;
  
      if (movingMultipleShapes) {
        dx = currentPoint.x - multiShapeOffset.x;
        dy = currentPoint.y - multiShapeOffset.y;
      } else {
        dx = currentPoint.x - movingShape.offset.x - movingShape.initialShape[0].x;
        dy = currentPoint.y - movingShape.offset.y - movingShape.initialShape[0].y;
      }
  
      const snappedDx = Math.round(dx / baseGridSize) * baseGridSize;
      const snappedDy = Math.round(dy / baseGridSize) * baseGridSize;
  
      setPages(function (prevPages) {
        const newPages = prevPages.map(function (page, pageIndex) {
          if (pageIndex === currentPage) {
            return {
              ...page,
              shapes: page.shapes.map(function (shape, index) {
                if (
                  (movingMultipleShapes && selectedShapes.has(index)) ||
                  (!movingMultipleShapes &&
                    movingShape &&
                    index === movingShape.index)
                ) {
                  return {
                    ...shape,
                    points: shape.points.map(function (point) {
                      return {
                        x: point.x + (snapToGrid ? snappedDx : dx),
                        y: point.y + (snapToGrid ? snappedDy : dy),
                        gridMeasurement: point.gridMeasurement,
                      };
                    }),
                  };
                }
                return shape;
              }),
            };
          }
          return page;
        });
        return newPages;
      });
  
      setLabelPositions(function (prev) {
        const newLabelPositions = { ...prev };
        Object.keys(newLabelPositions).forEach(function (shapeIndex) {
          if (
            (movingMultipleShapes && selectedShapes.has(Number(shapeIndex))) ||
            (!movingMultipleShapes &&
              movingShape &&
              Number(shapeIndex) === movingShape.index)
          ) {
            newLabelPositions[shapeIndex] = newLabelPositions[shapeIndex].map(
              function (label) {
                return {
                  ...label,
                  x: label.x + (snapToGrid ? snappedDx : dx),
                  y: label.y + (snapToGrid ? snappedDy : dy),
                };
              }
            );
          }
        });
        return newLabelPositions;
      });
  
      if (movingMultipleShapes) {
        setMultiShapeOffset({ x: currentPoint.x, y: currentPoint.y });
      } else if (movingShape) {
        setMovingShape(function (prev) {
          return {
            ...prev,
            offset: {
              x: currentPoint.x - prev.initialShape[0].x,
              y: currentPoint.y - prev.initialShape[0].y,
            },
          };
        });
      }
    } else if (rotatingShape) {
      const currentPoint = screenToSVGCoords(e.clientX, e.clientY);
      const newAngle = Math.atan2(
        currentPoint.y - rotatingShape.rotationPoint.y,
        currentPoint.x - rotatingShape.rotationPoint.x
      );
  
      let angleDiff = newAngle - rotatingShape.initialAngle;
  
      while (angleDiff > Math.PI) angleDiff -= 2 * Math.PI;
      while (angleDiff < -Math.PI) angleDiff += 2 * Math.PI;
  
      const dampingFactor = 0.5;
      angleDiff *= dampingFactor;
  
      setPages(function (prevPages) {
        const newPages = prevPages.map(function (page, pageIndex) {
          if (pageIndex === currentPage) {
            return {
              ...page,
              shapes: page.shapes.map(function (shape, index) {
                if (rotatingShape.indices.includes(index)) {
                  const initialShape =
                    rotatingShape.initialShapes[
                      rotatingShape.indices.indexOf(index)
                    ];
                  return {
                    ...shape,
                    points: initialShape.map(function (point) {
                      const dx = point.x - rotatingShape.rotationPoint.x;
                      const dy = point.y - rotatingShape.rotationPoint.y;
                      const rotatedX =
                        dx * Math.cos(angleDiff) - dy * Math.sin(angleDiff);
                      const rotatedY =
                        dx * Math.sin(angleDiff) + dy * Math.cos(angleDiff);
                      return {
                        x: rotatedX + rotatingShape.rotationPoint.x,
                        y: rotatedY + rotatingShape.rotationPoint.y,
                        gridMeasurement: point.gridMeasurement,
                      };
                    }),
                  };
                }
                return shape;
              }),
            };
          }
          return page;
        });
        return newPages;
      });
  
      setRotatingShape(function (prev) {
        return {
          ...prev,
          currentAngle: prev.initialAngle + angleDiff,
          lastAngle: prev.lastAngle + angleDiff,
        };
      });
    } else if (movingLabel) {
      moveLabel(e);
    } else if (isMovingToolbar) {
      handleToolbarMove(e);
    } else if (selecting && selectionBox) {
      const point = screenToSVGCoords(e.clientX, e.clientY);
      setSelectionBox(function (prev) {
        return {
          startX: prev.startX,
          startY: prev.startY,
          endX: point.x,
          endY: point.y,
        };
      });
    }
  
    const svgPoint = screenToSVGCoords(e.clientX, e.clientY);
    setCurrentMousePos(snapToGridPoint(svgPoint));
  
    if (svgRef.current) {
      svgRef.current.style.cursor = "crosshair";
    }
  };

const handleMouseUp = function () {
  if (rotatingShape) {
    setRotatingShape(prev => ({
      ...prev,
      lastAngle: Math.atan2(
        prev.currentAngle - prev.initialAngle,
        prev.rotationPoint.y - prev.rotationPoint.x
      ) * (180 / Math.PI)
    }));
  }
  setIsDragging(false);
  setIsMovingToolbar(false);

  if (movingShape || movingMultipleShapes) {
    setHistory(function (prev) {
      return [
        ...prev,
        {
          type: "move",
          pageIndex: currentPage,
          movedShapes: pages[currentPage].shapes.filter(function (
            shape,
            index
          ) {
            return (
              (movingMultipleShapes && selectedShapes.has(index)) ||
              (!movingMultipleShapes &&
                movingShape &&
                index === movingShape.index)
            );
          }),
        },
      ];
    });
    setFuture([]);
  }

  setMovingShape(null);
  setMovingMultipleShapes(false);

  if (rotatingShape) {
    setHistory(function (prev) {
      return [
        ...prev,
        {
          type: "rotate",
          pageIndex: currentPage,
          rotatedShapes: pages[currentPage].shapes.filter(function (
            shape,
            index
          ) {
            return rotatingShape.indices.includes(index);
          }),
          angle: rotatingShape.currentAngle - rotatingShape.initialAngle,
        },
      ];
    });
    setFuture([]);
    setRotatingShape(null);
  }

  if (movingLabel) {
    setHistory(function (prev) {
      return [
        ...prev,
        {
          type: "moveLabel",
          pageIndex: currentPage,
          labelPositions: { ...labelPositions },
        },
      ];
    });
    setFuture([]);
    setMovingLabel(null);
  }

  if (selecting && selectionBox) {
    const selected = new Set();
    pages[currentPage].shapes.forEach(function (shape, index) {
      const shapeBounds = getShapeBounds(shape);
      if (isOverlapping(shapeBounds, selectionBox)) {
        selected.add(index);
      }
    });
    setSelectedShapes(selected);
    setSelectionBox(null);
    setSelecting(false);
  }
};

  const sanitizeViewBox = function (viewBox) {
    return {
      x: isNaN(viewBox.x) ? 0 : viewBox.x,
      y: isNaN(viewBox.y) ? 0 : viewBox.y,
      width: isNaN(viewBox.width) || viewBox.width <= 0 ? 800 : viewBox.width,
      height:
        isNaN(viewBox.height) || viewBox.height <= 0 ? 600 : viewBox.height,
      zoom: isNaN(viewBox.zoom) || viewBox.zoom <= 0 ? 1 : viewBox.zoom,
    };
  };

  const screenToSVGCoords = function (screenX, screenY) {
    const svgElement = svgRef.current;
    if (!svgElement) return { x: 0, y: 0 };
    const pt = svgElement.createSVGPoint();
    pt.x = screenX;
    pt.y = screenY;
    const svgPoint = pt.matrixTransform(svgElement.getScreenCTM().inverse());
    return {
      x: Math.round(svgPoint.x * 100) / 100,
      y: Math.round(svgPoint.y * 100) / 100,
    };
  };

  const renderGrid = function () {
    if (!showGrid) {
      return null;
    }
    const gridLines = [];
    const viewBoxWidth = viewBox.width;
    const viewBoxHeight = viewBox.height;

    // Calculate the number of grid lines needed to cover the entire viewBox
    const numLinesX = Math.ceil(viewBoxWidth / gridDisplaySize) + 1;
    const numLinesY = Math.ceil(viewBoxHeight / gridDisplaySize) + 1;

    // Calculate the start positions to ensure the grid covers the entire viewBox
    const startX = Math.floor(viewBox.x / gridDisplaySize) * gridDisplaySize;
    const startY = Math.floor(viewBox.y / gridDisplaySize) * gridDisplaySize;

    // Render vertical lines
    for (let i = 0; i <= numLinesX; i++) {
      const x = startX + i * gridDisplaySize;
      if (!isNaN(x) && !isNaN(viewBox.y) && !isNaN(viewBoxHeight)) {
        gridLines.push(
          <line
            key={`vertical-${i}`}
            x1={x}
            y1={viewBox.y}
            x2={x}
            y2={viewBox.y + viewBoxHeight}
            stroke="#ccc"
            strokeWidth={0.5 / viewBox.zoom}
          />
        );
      }
    }

    // Render horizontal lines
    for (let i = 0; i <= numLinesY; i++) {
      const y = startY + i * gridDisplaySize;
      if (!isNaN(viewBox.x) && !isNaN(y) && !isNaN(viewBoxWidth)) {
        gridLines.push(
          <line
            key={`horizontal-${i}`}
            x1={viewBox.x}
            y1={y}
            x2={viewBox.x + viewBoxWidth}
            y2={y}
            stroke="#ccc"
            strokeWidth={0.5 / viewBox.zoom}
          />
        );
      }
    }

    return gridLines;
  };

  const handleZoom = function (e) {
    e.preventDefault();
    const delta = e.deltaY < 0 ? 1.1 : 0.9;
    const newZoom = Math.max(minZoom, Math.min(maxZoom, zoom * delta));

    if (newZoom !== zoom) {
      const svgElement = svgRef.current;
      const svgRect = svgElement.getBoundingClientRect();

      const mouseX = e.clientX - svgRect.left;
      const mouseY = e.clientY - svgRect.top;

      const svgPoint = svgElement.createSVGPoint();
      svgPoint.x = mouseX;
      svgPoint.y = mouseY;
      const svgCoords = svgPoint.matrixTransform(
        svgElement.getScreenCTM().inverse()
      );

      const zoomFactor = newZoom / zoom;
      const newWidth = viewBox.width / zoomFactor;
      const newHeight = viewBox.height / zoomFactor;

      const newX = svgCoords.x - (mouseX / svgRect.width) * newWidth;
      const newY = svgCoords.y - (mouseY / svgRect.height) * newHeight;

      const newViewBox = {
        x: newX,
        y: newY,
        width: newWidth,
        height: newHeight,
      };

      updateViewBox(newViewBox, newZoom);
    }
  };

  const calculateMeasurement = function (length, shapeGridMeasurement) {
    if (isNaN(length) || shapeGridMeasurement === undefined) {
      console.warn("Invalid measurement:", { length, shapeGridMeasurement });
      return "N/A";
    }
    let measurement = (length / baseGridSize) * shapeGridMeasurement;
    if (isNaN(measurement)) {
      console.warn("Invalid calculated measurement:", {
        length,
        shapeGridMeasurement,
        baseGridSize,
      });
      return "N/A";
    }
    return measurement.toFixed(2);
  };

  const calculateFontSize = function (baseSize) {
    return baseSize / viewBox.zoom;
  };

  const renderPoints = function () {
    return points.map(function (point, index) {
      return <circle key={index} cx={point.x} cy={point.y} r={3} fill="blue" />;
    });
  };

  const renderLines = function () {
    const lines = [];
    points.forEach(function (point, index) {
      if (index === 0) return;
      const prevPoint = points[index - 1];
      const length = Math.sqrt(
        Math.pow(point.x - prevPoint.x, 2) + Math.pow(point.y - prevPoint.y, 2)
      );
      const midX = (prevPoint.x + point.x) / 2;
      const midY = (prevPoint.y + point.y) / 2;
      lines.push(
        <g key={index}>
          <line
            x1={prevPoint.x}
            y1={prevPoint.y}
            x2={point.x}
            y2={point.y}
            stroke="blue"
            strokeWidth={1}
          />
          {showMeasurements && (
            <text
              x={midX}
              y={midY}
              fontSize={12}
              fill="black"
              textAnchor="middle"
              dominantBaseline="middle"
            >
              {calculateMeasurement(length, prevPoint.gridMeasurement)}{" "}
              {gridUnit !== "inches" ? unitAbbreviations[gridUnit] : ""}
            </text>
          )}
        </g>
      );
    });
    return lines;
  };

  const calculateDrawnShapeArea = function (points, gridMeasurement) {
    if (!Array.isArray(points) || points.length < 3) {
      console.error("Invalid shape: not enough points to calculate area");
      return 0;
    }

    let area = 0;
    const n = points.length;

    for (let i = 0; i < n; i++) {
      const j = (i + 1) % n;
      area += points[i].x * points[j].y;
      area -= points[j].x * points[i].y;
    }

    area = Math.abs(area) / 2;
    return (
      (area / (baseGridSize * baseGridSize)) * Math.pow(gridMeasurement, 2)
    );
  };

  const convertDrawnShapeArea = function (area) {
    switch (gridUnit) {
      case "feet":
        return area;
      case "inches":
        return area / 144;
      case "centimeters":
        return area / 929.0304;
      case "meters":
        return area / 10.7639;
      default:
        return area;
    }
  };

  const formatDrawnShapeArea = function (shape) {
    const area = calculateDrawnShapeArea(shape.points, shape.gridMeasurement);
    const convertedArea = convertDrawnShapeArea(area);
    return `${convertedArea.toFixed(2)} ${unitAbbreviations[gridUnit]}²`;
  };

  const calculateImportedShapeArea = function (
    points,
    realWorldLengths,
    scaleFactor
  ) {
    if (!Array.isArray(points) || points.length < 3) {
      console.error("Invalid shape: not enough points to calculate area");
      return 0;
    }

    let area = 0;
    const n = points.length;

    for (let i = 0; i < n; i++) {
      const j = (i + 1) % n;
      area += points[i].x * points[j].y;
      area -= points[j].x * points[i].y;
    }

    area = Math.abs(area) / 2;

    if (realWorldLengths && realWorldLengths.length === n && scaleFactor) {
      area *= scaleFactor * scaleFactor;
    }

    return area * 10.7639; // Convert from square meters to square feet
  };

  const convertImportedShapeArea = function (area) {
    switch (gridUnit) {
      case "feet":
        return area;
      case "inches":
        return area / 144;
      case "centimeters":
        return area / 929.0304;
      case "meters":
        return area / 10.7639;
      default:
        return area;
    }
  };

  const formatImportedShapeArea = function (shape) {
    const area = calculateImportedShapeArea(
      shape.points,
      shape.realWorldLengths,
      shape.scaleFactor
    );
    const convertedArea = convertImportedShapeArea(area);
    return `${convertedArea.toFixed(2)} ${unitAbbreviations[gridUnit]}²`;
  };

  const calculateShapeArea = function (shape) {
    return shape.origin === "drawn"
      ? calculateDrawnShapeArea(shape.points, shape.gridMeasurement)
      : calculateImportedShapeArea(
          shape.points,
          shape.realWorldLengths,
          shape.scaleFactor
        );
  };

  const formatArea = function (shape) {
    return shape.origin === "drawn"
      ? formatDrawnShapeArea(shape)
      : formatImportedShapeArea(shape);
  };

  const renderShapes = function () {
    return pages[currentPage].shapes.map(function (shape, shapeIndex) {
      const isSelected = shapeIndex === selectedShapeIndex || selectedShapes.has(shapeIndex);
      const isEditing = editingShape && editingShape.index === shapeIndex;
      const isDrawnShape = !shape.origin || shape.origin !== "imported";

      return (
        <g key={shape.id || shapeIndex}>
          <polygon
            points={shape.points.map(function (p) {
              return `${p.x.toFixed(2)},${p.y.toFixed(2)}`;
            }).join(" ")}
            fill="none"
            stroke={isSelected ? "red" : "black"}
            strokeWidth={1 / viewBox.zoom}
          />
          {shape.points.map(function (point, pointIndex) {
            const nextPoint = shape.points[(pointIndex + 1) % shape.points.length];
            const midX = (point.x + nextPoint.x) / 2;
            const midY = (point.y + nextPoint.y) / 2;
            const length = Math.sqrt(
              Math.pow(nextPoint.x - point.x, 2) +
              Math.pow(nextPoint.y - point.y, 2)
            );
            const measurement = calculateMeasurement(length, shape.gridMeasurement);

            if (isDrawnShape || pointIndex < shape.points.length - 1) {
              if (isEditing) {
                const angle = Math.atan2(nextPoint.y - point.y, nextPoint.x - point.x) * (180 / Math.PI);
                return (
                  <g key={`${shape.id || shapeIndex}-${pointIndex}`} transform={`translate(${midX},${midY}) rotate(${angle})`}>
                    <foreignObject
                      x={-40 / viewBox.zoom}
                      y={-12 / viewBox.zoom}
                      width={80 / viewBox.zoom}
                      height={24 / viewBox.zoom}
                      style={{
                        overflow: "visible"
                      }}
                    >
                      <div
                        style={{
                          background: "white",
                          border: `${1 / viewBox.zoom}px solid black`,
                          borderRadius: `${4 / viewBox.zoom}px`,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          transform: "rotate(0deg)",
                          transformOrigin: "center"
                        }}
                      >
                        <input
                          type="number"
                          step="0.01"
                          value={editingShape.points[pointIndex].length}
                          onChange={function (e) {
                            handleMeasurementChange(e, shapeIndex, pointIndex);
                          }}
                          onMouseDown={function (e) {
                            e.stopPropagation();
                          }}
                          style={{
                            width: "100%",
                            height: "100%",
                            fontSize: `${14 / viewBox.zoom}px`,
                            textAlign: "center",
                            border: "none",
                            padding: "0",
                            margin: "0",
                            background: "transparent",
                            outline: "none"
                          }}
                        />
                      </div>
                    </foreignObject>
                  </g>
                );
              } else if (showMeasurements) {
                return (
                  <text
                    key={`${shape.id || shapeIndex}-${pointIndex}`}
                    x={midX}
                    y={midY}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fill="black"
                    fontSize={calculateFontSize(12)}
                  >
                    {parseFloat(measurement).toFixed(2)} {unitAbbreviations[gridUnit]}
                  </text>
                );
              }
            }
            return null;
          })}
          <text
            x={(Math.min(...shape.points.map(function (p) {
              return p.x;
            })) + 
                Math.max(...shape.points.map(function (p) {
                  return p.x;
                }))) / 2}
            y={(Math.min(...shape.points.map(function (p) {
              return p.y;
            })) + 
                Math.max(...shape.points.map(function (p) {
                  return p.y;
                }))) / 2}
            textAnchor="middle"
            dominantBaseline="middle"
            fill="black"
            fontSize={calculateFontSize(14)}
          >
            {isDrawnShape ? formatDrawnShapeArea(shape) : formatImportedShapeArea(shape)}
          </text>
          {renderLabels(shapeIndex)}
          {isSelected && renderRotationWheel(shape, shapeIndex)}
        </g>
      );
    });
  };

  const renderLabels = function (shapeIndex) {
    if (!labelPositions[shapeIndex]) {
      return null;
    }
    return labelPositions[shapeIndex].map(function (label, labelIndex) {
      return (
        <g key={labelIndex}>
          <text
            x={label.x}
            y={label.y}
            textAnchor="middle"
            dominantBaseline="middle"
            fill="blue"
            fontSize={calculateFontSize(12)}
            onMouseDown={function (e) {
              startMovingLabel(e, shapeIndex, labelIndex);
            }}
            style={{ cursor: "move" }}
          >
            {label.text}
          </text>
        </g>
      );
    });
  };

  const renderDynamicLine = function () {
    if (points.length === 0) return null;
    const lastPoint = points[points.length - 1];
    const length = Math.sqrt(
      Math.pow(currentMousePos.x - lastPoint.x, 2) +
        Math.pow(currentMousePos.y - lastPoint.y, 2)
    );
    const midX = (lastPoint.x + currentMousePos.x) / 2;
    const midY = (lastPoint.y + currentMousePos.y) / 2;

    return (
      <g>
        <line
          x1={lastPoint.x}
          y1={lastPoint.y}
          x2={currentMousePos.x}
          y2={currentMousePos.y}
          stroke="red"
          strokeWidth={1}
          strokeDasharray="5,5"
        />
        {showMeasurements && (
          <text
            x={midX}
            y={midY}
            fontSize={12}
            fill="red"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {calculateMeasurement(length, gridMeasurement)}{" "}
            {gridUnit !== "inches" ? unitAbbreviations[gridUnit] : ""}
          </text>
        )}
      </g>
    );
  };

  const renderContextMenu = function () {
    if (!contextMenu) return null;

    return (
      <div
        style={{
          position: "absolute",
          top: `${contextMenu.y}px`,
          left: `${contextMenu.x}px`,
          backgroundColor: "white",
          border: "1px solid black",
          padding: "5px",
          zIndex: 1000,
        }}
      >
        <button onClick={deleteSelectedShape}>Delete</button>
        <button
          onClick={function () {
            addCustomLabel(contextMenu.shapeIndex);
            closeContextMenu();
          }}
        >
          Add Label
        </button>
        <button
          onClick={function () {
            startEditingShape(contextMenu.shapeIndex);
            closeContextMenu();
          }}
        >
          Edit
        </button>
      </div>
    );
  };

  const undo = function () {
    if (history.length === 0) return;

    const lastAction = history[history.length - 1];
    setFuture(function (prev) {
      return [lastAction, ...prev];
    });

    if (lastAction.pages) {
      setPages(lastAction.pages);
      setPoints(lastAction.pages[currentPage].points);
      setShapes(lastAction.pages[currentPage].shapes);
    }

    if (lastAction.type === "moveLabel" || lastAction.type === "addLabel") {
      setLabelPositions(lastAction.labelPositions);
    }

    if (lastAction.type === "editMeasurement") {
      setPages(function (prevPages) {
        const newPages = [...prevPages];
        const shape =
          newPages[lastAction.pageIndex].shapes[lastAction.shapeIndex];
        shape.points[(lastAction.pointIndex + 1) % shape.points.length] =
          lastAction.originalNextPoint;
        shape.points[(lastAction.pointIndex + 2) % shape.points.length] =
          lastAction.originalOppositePoint;
        return newPages;
      });

      setEditingShape(function (prev) {
        if (prev && prev.index === lastAction.shapeIndex) {
          const newPoints = [...prev.points];
          const originalLength = Math.sqrt(
            Math.pow(
              lastAction.originalNextPoint.x -
                prev.points[lastAction.pointIndex].x,
              2
            ) +
              Math.pow(
                lastAction.originalNextPoint.y -
                  prev.points[lastAction.pointIndex].y,
                2
              )
          );
          newPoints[lastAction.pointIndex] = {
            ...newPoints[lastAction.pointIndex],
            length: calculateMeasurement(
              originalLength,
              lastAction.originalNextPoint.gridMeasurement
            ),
          };
          return { ...prev, points: newPoints };
        }
        return prev;
      });
    }

    setHistory(function (prev) {
      return prev.slice(0, -1);
    });
  };

  const redo = function () {
    if (future.length === 0) return;

    const nextAction = future[0];
    setHistory(function (prev) {
      return [...prev, nextAction];
    });

    if (nextAction.pages) {
      setPages(nextAction.pages);
      setPoints(nextAction.pages[currentPage].points);
      setShapes(nextAction.pages[currentPage].shapes);
    }

    if (nextAction.type === "moveLabel" || nextAction.type === "addLabel") {
      setLabelPositions(nextAction.labelPositions);
    }

    if (nextAction.type === "editMeasurement") {
      setPages(function (prevPages) {
        const newPages = [...prevPages];
        const shape =
          newPages[nextAction.pageIndex].shapes[nextAction.shapeIndex];
        const currentPoint = shape.points[nextAction.pointIndex];
        const newLength = nextAction.newLength;
        const currentLength = Math.sqrt(
          Math.pow(nextAction.originalNextPoint.x - currentPoint.x, 2) +
            Math.pow(nextAction.originalNextPoint.y - currentPoint.y, 2)
        );
        const scaleFactor =
          newLength /
          calculateMeasurement(currentLength, shape.gridMeasurement);

        const dx = nextAction.originalNextPoint.x - currentPoint.x;
        const dy = nextAction.originalNextPoint.y - currentPoint.y;
        const angle = Math.atan2(dy, dx);

        const newDx = Math.cos(angle) * currentLength * scaleFactor;
        const newDy = Math.sin(angle) * currentLength * scaleFactor;

        const newNextPoint = {
          x: currentPoint.x + newDx,
          y: currentPoint.y + newDy,
          gridMeasurement: shape.gridMeasurement,
        };

        shape.points[(nextAction.pointIndex + 1) % shape.points.length] =
          newNextPoint;
        return newPages;
      });

      setEditingShape(function (prev) {
        if (prev && prev.index === nextAction.shapeIndex) {
          const newPoints = [...prev.points];
          newPoints[nextAction.pointIndex] = {
            ...newPoints[nextAction.pointIndex],
            length: nextAction.newLength,
          };
          return { ...prev, points: newPoints };
        }
        return prev;
      });
    }

    setFuture(function (prev) {
      return prev.slice(1);
    });
  };

  const reset = function () {
    const updatedPages = [...pages];
    updatedPages[currentPage] = {
      ...updatedPages[currentPage],
      shapes: [],
      points: [],
      viewBox: [{ x: 0, y: 0, width: 800, height: 600, zoom: 1 }],
    };
    setPages(updatedPages);
    setViewBox({ x: 0, y: 0, width: 800, height: 600, zoom: 1 });
    setPoints([]);
    setShapes([]);
    setHistory([]);
    setFuture([]);
    setLabelPositions({});
    return;
  };

const calculateMaxToolbarPosition = function () {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const toolbarElement = document.getElementById("cad-toolbar");

  if (!toolbarElement) {
    console.warn("Toolbar element not found");
    return { maxX: 0, maxY: 0 };
  }

  const toolbarRect = toolbarElement.getBoundingClientRect();

  return {
    maxX: windowWidth - toolbarRect.width,
    maxY: windowHeight - toolbarRect.height,
  };
};

  const startMovingToolbar = function (e) {
    if (
      e.target.tagName === "BUTTON" ||
      e.target.tagName === "SELECT" ||
      e.target.tagName === "INPUT"
    )
      return;
    e.preventDefault();
    const toolbarElement = document.getElementById("cad-toolbar");
    if (!toolbarElement) {
      console.error("Toolbar element not found");
      return;
    }
    const toolbarRect = toolbarElement.getBoundingClientRect();
    setIsMovingToolbar(true);
    setToolbarOffset({
      x: e.clientX - toolbarRect.left,
      y: e.clientY - toolbarRect.top,
    });
  };

  const handleToolbarMove = function (e) {
    if (isMovingToolbar && e.buttons === 1) {
      const { maxX, maxY } = calculateMaxToolbarPosition();
      setToolbarPosition({
        x: Math.max(0, Math.min(maxX, e.clientX - toolbarOffset.x)),
        y: Math.max(0, Math.min(maxY, e.clientY - toolbarOffset.y)),
      });
    }
  };

  const handleGridMeasurementChange = function (e) {
    const newMeasurement = parseFloat(e.target.value);
    setGridMeasurement(newMeasurement);
  };

  const handleMeasurementChange = function (e, shapeIndex, pointIndex) {
    const newLength = parseFloat(e.target.value);
    if (isNaN(newLength)) return;

    setEditingShape(function (prev) {
      const newPoints = [...prev.points];
      newPoints[pointIndex] = { ...newPoints[pointIndex], length: newLength };
      return { ...prev, points: newPoints };
    });

    setPages(function (prevPages) {
      const newPages = [...prevPages];
      const shape = newPages[currentPage].shapes[shapeIndex];
      const originalPoints = [...shape.points];
      const currentPoint = shape.points[pointIndex];
      const nextPoint = shape.points[(pointIndex + 1) % shape.points.length];

      const currentLength = Math.sqrt(
        Math.pow(nextPoint.x - currentPoint.x, 2) +
          Math.pow(nextPoint.y - currentPoint.y, 2)
      );
      const scaleFactor =
        newLength / calculateMeasurement(currentLength, shape.gridMeasurement);

      const dx = nextPoint.x - currentPoint.x;
      const dy = nextPoint.y - currentPoint.y;
      const angle = Math.atan2(dy, dx);

      const newDx = Math.cos(angle) * currentLength * scaleFactor;
      const newDy = Math.sin(angle) * currentLength * scaleFactor;

      const newNextPoint = {
        x: currentPoint.x + newDx,
        y: currentPoint.y + newDy,
        gridMeasurement: shape.gridMeasurement,
      };

      // Only update the next point, leaving other points unchanged
      shape.points[(pointIndex + 1) % shape.points.length] = newNextPoint;

      setHistory(function (prev) {
        return [
          ...prev,
          {
            type: "editMeasurement",
            pageIndex: currentPage,
            shapeIndex: shapeIndex,
            pointIndex: pointIndex,
            originalNextPoint:
              originalPoints[(pointIndex + 1) % originalPoints.length],
          },
        ];
      });
      setFuture([]);

      return newPages;
    });
  };

  const deleteSelectedShape = function () {
    if (selectedShapeIndex !== null) {
      setPages(function (prevPages) {
        const newPages = [...prevPages];
        const deletedShape = newPages[currentPage].shapes[selectedShapeIndex];
        newPages[currentPage] = {
          ...newPages[currentPage],
          shapes: newPages[currentPage].shapes.filter(function (_, index) {
            return index !== selectedShapeIndex;
          }),
        };
        setHistory(function (prev) {
          return [
            ...prev,
            {
              type: "delete",
              pages: newPages,
              deletedShape: deletedShape,
              deletedIndex: selectedShapeIndex,
              pageIndex: currentPage,
            },
          ];
        });
        setFuture([]);
        return newPages;
      });
      setSelectedShapeIndex(null);
      setSelectedShapes(new Set());
      closeContextMenu();
    }
  };

  const getDeleteButtonPosition = function () {
    if (selectedShape === null) return null;
    const shape = shapes[selectedShape];
    const centerX =
      shape.points.reduce(function (sum, point) {
        return sum + point.x;
      }, 0) / shape.points.length;
    const centerY =
      shape.points.reduce(function (sum, point) {
        return sum + point.y;
      }, 0) / shape.points.length;

    const svgRect = svgRef.current.getBoundingClientRect();
    const svgPoint = screenToSVGCoords(svgRect.left, svgRect.top);

    return {
      left: centerX - svgPoint.x,
      top: centerY - svgPoint.y,
    };
  };

  const closeContextMenu = function () {
    setContextMenu(null);
  };

  const downloadPDF = async function () {
    console.log("downloadPDF function called");
    if (!pages[currentPage] || pages[currentPage].shapes.length === 0) {
        console.error("No shapes to export");
        alert("Error: No shapes to export.");
        return;
    }
    custom();
    setShowDownloadModal(true);
};

function custom(customdownloadOption = "") {
  if (!svgRef.current || !pages[currentPage] || pages[currentPage].shapes.length === 0) {
    console.error("SVG ref is null or no shapes present");
    alert("Error: No shapes to export or SVG element not found.");
    return;
  }

  try {
    let pdfWidth = 210;
    let pdfHeight = 297;

    if (orientation === "landscape") {
      [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
    }

    const pdf = new jsPDF({
      orientation: orientation,
      unit: "mm",
      format: "a4",
    });

    const optionToUse = customdownloadOption !== "" ? customdownloadOption : downloadOption;

    // Ensure downloadTitle has shapeLabels
    const titleWithLabels = {
      ...downloadTitle,
      shapeLabels: downloadTitle.shapeLabels || pages[currentPage].shapes.map((_, index) => 
        localShapeLabels[index] || `Shape ${index + 1}`
      )
    };

    if (optionToUse === "separatePages") {
      for (let i = 0; i < pages[currentPage].shapes.length; i++) {
        if (i > 0) pdf.addPage();
        addShapeToPage(
          pdf,
          pages[currentPage].shapes[i],
          i + 1,
          pdfWidth,
          pdfHeight,
          titleWithLabels
        );
      }
    } else if (optionToUse === "singlePage") {
      addAllShapesToPage(pdf, pages[currentPage].shapes, pdfWidth, pdfHeight, titleWithLabels);
    }

    const pdfDataUri = pdf.output("datauristring");
    setPdfPreview(pdfDataUri);

    // Update the preview immediately
    if (showDownloadModal) {
      const previewIframe = document.querySelector('.pdf-preview');
      if (previewIframe) {
        previewIframe.src = pdfDataUri;
      }
    }
  } catch (error) {
    console.error("Error creating PDF:", error);
    alert("Error creating PDF. Check console for details.");
  }
}

const addShapeToPage = function (pdf, shape, pageNumber, pdfWidth, pdfHeight, downloadTitle) {
  console.log("Adding shape to page:", shape);

  if (!shape || !shape.points || shape.points.length === 0) {
    console.error("Shape points are undefined or empty");
    return;
  }

  const margin = 20;
  const pageWidth = pdfWidth - 2 * margin;
  const pageHeight = pdfHeight - 2 * margin;

  // Calculate the bounding box of the shape
  let minX, maxX, minY, maxY;
  try {
    minX = Math.min(...shape.points.map((p) => p.x));
    maxX = Math.max(...shape.points.map((p) => p.x));
    minY = Math.min(...shape.points.map((p) => p.y));
    maxY = Math.max(...shape.points.map((p) => p.y));
  } catch (error) {
    console.error("Error calculating bounding box:", error);
    return;
  }

  const shapeWidth = maxX - minX;
  const shapeHeight = maxY - minY;

  // Add title
  pdf.setFontSize(16);
  pdf.setTextColor(0);
  let currentY = margin + 5; // Reduced from 10 to 5
  currentY = addWrappedText(pdf, downloadTitle.main || "Shape", margin, currentY, pageWidth, 16);

  // Add subtitles
  currentY += 2; // Reduced from 5 to 2
  if (downloadTitle.subtitles && Array.isArray(downloadTitle.subtitles)) {
    downloadTitle.subtitles.forEach((subtitle) => {
      currentY = addWrappedText(pdf, subtitle, margin, currentY, pageWidth, 12);
    });
  }

  // Add shape label
  currentY += 5;
  const shapeLabel = downloadTitle.shapeLabels?.[pageNumber - 1] || `Shape ${pageNumber}`;
  currentY = addWrappedText(pdf, shapeLabel, margin, currentY, pageWidth, 12);

  // Add area
  currentY += 5;
  currentY = addWrappedText(pdf, `Area: ${formatArea(shape)}`, margin, currentY, pageWidth, 12);

  // Calculate the remaining space for the shape
  const availableHeight = pdfHeight - currentY - margin;
  
  // Calculate the scale and offset
  const scale = Math.min(
    pageWidth / shapeWidth,
    availableHeight / shapeHeight
  ) * 0.8;
  const offsetX = margin + (pageWidth - shapeWidth * scale) / 2;
  const offsetY = currentY + (availableHeight - shapeHeight * scale) / 2;

  // Draw the shape
  pdf.setDrawColor(0);
  pdf.setLineWidth(0.5);
  try {
    pdf.lines(
      shape.points.map((point, index, array) => {
        const nextPoint = array[(index + 1) % array.length];
        return [
          (nextPoint.x - point.x) * scale,
          (nextPoint.y - point.y) * scale,
        ];
      }),
      (shape.points[0].x - minX) * scale + offsetX,
      (shape.points[0].y - minY) * scale + offsetY,
      [1, 1],
      "S",
      true
    );
  } catch (error) {
    console.error("Error drawing shape:", error);
    return;
  }

  // Add measurements
  pdf.setFontSize(8);
  pdf.setTextColor(0, 0, 255);
  try {
    shape.points.forEach((point, index) => {
      const nextPoint = shape.points[(index + 1) % shape.points.length];
      const midX = ((point.x + nextPoint.x) / 2 - minX) * scale + offsetX;
      const midY = ((point.y + nextPoint.y) / 2 - minY) * scale + offsetY;
      const length = Math.sqrt(
        Math.pow(nextPoint.x - point.x, 2) + Math.pow(nextPoint.y - point.y, 2)
      );
      const measurement = `${calculateMeasurement(
        length,
        shape.gridMeasurement
      )} ${gridUnit !== "inches" ? unitAbbreviations[gridUnit] : ""}`;
      pdf.text(measurement, midX, midY, {
        align: "center",
        baseline: "middle",
      });
    });
  } catch (error) {
    console.error("Error adding measurements:", error);
    return;
  }

  // Add page number
  pdf.setFontSize(10);
  pdf.setTextColor(0);
  pdf.text(`Page ${pageNumber}`, pdfWidth / 2, pdfHeight - margin / 2, {
    align: "center",
  });

  console.log("Shape added to page");
};
  
const addAllShapesToPage = function (pdf, shapes, pdfWidth, pdfHeight, downloadTitle) {
  console.log("Adding all shapes to page:", shapes);

  if (!shapes || shapes.length === 0) {
    console.error("Shapes array is undefined or empty");
    return;
  }

  const margin = 20;
  const pageWidth = pdfWidth - 2 * margin;
  const pageHeight = pdfHeight - 2 * margin;

  // Calculate the bounding box of all shapes
  let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
  shapes.forEach(function(shape) {
    if (!shape.points || shape.points.length === 0) {
      console.error("Shape points are undefined or empty for shape:", shape);
      return;
    }
    shape.points.forEach(function(point) {
      minX = Math.min(minX, point.x);
      minY = Math.min(minY, point.y);
      maxX = Math.max(maxX, point.x);
      maxY = Math.max(maxY, point.y);
    });
  });
  const shapeWidth = maxX - minX;
  const shapeHeight = maxY - minY;

  // Add title
  let currentY = margin;
  currentY = addWrappedText(pdf, downloadTitle.main || "All Shapes", margin, currentY, pageWidth, 16);

  // Add subtitles
  currentY += 2; // Reduced from 5 to 2
  if (downloadTitle.subtitles && Array.isArray(downloadTitle.subtitles)) {
    downloadTitle.subtitles.forEach((subtitle) => {
      currentY = addWrappedText(pdf, subtitle, margin, currentY, pageWidth, 12);
    });
  }

  // Calculate space for shape details
  const detailStartY = currentY + 10;
  const detailsPerRow = 5;
  const initialRows = 3;
  const detailWidth = pageWidth / detailsPerRow;
  const detailHeight = 20; // Height for each detail block

  // Calculate the number of rows needed
  const rows = Math.max(initialRows, Math.ceil(shapes.length / detailsPerRow));

  // Add shape details in a grid layout
  pdf.setFontSize(8);
  shapes.forEach(function(shape, index) {
    const row = Math.floor(index / detailsPerRow);
    const col = index % detailsPerRow;
    const detailX = margin + col * detailWidth;
    const detailY = detailStartY + row * detailHeight;

    const shapeLabel = downloadTitle.shapeLabels && downloadTitle.shapeLabels[index] 
      ? downloadTitle.shapeLabels[index] 
      : `Shape ${index + 1}`;
    addWrappedText(pdf, `${index + 1}. ${shapeLabel}`, detailX, detailY, detailWidth - 2, 8);
    addWrappedText(pdf, `Area: ${formatArea(shape)}`, detailX, detailY + 10, detailWidth - 2, 8);
  });

  // Calculate the remaining space for shapes
  const shapesSectionStartY = detailStartY + rows * detailHeight + 10;
  const shapesSectionHeight = pageHeight - shapesSectionStartY;

  // Calculate the scale and offset for shapes
  const scale = Math.min(
    pageWidth / shapeWidth,
    shapesSectionHeight / shapeHeight
  ) * 0.8;
  const offsetX = margin + (pageWidth - shapeWidth * scale) / 2;
  const offsetY = shapesSectionStartY + (shapesSectionHeight - shapeHeight * scale) / 2;

  // Draw all shapes
  pdf.setDrawColor(0);
  pdf.setLineWidth(0.5);
  shapes.forEach(function(shape, shapeIndex) {
    if (!shape.points || shape.points.length === 0) {
      console.error("Shape points are undefined or empty for shape:", shape);
      return;
    }
    pdf.lines(
      shape.points.map(function(point, index, array) {
        const nextPoint = array[(index + 1) % array.length];
        return [
          (nextPoint.x - point.x) * scale,
          (nextPoint.y - point.y) * scale,
        ];
      }),
      (shape.points[0].x - minX) * scale + offsetX,
      (shape.points[0].y - minY) * scale + offsetY,
      [1, 1],
      "S",
      true
    );

    // Calculate shape center
    const centerX = shape.points.reduce(function(sum, point) { return sum + point.x; }, 0) / shape.points.length;
    const centerY = shape.points.reduce(function(sum, point) { return sum + point.y; }, 0) / shape.points.length;
    
    // Add shape number label
    const labelSize = 8 / scale; // Adjust this value to change the label size
    const labelX = (centerX - minX) * scale + offsetX;
    const labelY = (centerY - minY) * scale + offsetY;
    
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(labelSize * scale);
    pdf.setTextColor(0, 0, 0); // Black text
    pdf.text(
      (shapeIndex + 1).toString(),
      labelX,
      labelY,
      {
        align: "center",
        baseline: "middle"
      }
    );
  });

  // Add measurements
  pdf.setFontSize(8);
  pdf.setTextColor(0, 0, 255);
  shapes.forEach(function(shape) {
    if (!shape.points || shape.points.length === 0) {
      console.error("Shape points are undefined or empty for shape:", shape);
      return;
    }
    shape.points.forEach(function(point, index) {
      const nextPoint = shape.points[(index + 1) % shape.points.length];
      const midX = ((point.x + nextPoint.x) / 2 - minX) * scale + offsetX;
      const midY = ((point.y + nextPoint.y) / 2 - minY) * scale + offsetY;
      const length = Math.sqrt(
        Math.pow(nextPoint.x - point.x, 2) + Math.pow(nextPoint.y - point.y, 2)
      );
      const measurement = `${calculateMeasurement(
        length,
        shape.gridMeasurement
      )} ${gridUnit !== "inches" ? unitAbbreviations[gridUnit] : ""}`;
      pdf.text(measurement, midX, midY, {
        align: "center",
        baseline: "middle",
      });
    });
  });

  // Add page number
  pdf.setFontSize(10);
  pdf.text(`Page 1`, pdfWidth / 2, pdfHeight - margin / 2, {
    align: "center",
  });

  console.log("All shapes added to page with fixed grid shape details");
};

const addWrappedText = function(pdf, text, x, y, maxWidth, fontSize) {
  pdf.setFontSize(fontSize);
  let textLines = pdf.splitTextToSize(text, maxWidth);
  let currentY = y;
  
  while (currentY + textLines.length * fontSize > y + 20 && fontSize > 6) {
    fontSize -= 0.5;
    pdf.setFontSize(fontSize);
    textLines = pdf.splitTextToSize(text, maxWidth);
  }
  
  textLines.forEach(line => {
    pdf.text(line, x, currentY);
    currentY += fontSize * 1.2; // Add some line spacing
  });
  
  return currentY;
};

  const handleDownload = function () {
    console.log("Handling download");

    // Ensure we're using the most recent labels
    const currentLabels = {
      ...downloadTitle,
      shapeLabels: localShapeLabels
    };

    const pdf = new jsPDF({
      orientation: orientation,
      unit: "mm",
      format: "a4",
    });

    if (downloadOption === "separatePages") {
      pages[currentPage].shapes.forEach((shape, i) => {
        if (i > 0) pdf.addPage();
        addShapeToPage(
          pdf,
          shape,
          i + 1,
          pdf.internal.pageSize.width,
          pdf.internal.pageSize.height,
          currentLabels
        );
      });
    } else {
      addAllShapesToPage(
        pdf,
        pages[currentPage].shapes,
        pdf.internal.pageSize.width,
        pdf.internal.pageSize.height,
        currentLabels
      );
    }

    pdf.save(`${fileName}.pdf`);
    setShowDownloadModal(false);
    console.log("PDF saved");
  };


  const DownloadModal = function ({
    isOpen,
    onClose,
    pdfPreview,
    downloadOption,
    setDownloadOption,
    downloadTitle,
    setDownloadTitle,
    onDownload,
    custom,
    shapes
  }) {
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [localTitle, setLocalTitle] = useState(downloadTitle.main || '');
    const [localSubtitles, setLocalSubtitles] = useState(downloadTitle.subtitles || ['']);
    const [localShapeLabels, setLocalShapeLabels] = useState(
      pages[currentPage].shapes.map((_, index) => 
        downloadTitle.shapeLabels?.[index] || `Shape ${index + 1}`
      )
    );
  
    useEffect(() => {
      setLocalTitle(downloadTitle.main || '');
      setLocalSubtitles(downloadTitle.subtitles || ['']);
      const initialShapeLabels = downloadTitle.shapeLabels || 
        shapes.map((_, index) => `Shape ${index + 1}`);
      setLocalShapeLabels(initialShapeLabels);
      
      // Update downloadTitle with initial shape labels if not present
      if (!downloadTitle.shapeLabels) {
        setDownloadTitle(prevTitle => ({
          ...prevTitle,
          shapeLabels: initialShapeLabels
        }));
      }
    }, [downloadTitle, shapes]);
  
    // Add this useEffect to initialize localShapeLabels when the modal opens
    useEffect(() => {
      if (showDownloadModal) {
        setLocalShapeLabels(
          pages[currentPage].shapes.map((_, index) => 
            downloadTitle.shapeLabels?.[index] || `Shape ${index + 1}`
          )
        );
      }
    }, [showDownloadModal, pages, currentPage, downloadTitle.shapeLabels]);
  
    if (!isOpen) {
      return null;
    }
  
    const handleOptionChange = (e) => {
      const newOption = e.target.value;
      setDownloadOption(newOption);
      custom(newOption);
    };
  
    const handleTitleBlur = () => {
      setDownloadTitle(prevTitle => ({
        ...prevTitle,
        main: localTitle,
      }));
      custom(downloadOption);
    };
  
    const handleSubtitleChange = (index, value) => {
      const newSubtitles = [...localSubtitles];
      newSubtitles[index] = value;
      setLocalSubtitles(newSubtitles);
    };
  
    const handleSubtitleBlur = () => {
      setDownloadTitle(prevTitle => ({
        ...prevTitle,
        subtitles: localSubtitles,
      }));
      custom(downloadOption);
    };
  
    const handleShapeLabelChange = (index, newValue) => {
      // Update local state
      setLocalShapeLabels(prev => {
        const newLabels = [...prev];
        newLabels[index] = newValue;
        return newLabels;
      });

      // Update persistent labels
      setPersistentShapeLabels(prev => {
        const newLabels = [...prev];
        newLabels[index] = newValue;
        return newLabels;
      });

      // Update download title
      setDownloadTitle(prev => ({
        ...prev,
        shapeLabels: prev.shapeLabels.map((label, i) => 
          i === index ? newValue : label
        )
      }));

      // Trigger preview update
      custom();
    };
  
    const addSubtitle = () => {
      setLocalSubtitles(prevSubtitles => [...prevSubtitles, '']);
    };
  
    const removeSubtitle = (index) => {
      const newSubtitles = localSubtitles.filter((_, i) => i !== index);
      setLocalSubtitles(newSubtitles);
      setDownloadTitle(prevTitle => ({
        ...prevTitle,
        subtitles: newSubtitles,
      }));
      custom(downloadOption);
    };
  
    const handleModalClose = () => {
      // Save the current state of labels before closing
      setPersistentShapeLabels(localShapeLabels);
      setDownloadTitle(prevTitle => ({
        ...prevTitle,
        shapeLabels: localShapeLabels
      }));
      setDownloadShapeLabels(localShapeLabels);
      setShowDownloadModal(false);
    };
  
    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title">Preview</h2>
            <button onClick={onClose} className="close-button">
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="modal-body">
            <div className="left-column">
              <div className="title-section">
                <div className="main-title-container">
                  <label htmlFor="downloadTitle" className="title-label">Main Title:</label>
                  <textarea
                    id="downloadTitle"
                    value={localTitle}
                    onChange={(e) => setLocalTitle(e.target.value)}
                    onBlur={handleTitleBlur}
                    placeholder="Enter main title"
                    className="title-input"
                    rows="2"
                  />
                </div>
                <div className="subtitles-container">
                  <label className="title-label">Subtitles:</label>
                  {localSubtitles.map((subtitle, index) => (
                    <div key={index} className="subtitle-input-container">
                      <textarea
                        value={subtitle}
                        onChange={(e) => handleSubtitleChange(index, e.target.value)}
                        onBlur={handleSubtitleBlur}
                        placeholder={`Subtitle ${index + 1}`}
                        className="subtitle-input"
                        rows="2"
                      />
                      <button onClick={() => removeSubtitle(index)} className="remove-subtitle-btn">
                        <FontAwesomeIcon icon={faMinus} />
                      </button>
                    </div>
                  ))}
                  <button onClick={addSubtitle} className="add-subtitle-btn">
                    <FontAwesomeIcon icon={faPlus} /> Add Subtitle
                  </button>
                </div>
                <div className="shape-labels-container mb-4">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Shape Labels:
                  </label>
                  {pages[currentPage].shapes.map((_, index) => (
                    <ShapeLabelInput
                      key={index}
                      index={index}
                      value={localShapeLabels[index] || `Shape ${index + 1}`}
                      onChange={handleShapeLabelChange}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="right-column">
              <div className="preview-container">
                <iframe src={pdfPreview} className="pdf-preview" />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="options-dropdown">
              <button 
                className="options-toggle" 
                onClick={() => setIsOptionsOpen(!isOptionsOpen)}
              >
                Download Options <FontAwesomeIcon icon={faChevronDown} />
              </button>
              {isOptionsOpen && (
                <div className="options-menu">
                  <div className="radio-group">
                    <label className="radio-label">
                      <input
                        type="radio"
                        value="separatePages"
                        checked={downloadOption === "separatePages"}
                        onChange={handleOptionChange}
                      />
                      Each shape on separate page
                    </label>
                    <label className="radio-label">
                      <input
                        type="radio"
                        value="singlePage"
                        checked={downloadOption === "singlePage"}
                        onChange={handleOptionChange}
                      />
                      All shapes on one page
                    </label>
                  </div>
                </div>
              )}
            </div>
            <button onClick={onClose} className="decline-button">Decline</button>
            <button onClick={onDownload} className="accept-button">
              <FontAwesomeIcon icon={faDownload} className="download-icon" />
              Accept
            </button>
          </div>
        </div>
      </div>
    );
  };
  

  const moveLabel = function (e) {
    if (movingLabel) {
      const point = screenToSVGCoords(e.clientX, e.clientY);
      setLabelPositions(function (prev) {
        return {
          ...prev,
          [movingLabel.shapeIndex]: prev[movingLabel.shapeIndex].map(function (
            label,
            index
          ) {
            return index === movingLabel.labelIndex
              ? {
                  ...label,
                  x: point.x - movingLabel.offsetX,
                  y: point.y - movingLabel.offsetY,
                }
              : label;
          }),
        };
      });
    }
  };

  const startMovingLabel = function (e, shapeIndex, labelIndex) {
    e.stopPropagation();
    const point = screenToSVGCoords(e.clientX, e.clientY);
    setMovingLabel({
      shapeIndex: shapeIndex,
      labelIndex: labelIndex,
      offsetX: point.x - labelPositions[shapeIndex][labelIndex].x,
      offsetY: point.y - labelPositions[shapeIndex][labelIndex].y,
    });
  };

  const addCustomLabel = function (shapeIndex) {
    const labelText = prompt("Enter label text:");
    if (labelText) {
      const shape = shapes[shapeIndex];
      const centerX =
        shape.points.reduce(function (sum, point) {
          return sum + point.x;
        }, 0) / shape.points.length;
      const centerY =
        shape.points.reduce(function (sum, point) {
          return sum + point.y;
        }, 0) / shape.points.length;

      setLabelPositions(function (prev) {
        const newLabelPositions = { ...prev };
        if (!newLabelPositions[shapeIndex]) {
          newLabelPositions[shapeIndex] = [];
        }
        newLabelPositions[shapeIndex].push({
          x: centerX,
          y: centerY,
          text: labelText,
          isArea: false,
        });
        return newLabelPositions;
      });

      setHistory(function (prev) {
        return [
          ...prev,
          {
            type: "addLabel",
            labelPositions: {
              ...labelPositions,
              [shapeIndex]: [
                ...(labelPositions[shapeIndex] || []),
                { x: centerX, y: centerY, text: labelText, isArea: false },
              ],
            },
          },
        ];
      });
      setFuture([]);
    }
  };

  const getShapeBounds = function (shape) {
    const minX = Math.min(
      ...shape.points.map(function (p) {
        return p.x;
      })
    );
    const maxX = Math.max(
      ...shape.points.map(function (p) {
        return p.x;
      })
    );
    const minY = Math.min(
      ...shape.points.map(function (p) {
        return p.y;
      })
    );
    const maxY = Math.max(
      ...shape.points.map(function (p) {
        return p.y;
      })
    );
    return { minX: minX, maxX: maxX, minY: minY, maxY: maxY };
  };

  const isOverlapping = function (bounds1, bounds2) {
    return !(
      bounds1.maxX < bounds2.startX ||
      bounds1.minX > bounds2.endX ||
      bounds1.maxY < bounds2.startY ||
      bounds1.minY > bounds2.endY
    );
  };

  const renderSelectionBox = function () {
    if (!selectionBox) return null;
    const { startX, startY, endX, endY } = selectionBox;
    return (
      <rect
        x={Math.min(startX, endX)}
        y={Math.min(startY, endY)}
        width={Math.abs(endX - startX)}
        height={Math.abs(endY - startY)}
        fill="rgba(0, 0, 255, 0.1)"
        stroke="blue"
        strokeWidth={1}
      />
    );
  };

  useEffect(() => {
    if (currentGrid) {
      const { points, shapes, pages, currentPage, gridName, viewBox } = currentGrid;
      setPoints(points || []);
      setShapes(shapes || []);
      setPages(pages || [{ points: [], shapes: [] }]);
      setCurrentPage(currentPage || 0);
      setGridName(gridName || '');
      if (viewBox) {
        setViewBox(viewBox);
      }
      setCurrentGridId(currentGrid.id);
    }
  }, [currentGrid]);

  const loadGrid = async (gridId) => {
    try {
      let result;
      
      if (isApiMode) {
        // API mode implementation
        const result = await onGridLoad?.(gridId);
        if (result) {
          const { points, shapes, pages, currentPage, gridName } = result;
          setPoints(points || []);
          setShapes(shapes || []);
          setPages(pages || [{ points: [], shapes: [] }]);
          setCurrentPage(currentPage || 0);
          setGridName(gridName || '');
          setCurrentGridId(gridId);
        }
      } else {
        // Direct Supabase mode
        const { data, error } = await supabase
          .from('grids')
          .select('*')
          .eq('id', gridId)
          .single();

        if (error) throw error;

        if (data) {
          setSavedGridData(data);
          setCurrentGridId(gridId);
          setGridName(data.name);
        } else {
          throw new Error('Grid not found');
        }
      }
    } catch (error) {
      console.error('Error loading grid:', error);
      onError?.(error);
      alert('Failed to load grid. Please try again.');
    }
  };

  const setSavedGridData = (grid) => {
    setPages(grid.pages);
    setViewBox(grid.viewBox);
    setGridMeasurement(grid.gridMeasurement);
    setGridUnit(grid.gridUnit);
    setCurrentPage(0);
    setShapes(grid.pages[0].shapes);
    setPoints(grid.pages[0].points);
  };

  useEffect(() => {
    setPoints(pages[currentPage].points);
    setShapes(pages[currentPage].shapes);
  }, [currentPage, pages]);

  const saveGrid = async () => {
    if (!gridName) {
      alert("Please enter a name for your grid.");
      return;
    }
  
    if (!user && !isApiMode) {
      alert("You must be logged in to save a grid.");
      return;
    }
  
    try {
      let result;
      
      if (isApiMode) {
        // API mode implementation
        const gridData = {
          id: currentGridId,
          name: gridName,
          points,
          shapes,
          pages,
          currentPage,
          business_id: apiKey,
          viewBox,
          gridMeasurement,
          gridUnit
        };

        result = await onGridSave?.(gridData);
        if (result?.id) {
          setCurrentGridId(result.id);
        }
      } else {
        // Direct Supabase mode
        const gridData = {
          user_id: user.id,
          name: gridName,
          pages,
          viewBox,
          gridMeasurement,
          gridUnit
        };

        if (currentGridId) {
          // Update existing grid
          const { data, error } = await supabase
            .from('grids')
            .update(gridData)
            .eq('id', currentGridId)
            .select()
            .single();

          if (error) throw error;
          result = data;
          alert('Grid updated successfully!');
        } else {
          // Create new grid
          const { data, error } = await supabase
            .from('grids')
            .insert([gridData])
            .select();

          if (error) throw error;
          result = data[0];
          setCurrentGridId(result.id);
          alert('Grid saved successfully!');
        }
      }

      // Refresh the list of saved grids
      loadSavedGrids();
      
    } catch (error) {
      console.error('Error saving grid:', error);
      onError?.(error);
      alert('Failed to save grid. Please try again.');
    }
  };

  
  // Add this function within your CADApp component
  const loadSavedGrids = async () => {
    if (!user) {
      console.log("No user logged in");
      return;
    }
  
    try {
      const { data, error } = await supabase
        .from('grids')
        .select('*')
        .eq('user_id', user.id);
  
      if (error) {
        throw error;
      }
  
      setSavedGrids(data);
    } catch (error) {
      console.error('Error loading saved grids:', error);
      alert('Failed to load saved grids. Please try again.');
    }
  };

  const ToolbarOption = ({ label, type, checked, value, onChange, options }) => {
    return (
      <div className="toolbar-option mb-2">
        <label className="flex items-center text-gray-700">
          {type === 'checkbox' ? (
            <>
              <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
                className="mr-2 form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
              />
              <span className="text-sm">{label}</span>
            </>
          ) : (
            <>
              <span className="text-sm mr-2">{label}</span>
              <select
                value={value}
                onChange={onChange}
                className="form-select block w-full pl-3 pr-10 py-2 text-sm leading-5 rounded-md border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              >
                {options.map((option, index) => (
                  <option key={index} value={option.value || option}>
                    {option.label || option}
                  </option>
                ))}
              </select>
            </>
          )}
        </label>
      </div>
    );
  };

  // Add these useEffect hooks within your CADApp component
  useEffect(() => {
    if (user) {
      loadSavedGrids();
    }
  }, [user]);
  
  useEffect(() => {
    if (gridId) {
      loadGrid(gridId);
    }
  }, [gridId]);

  useEffect(function () {
    function preventWheel(e) {
      e.preventDefault();
    }
    document.body.addEventListener("wheel", preventWheel, { passive: false });
    return function () {
      document.body.removeEventListener("wheel", preventWheel);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const { maxX, maxY } = calculateMaxToolbarPosition();
      setToolbarPosition((prev) => ({
        x: Math.min(prev.x, maxX),
        y: Math.min(prev.y, maxY),
      }));
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderEditingShape = function () {
    if (!editingShape) return null;

    const shape = pages[currentPage].shapes[editingShape.index];
    const fontSize = 14 / viewBox.zoom;
    const boxWidth = 80;
    const boxHeight = 24;

    return (
      <g>
        {editingShape.points.map((point, index) => {
          const nextPoint = shape.points[(index + 1) % shape.points.length];
          const midX = (point.x + nextPoint.x) / 2;
          const midY = (point.y + nextPoint.y) / 2;

          // Calculate angle of the line segment for proper alignment
          const angle = Math.atan2(nextPoint.y - point.y, nextPoint.x - point.x);
          
          return (
            <foreignObject
              key={index}
              x={midX}
              y={midY}
              width={boxWidth / viewBox.zoom}
              height={boxHeight / viewBox.zoom}
              style={{
                overflow: 'visible',
                transform: `
                  translate(${-boxWidth / (2 * viewBox.zoom)}px, ${-boxHeight / (2 * viewBox.zoom)}px)
                  rotate(${angle * (180 / Math.PI)}deg)
                `,
                transformOrigin: '50% 50%'
              }}
            >
              <div
                style={{
                  background: 'white',
                  border: `${1 / viewBox.zoom}px solid black`,
                  borderRadius: `${4 / viewBox.zoom}px`,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <input
                  type="number"
                  value={point.length || ''}
                  onChange={(e) => {
                    handleMeasurementChange(e, editingShape.index, index);
                  }}
                  style={{
                    width: '100%',
                    height: '100%',
                    fontSize: `${fontSize}px`,
                    border: 'none',
                    outline: 'none',
                    textAlign: 'center',
                    padding: `0 ${4 / viewBox.zoom}px`,
                    background: 'transparent'
                  }}
                />
              </div>
            </foreignObject>
          );
        })}
      </g>
    );
  };

  const renderRotationWheel = function (shape, shapeIndex) {
    if (!selectedShapes.has(shapeIndex) && selectedShapeIndex !== shapeIndex) {
      return null;
    }

    const centerX = shape.points.reduce((sum, point) => sum + point.x, 0) / shape.points.length;
    const centerY = shape.points.reduce((sum, point) => sum + point.y, 0) / shape.points.length;

    const wheelSize = 40 / viewBox.zoom;
    const fontSize = 12 / viewBox.zoom;
    const padding = wheelSize * 0.5;

    // Calculate pointer angle based on current mouse position when rotating
    const pointerAngle = rotatingShape && rotatingShape.indices.includes(shapeIndex)
      ? Math.atan2(
          currentMousePos.y - centerY,
          currentMousePos.x - centerX
        ) * (180 / Math.PI) + 90 // Add 90 degrees to point in the right direction
      : rotatingShape?.lastAngle || 0;

    const pointerDistance = (wheelSize / 2) + (padding / 4);

    return (
      <g transform={`translate(${centerX}, ${centerY})`}>
        <foreignObject
          x={-(wheelSize + padding) / 2}
          y={-(wheelSize + padding) / 2}
          width={wheelSize + padding}
          height={wheelSize + padding}
          style={{ 
            pointerEvents: 'none',
            overflow: 'visible'
          }}
        >
          <div 
            className="wheel-container"
            style={{
              width: '100%',
              height: '100%',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div 
              className="wheel"
              style={{
                width: `${wheelSize}px`,
                height: `${wheelSize}px`,
                position: 'absolute',
                pointerEvents: 'auto'
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
                const point = screenToSVGCoords(e.clientX, e.clientY);
                const angle = Math.atan2(
                  point.y - centerY,
                  point.x - centerX
                ) * (180 / Math.PI) + 90;
                startRotatingShape(shapeIndex, point, angle);
              }}
            >
              <div className="compass-marks" />
              <div className="compass-arrow" />
              <div 
                className="compass-n"
                style={{
                  fontSize: `${fontSize}px`
                }}
              >
                N
              </div>
              <div
                className="pointer-arrow"
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: `translate(-50%, -${pointerDistance}px) rotate(${pointerAngle}deg) scale(${1 / viewBox.zoom})`,
                }}
              />
            </div>
          </div>
        </foreignObject>
      </g>
    );
  };

  const ShapeLabelInput = React.memo(({ index, value, onChange }) => {
    // Use local state to manage the input value while editing
    const [localValue, setLocalValue] = useState(value);
    const inputRef = useRef(null);

    // Sync local state with prop value when it changes externally
    useEffect(() => {
      setLocalValue(value);
    }, [value]);

    const handleInputChange = (e) => {
      const newValue = e.target.value;
      setLocalValue(newValue);
    };

    // Only propagate changes to parent when input loses focus or Enter is pressed
    const handleBlur = () => {
      onChange(index, localValue);
    };

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        inputRef.current?.blur();
      }
    };

    return (
      <div className="shape-label-input-container">
        <span className="shape-number">{index + 1}.</span>
        <input
          ref={inputRef}
          type="text"
          value={localValue}
          onChange={handleInputChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onClick={(e) => e.stopPropagation()}
          className="shape-label-input"
          placeholder={`Shape ${index + 1}`}
        />
      </div>
    );
  });

  // Add this function inside the CADApp component, before the return statement
  const handleShapeLabelChange = (index, newValue) => {
    // Update local state
    setLocalShapeLabels(prev => {
      const newLabels = [...prev];
      newLabels[index] = newValue;
      return newLabels;
    });

    // Update persistent labels
    setPersistentShapeLabels(prev => {
      const newLabels = [...prev];
      newLabels[index] = newValue;
      return newLabels;
    });

    // Update download title
    setDownloadTitle(prev => ({
      ...prev,
      shapeLabels: prev.shapeLabels.map((label, i) => 
        i === index ? newValue : label
      )
    }));
  };

  return (
    <div
        className="w-full h-screen bg-gray-100 relative select-none overflow-hidden"
        onContextMenu={function (e) {
            e.preventDefault();
        }}
    >
        <div
            id="cad-toolbar"
            className={`bg-gray-200 shadow-md transition-all ${menuOpen ? 'open' : ''}`}
        >
            <div className="flex justify-between items-center mb-4">
                <button
                    onClick={function () {
                        setMenuOpen(!menuOpen);
                    }}
                    className="text-gray-700"
                >
                    <FontAwesomeIcon icon={faBars} />
                </button>
            </div>
            {menuOpen && (
                <React.Fragment>
                    <div className="flex justify-end mb-4">
                        <button
                            onClick={navigateToDashboard}
                            className="toolbar-button"
                            style={{ padding: '8px 12px' }}
                            title="Back to Dashboard"
                        >
                            <span className="toolbar-button-content">
                                <FontAwesomeIcon icon={faHome} />
                            </span>
                        </button>
                    </div>
                    <div
                        className="relative mb-4 cursor-pointer"
                        onClick={function () {
                            setIsEditingGridName(true);
                        }}
                        onMouseEnter={function () {
                            setIsHoveringGridName(true);
                        }}
                        onMouseLeave={function () {
                            setIsHoveringGridName(false);
                        }}
                    >
                        {isEditingGridName ? (
                            <input
                                type="text"
                                value={gridName}
                                onChange={function (e) {
                                    setGridName(e.target.value);
                                }}
                                onBlur={function () {
                                    setIsEditingGridName(false);
                                }}
                                onKeyPress={function (e) {
                                    if (e.key === "Enter") {
                                        setIsEditingGridName(false);
                                    }
                                }}
                                className="w-full px-2 py-1 border rounded text-3xl font-bold text-white"
                                style={{
                                    fontSize: "3rem",
                                    fontWeight: "bold",
                                    backgroundColor: "transparent",
                                }}
                                autoFocus
                            />
                        ) : (
                            <div
                                className="px-2 py-1 truncate text-3xl font-bold text-white"
                                style={{
                                    fontSize: "1rem",
                                    fontWeight: "bold",
                                }}
                            >
                                {gridName || "Untitled Grid"}
                                {isHoveringGridName && (
                                    <span className="ml-2 text-xs text-gray-300">
                                        (Click to edit)
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                    <button
                        onClick={saveGrid}
                        className="toolbar-button"
                    >
                        <span className="toolbar-button-content">
                            <FontAwesomeIcon icon={faSave} className="mr-2" />
                            {currentGridId ? "Update Grid" : "Save Grid"}
                        </span>
                    </button>
                    <button
                        onClick={() => setShowMapSelector(true)}
                        className="bolt-button"
                    >
                        <span className="bolt-button-content">
                            <FontAwesomeIcon icon={faMap} className="mr-2" />
                            Add From Map
                        </span>
                    </button>
                    <button
                        onClick={fitAllShapesToView}
                        className="toolbar-button"
                    >
                        <span className="toolbar-button-content">
                            <FontAwesomeIcon icon={faExpand} className="mr-2" />
                            Fit All Shapes In View
                        </span>
                    </button>
                    <button
                        onClick={undo}
                        className="toolbar-button"
                    >
                        <span className="toolbar-button-content">
                            <FontAwesomeIcon icon={faUndo} className="mr-2" />
                            Undo
                        </span>
                    </button>
                    <button
                        onClick={redo}
                        className="toolbar-button"
                    >
                        <span className="toolbar-button-content">
                            <FontAwesomeIcon icon={faRedo} className="mr-2" />
                            Redo
                        </span>
                    </button>
                    <button
                        onClick={reset}
                        className="toolbar-button"
                    >
                        <span className="toolbar-button-content">
                            <FontAwesomeIcon icon={faTrash} className="mr-2" />
                            Reset
                        </span>
                    </button>
                    <div className="mb-4">
                        <button
                            onClick={() => setCurrentPage(currentPage > 0 ? currentPage - 1 : currentPage)}
                            className="toolbar-button"
                            style={{ width: 'calc(33.33% - 4px)', marginRight: '4px', padding: '8px' }}
                            disabled={currentPage === 0}
                        >
                            <span className="toolbar-button-content">
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </span>
                        </button>
                        <button
                            onClick={() => setPages([...pages, { points: [], shapes: [] }])}
                            className="toolbar-button"
                            style={{ width: 'calc(33.33% - 4px)', marginRight: '4px', padding: '8px', backgroundColor: '#4CAF50' }}
                        >
                            <span className="toolbar-button-content">
                                <FontAwesomeIcon icon={faPlus} />
                            </span>
                        </button>
                        <button
                            onClick={() => setCurrentPage(currentPage < pages.length - 1 ? currentPage + 1 : currentPage)}
                            className="toolbar-button"
                            style={{ width: 'calc(33.33% - 4px)', padding: '8px' }}
                            disabled={currentPage === pages.length - 1}
                        >
                            <span className="toolbar-button-content">
                                <FontAwesomeIcon icon={faArrowRight} />
                            </span>
                        </button>
                    </div>
                    <div className="toolbar-button" style={{ justifyContent: 'center', padding: '.5px', backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}>
                        <span className="toolbar-button-content" style={{ color: '#4a515d', fontFamily: 'Arial, sans-serif', fontWeight: 'bold' }}>
                            Page {currentPage + 1} of {pages.length}
                        </span>
                    </div>
                    <div className="toolbar-options mt-4">
                        <ToolbarOption
                            label="Snap to Grid"
                            type="checkbox"
                            checked={snapToGrid}
                            onChange={(e) => setSnapToGrid(e.target.checked)}
                        />
                        <ToolbarOption
                            label="Show Grid"
                            type="checkbox"
                            checked={showGrid}
                            onChange={(e) => setShowGrid(e.target.checked)}
                        />
                        <ToolbarOption
                            label="Show Measurements"
                            type="checkbox"
                            checked={showMeasurements}
                            onChange={(e) => setShowMeasurements(e.target.checked)}
                        />
                        <ToolbarOption
                            label="Grid Unit"
                            type="select"
                            value={gridUnit}
                            onChange={(e) => setGridUnit(e.target.value)}
                            options={[
                                { value: "feet", label: "Feet" },
                                { value: "inches", label: "Inches" },
                                { value: "centimeters", label: "Centimeters" },
                                { value: "meters", label: "Meters" },
                            ]}
                        />
                        <ToolbarOption
                            label="Grid Measurement"
                            type="select"
                            value={gridMeasurement}
                            onChange={handleGridMeasurementChange}
                            options={gridMeasurementOptions}
                        />
                    </div>
                    <div style={{ paddingTop: '4px' }}>
                        <button
                            onClick={downloadPDF}
                            className="toolbar-button"
                        >
                            <span className="toolbar-button-content">
                                <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
                                Download PDF
                            </span>
                        </button>
                    </div>
                </React.Fragment>
            )}
        </div>
        <svg
            ref={svgRef}
            viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`}
            preserveAspectRatio="xMidYMid meet"
            onWheel={handleZoom}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            className="w-full h-full bg-white"
        >
            {renderGrid()}
            {renderPoints()}
            {renderLines()}
            {renderShapes()}
            {renderDynamicLine()}
            {renderSelectionBox()}
        </svg>
        {renderContextMenu()}
        {showMapSelector && (
            <MapSelector
                onLocationSelect={handleLocationSelect}
                onCancel={handleMapSelectorCancel}
            />
        )}
        <DownloadModal
            isOpen={showDownloadModal}
            onClose={function () {
                setShowDownloadModal(false);
            }}
            pdfPreview={pdfPreview}
            downloadOption={downloadOption}
            setDownloadOption={setDownloadOption}
            downloadTitle={downloadTitle}
            setDownloadTitle={setDownloadTitle}
            onDownload={handleDownload}
            custom={custom}
            shapes={shapes}
        />
        <div style={{ position: 'fixed', right: '20px', bottom: '20px', zIndex: 9999 }} className="flex gap-2">
          <button
            onClick={() => {
              const newZoom = Math.min(maxZoom, zoom * 1.1);
              const center = {
                x: viewBox.x + viewBox.width / 2,
                y: viewBox.y + viewBox.height / 2
              };
              updateViewBox({
                x: center.x - (viewBox.width / (2 * (newZoom / zoom))),
                y: center.y - (viewBox.height / (2 * (newZoom / zoom))),
                width: viewBox.width * (zoom / newZoom),
                height: viewBox.height * (zoom / newZoom)
              }, newZoom);
            }}
            className="bg-white p-3 rounded-full shadow-lg hover:bg-gray-100 transition-colors"
            style={{ width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            title="Zoom In"
          >
            <FontAwesomeIcon icon={faPlus} className="text-gray-700 w-4 h-4" />
          </button>
          <button
            onClick={() => {
              const newZoom = Math.max(minZoom, zoom * 0.9);
              const center = {
                x: viewBox.x + viewBox.width / 2,
                y: viewBox.y + viewBox.height / 2
              };
              updateViewBox({
                x: center.x - (viewBox.width / (2 * (newZoom / zoom))),
                y: center.y - (viewBox.height / (2 * (newZoom / zoom))),
                width: viewBox.width * (zoom / newZoom),
                height: viewBox.height * (zoom / newZoom)
              }, newZoom);
            }}
            className="bg-white p-3 rounded-full shadow-lg hover:bg-gray-100 transition-colors"
            style={{ width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            title="Zoom Out"
          >
            <FontAwesomeIcon icon={faMinus} className="text-gray-700 w-4 h-4" />
          </button>
        </div>
        {showDownloadModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 custommodal">
            <div className="bg-white p-6 rounded shadow-lg max-h-[80vh] overflow-y-auto">
              <h2 className="text-lg font-bold mb-4">Download PDF</h2>
              
              {/* File name input */}
              <label className="block mb-4">
                File Name:
                <input
                  type="text"
                  value={fileName}
                  onChange={(e) => setFileName(e.target.value)}
                  className="border rounded w-full p-2"
                />
              </label>

              {/* Shape labels section */}
              <div className="shape-labels-container mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Shape Labels:
                </label>
                {pages[currentPage].shapes.map((_, index) => (
                  <ShapeLabelInput
                    key={index}
                    index={index}
                    value={localShapeLabels[index] || `Shape ${index + 1}`}
                    onChange={handleShapeLabelChange}
                  />
                ))}
              </div>

              {/* Modal buttons */}
              <div className="flex justify-end mt-4 gap-2">
                <button
                  onClick={() => setShowDownloadModal(false)}
                  className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setDownloadTitle(prevTitle => ({
                      ...prevTitle,
                      shapeLabels: localShapeLabels
                    }));
                    handleDownload();
                  }}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        )}
    </div>
);
};

if (typeof window !== 'undefined') {
  window.CADApp = CADApp
}

export default CADApp
