import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth, AuthProvider } from './AuthContext';
import LandingPage from './landingpage.js';
import Confirm from './confirm.js';
import AuthPage from './AuthPage.js';
import AccountTypeSelection from './AccountTypeSelection.js';
import IndividualSignUpPage from './IndividualSignUpPage.js';
import BusinessSignUpPage from './BusinessSignUpPage.js';
import Dashboard from './Dashboard.js';
import CADApp from './components/CADApp/CADApp.js';
import AccountPage from './AccountPage.js';
import UserManagement from './UserManagement.js';
import AdminConsole from './AdminConsole.js';
import ResetPassword from './ResetPassword.js';
import UserDashboard from './UserDashboard.js';
import StripeSubscriptionSetup from './StripeSubscriptionSetup.js';
import APIDocumentation from './APIDocumentation.js';
import CADWrapper from './components/CADApp/CADWrapper.js';
import SubscriptionPage from './SubscriptionPage.js';
import ProtectedRoute from './components/ProtectedRoute';
import ErrorBoundary from './components/ErrorBoundary';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #1a202c, #2d3748);
  color: white;
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top: 4px solid #4299e1;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

function AppRoutes() {
  const { loading } = useAuth();

  if (loading) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/landing" />} />
      <Route path="/landing" element={<LandingPage />} />
      <Route path="/confirm" element={<Confirm />} />
      <Route path="/login/*" element={<AuthPage />} />
      <Route path="/signup" element={<AccountTypeSelection />} />
      <Route path="/app/signup/individual" element={<IndividualSignUpPage />} />
      <Route path="/app/signup/business" element={<BusinessSignUpPage />} />
      <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
      <Route path="/cad" element={<ProtectedRoute><CADApp /></ProtectedRoute>} />
      <Route path="/cad/:gridId" element={<ProtectedRoute><CADApp /></ProtectedRoute>} />
      <Route path="/account" element={<ProtectedRoute><AccountPage /></ProtectedRoute>} />
      <Route path="/admin" element={<AdminConsole />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/user-management" element={<UserManagement />} />
      <Route path="/user-dashboard/:userId" element={<UserDashboard />} />
      <Route path="/subscription-page" element={<SubscriptionPage />} />
      <Route path="/stripe-setup" element={<StripeSubscriptionSetup />} />
      <Route path="/subscription-setup" element={<Navigate to="/stripe-setup" replace />} />
      <Route path="/api-documentation" element={<APIDocumentation />} />
      <Route path="/verify-email" element={<Navigate to="/confirm" replace />} />
    </Routes>
  );
}

function App() {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
