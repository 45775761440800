import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #1a202c, #2d3748);
  color: white;
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top: 4px solid #4299e1;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoadingMessage = styled.p`
  margin-top: 1rem;
  font-size: 1.1rem;
  color: #e2e8f0;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #1a202c, #2d3748);
  padding: 2rem;
  text-align: center;
`;

const ErrorMessage = styled.div`
  color: #fc8181;
  margin-bottom: 1rem;
  font-size: 1.1rem;
`;

const RetryButton = styled.button`
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  background-color: #4299e1;
  color: white;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #3182ce;
  }
`;

const ProtectedRoute = ({ children, requiredRole = null }) => {
  const { isAuthenticated, isLoading, user, authError, refreshSession } = useAuth();
  const location = useLocation();

  const handleRetry = async () => {
    try {
      await refreshSession();
    } catch (error) {
      console.error('Error retrying authentication:', error);
    }
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <LoadingMessage>Verifying your authentication...</LoadingMessage>
      </LoadingContainer>
    );
  }

  if (authError) {
    return (
      <ErrorContainer>
        <ErrorMessage>{authError}</ErrorMessage>
        <RetryButton onClick={handleRetry}>
          Retry Authentication
        </RetryButton>
      </ErrorContainer>
    );
  }

  if (!isAuthenticated || !user) {
    // Save the attempted URL for redirect after login
    return (
      <Navigate
        to="/login"
        state={{ from: location.pathname }}
        replace
      />
    );
  }

  // Check for required role
  if (requiredRole && user.role !== requiredRole) {
    return (
      <ErrorContainer>
        <ErrorMessage>
          You don't have permission to access this page.
          {user.role === 'business_owner' && requiredRole === 'admin' && 
            ' This page requires admin privileges.'}
        </ErrorMessage>
      </ErrorContainer>
    );
  }

  // Check subscription status for business owners
  const publicRoutes = ['/landing', '/', '/login', '/signup', '/confirm', '/verify-email'];
  const requiresSubscription = ['/dashboard', '/cad', '/account', '/user-management'];
  
  if (user.role === 'business_owner' && 
      (!user.subscription_status || user.subscription_status === 'inactive') && 
      !publicRoutes.includes(location.pathname) &&
      requiresSubscription.includes(location.pathname)) {
    return (
      <Navigate
        to="/subscription-setup"
        state={{
          from: location.pathname,
          plan: 'business',
          userId: user.id,
          businessId: user.businessId
        }}
        replace
      />
    );
  }

  return children;
};

export default ProtectedRoute; 