import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';

export default function Confirm() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log("Asd");

        const confirmSignUp = async () => {
            console.log("demo");

            // Extract the token from the URL hash
            const hash = window.location.hash;
            const params = new URLSearchParams(hash.substring(1)); // Remove the `#` and parse
            const accessToken = params.get('access_token');
            const refreshToken = params.get('refresh_token');

            console.log("accessToken :", accessToken);
            console.log("refreshToken :", refreshToken);

            if (accessToken && refreshToken) {
                console.log("in if");

                supabase.auth.onAuthStateChange((event, session) => {
                    console.log("event :", event);

                    if (event == "SIGNED_IN") {
                        setTimeout(() => {
                            navigate('/stripe-setup', { replace: true });
                            window.location.reload();
                        }, 1000);
                    }
                    console.log("session", session);
                })

                // Set the session using the tokens
                supabase.auth.setSession({
                    access_token: accessToken,
                    refresh_token: refreshToken,
                });
            } else {
                navigate('/login');
            }
        };

        confirmSignUp();
    }, [navigate]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return <div>Confirming your signup...</div>;
}