import React, { useState, useEffect } from "react";
import { useNavigate, Routes, Route, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "./store.js";
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import styled from 'styled-components';
import ResetPasswordPage from './ResetPasswordPage.js';
import { supabase, getAuthenticatedClient } from './supabaseClient.js';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #1a202c, #2d3748);
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  padding: 2rem;
`;

const Title = styled(motion.h1)`
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
`;

const FormContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
`;

const InputGroup = styled.div`
  margin-bottom: 1rem;
  padding: 0 1rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: #e2e8f0;
  }

  input {
    width: calc(100% - 2rem);
    padding: 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid #4a5568;
    background-color: #2d3748;
    color: #ffffff;
    font-size: 1rem;

    &:focus {
      outline: none;
      border-color: #4299e1;
    }
  }
`;

const LoginButton = styled.button`
  width: calc(100% - 2rem);
  margin: 0 1rem;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background-color: #4299e1;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #3182ce;
  }
`;

const ErrorMessage = styled.p`
  color: #fc8181;
  margin-bottom: 1rem;
`;

const SignUpLink = styled(Link)`
  display: block;
  text-align: center;
  margin-top: 1rem;
  color: #4299e1;
  text-decoration: none;
  font-size: 0.875rem;

  &:hover {
    text-decoration: underline;
  }
`;

const ResetPasswordLink = styled(Link)`
  display: block;
  text-align: center;
  margin-top: 0.5rem;
  color: #4299e1;
  text-decoration: none;
  font-size: 0.875rem;

  &:hover {
    text-decoration: underline;
  }
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top: 4px solid #4299e1;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #1a202c, #2d3748);
  color: white;
`;

const AuthPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const checkUser = async () => {
      try {
        console.log('Checking user session...');
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) throw sessionError;
        
        if (session?.user) {
          console.log('Found existing session, checking user status...');
          const { data: accountData } = await supabase
            .from('accounts')
            .select('role, subscription_status')
            .eq('id', session.user.id)
            .single();
          
          if (accountData) {
            const destination = accountData.role === 'business_owner' && accountData.subscription_status === 'inactive'
              ? '/stripe-setup'
              : '/dashboard';
            navigate(destination, { replace: true });
          }
        }
      } catch (error) {
        console.error('Error checking user session:', error);
        setError(error.message);
      }
    };

    checkUser();
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsAuthenticating(true);

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;

      // AuthContext will handle the redirection
      console.log('Login successful, AuthContext will handle navigation');
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message);
    } finally {
      setIsAuthenticating(false);
    }
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <p>Loading...</p>
      </LoadingContainer>
    );
  }

  return (
    <PageContainer>
      <Routes>
        <Route path="/*" element={
          <>
            <Title
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              DynamicDrafts
            </Title>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <FormContainer>
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <form onSubmit={handleSubmit}>
                  <InputGroup>
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      disabled={isAuthenticating}
                    />
                  </InputGroup>
                  <InputGroup>
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      disabled={isAuthenticating}
                    />
                  </InputGroup>
                  <LoginButton type="submit" disabled={isAuthenticating}>
                    {isAuthenticating ? 'Logging in...' : 'Log In'}
                  </LoginButton>
                </form>
                <SignUpLink to="/signup">Don't have an account? Sign up</SignUpLink>
                <ResetPasswordLink to="/resetpassword">Forgot your password?</ResetPasswordLink>
              </FormContainer>
            </motion.div>
          </>
        } />
      </Routes>
    </PageContainer>
  );
};

export default AuthPage;
