import { v4 as uuidv4 } from 'uuid';
import { supabase } from './supabaseClient.js';
import { useSelector } from 'react-redux';

export const generateApiKey = () => {
  return uuidv4();
};

export const saveApiKey = async (businessId, apiKey, userId) => {
  if (!businessId) {
    console.error('Business ID is undefined or null');
    throw new Error('Invalid Business ID');
  }
  try {
    console.log(`Attempting to save API key for business ID: ${businessId}`);
    
    // Start a Supabase transaction
    const { data, error } = await supabase.rpc('save_api_key_and_update_business', {
      p_business_id: businessId,
      p_api_key: apiKey,
      p_created_by: userId
    });

    if (error) {
      console.error('Error saving API key:', error);
      throw error;
    }

    console.log('API key saved successfully and business updated');
    return data;
  } catch (error) {
    console.error('Error in saveApiKey:', error);
    throw new Error(`Failed to save API key: ${error.message}`);
  }
};

export const getApiKey = async (businessId) => {
  if (!businessId) {
    console.error('Business ID is undefined or null');
    return null;
  }
  try {
    const { data, error } = await supabase
      .from('api_keys')
      .select('api_key')
      .eq('business_id', businessId)
      .single()
      .headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      });

    if (error) {
      if (error.code === 'PGRST116') {
        console.log('No API key found for this business');
        return null;
      }
      throw error;
    }
    return data?.api_key;
  } catch (error) {
    console.error('Error retrieving API key:', error);
    return null;
  }
};

export const validateApiKey = async (apiKey) => {
  const { data, error } = await supabase
    .from('api_keys')
    .select('business_id')
    .eq('api_key', apiKey)
    .single();

  if (error || !data) {
    return false;
  }
  return true;
};

// New function to get the business ID from the Redux store
export const useBusinessId = () => {
  const user = useSelector((state) => state.auth.user);
  // Check both possible locations of business ID
  return user?.businessId || user?.businessid || null;
};

export const checkApiKeyExists = async (businessId) => {
  if (!businessId) return false;
  
  try {
    const { data, error } = await supabase
      .from('api_keys')
      .select('api_key')
      .eq('business_id', businessId)
      .maybeSingle();
      
    if (error) {
      console.error('Error checking API key existence:', error);
      return false;
    }
    
    return !!data?.api_key;
  } catch (error) {
    console.error('Error in checkApiKeyExists:', error);
    return false;
  }
};

export const ensureApiKeyExists = async (businessId, userId) => {
  try {
    const hasKey = await checkApiKeyExists(businessId);
    if (!hasKey) {
      const newApiKey = generateApiKey();
      await saveApiKey(businessId, newApiKey, userId);
      return newApiKey;
    }
    return null;
  } catch (error) {
    console.error('Error in ensureApiKeyExists:', error);
    return null;
  }
};

export const consolidateApiKeys = async (businessId) => {
  try {
    // Get all API keys for the business
    const { data: apiKeys, error: fetchError } = await supabase
      .from('api_keys')
      .select('*')
      .eq('business_id', businessId)
      .order('created_at', { ascending: true });

    if (fetchError) throw fetchError;
    
    if (!apiKeys || apiKeys.length === 0) return null;
    if (apiKeys.length === 1) return apiKeys[0];

    // Keep the first key (oldest) and consolidate usage data
    const primaryKey = apiKeys[0];
    const otherKeys = apiKeys.slice(1);
    const otherKeysList = otherKeys.map(k => k.api_key);

    // Begin transaction
    const { error: usageError } = await supabase.rpc('consolidate_api_keys', {
      p_primary_key: primaryKey.api_key,
      p_other_keys: otherKeysList,
      p_business_id: businessId
    });

    if (usageError) throw usageError;

    // Delete other keys
    const { error: deleteError } = await supabase
      .from('api_keys')
      .delete()
      .eq('business_id', businessId)
      .neq('id', primaryKey.id);

    if (deleteError) throw deleteError;

    return primaryKey;
  } catch (error) {
    console.error('Error consolidating API keys:', error);
    throw error;
  }
};
