import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSync, faCreditCard, faChevronRight, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { SUBSCRIPTION_PRICES, PLAN_DETAILS } from '../../stripeConfig';

const Container = styled(motion.div)`
  background: rgba(26, 32, 44, 0.3);
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 800px;
  margin: 2rem auto;
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  h2 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
  }
`;

const StatusBadge = styled.span`
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.875rem;
  font-weight: 500;
  background: ${props => props.active ? 'rgba(72, 187, 120, 0.2)' : 'rgba(237, 100, 166, 0.2)'};
  color: ${props => props.active ? '#48BB78' : '#ED64A6'};
  border: 1px solid ${props => props.active ? 'rgba(72, 187, 120, 0.4)' : 'rgba(237, 100, 166, 0.4)'};
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Section = styled.div`
  background: rgba(26, 32, 44, 0.5);
  border-radius: 0.75rem;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const PlanInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  .plan-details {
    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      margin: 0 0 0.5rem 0;
    }

    .price {
      font-size: 1.5rem;
      color: #4299e1;
      font-weight: 700;
    }

    .period {
      font-size: 0.875rem;
      color: #A0AEC0;
    }
  }
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  background: ${props => props.primary ? 'linear-gradient(135deg, #4299e1 0%, #3182ce 100%)' : 'rgba(26, 32, 44, 0.5)'};
  color: #ffffff;
  border: 1px solid ${props => props.primary ? 'transparent' : 'rgba(255, 255, 255, 0.1)'};
  cursor: pointer;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(66, 153, 225, 0.3);
    background: ${props => props.primary ? 'linear-gradient(135deg, #3182ce 0%, #2c5282 100%)' : 'rgba(45, 55, 72, 0.5)'};
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const BillingInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-top: 1.5rem;

  .info-group {
    h4 {
      font-size: 0.875rem;
      color: #A0AEC0;
      margin: 0 0 0.5rem 0;
    }
    p {
      margin: 0;
      font-size: 1rem;
    }
  }
`;

const NextBilling = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: rgba(66, 153, 225, 0.1);
  border-radius: 0.5rem;
  margin-top: 1rem;

  .date {
    font-weight: 500;
  }

  .amount {
    color: #4299e1;
    font-weight: 600;
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1rem 0 0 0;

  li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    color: #A0AEC0;
    font-size: 0.875rem;

    svg {
      color: #48BB78;
    }
  }
`;

const ManageSubscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const handleChangePlan = () => {
    navigate('/subscription-page', { state: { fromManage: true } });
  };

  const handleUpdatePayment = () => {
    // Implement payment method update logic
  };

  const handleCancelSubscription = async () => {
    // Implement subscription cancellation logic
  };

  const currentPlan = PLAN_DETAILS[user?.subscription_plan?.toLowerCase()];
  const isYearly = user?.billing_period === 'yearly';
  const basePrice = SUBSCRIPTION_PRICES[user?.subscription_plan?.toUpperCase()];
  const price = isYearly ? basePrice * 10 : basePrice;

  return (
    <Container
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Header>
        <h2>Subscription Management</h2>
        <StatusBadge active={user?.subscription_status === 'active'}>
          <FontAwesomeIcon icon={user?.subscription_status === 'active' ? faCheck : faTimes} />
          {user?.subscription_status === 'active' ? 'Active' : 'Inactive'}
        </StatusBadge>
      </Header>

      <Section>
        <PlanInfo>
          <div className="plan-details">
            <h3>{currentPlan?.name}</h3>
            <div className="price">${price.toFixed(2)}</div>
            <div className="period">per {isYearly ? 'year' : 'month'}</div>
          </div>
          <ActionButton onClick={handleChangePlan}>
            <FontAwesomeIcon icon={faSync} />
            Change Plan
          </ActionButton>
        </PlanInfo>
        <FeatureList>
          {currentPlan?.features.map((feature, index) => (
            <li key={index}>
              <FontAwesomeIcon icon={faCheck} />
              {feature}
            </li>
          ))}
        </FeatureList>
      </Section>

      <Section>
        <h3>Billing Information</h3>
        <BillingInfo>
          <div className="info-group">
            <h4>Payment Method</h4>
            <p>•••• •••• •••• 4242</p>
          </div>
          <div className="info-group">
            <h4>Billing Email</h4>
            <p>{user?.email}</p>
          </div>
        </BillingInfo>
        <ActionButton onClick={handleUpdatePayment} style={{ marginTop: '1rem' }}>
          <FontAwesomeIcon icon={faCreditCard} />
          Update Payment Method
        </ActionButton>
        <NextBilling>
          <div>
            <span>Next billing date: </span>
            <span className="date">{formatDate(user?.subscription_end_date)}</span>
          </div>
          <span className="amount">${price.toFixed(2)}</span>
        </NextBilling>
      </Section>

      <Section>
        <h3>Cancel Subscription</h3>
        <p style={{ color: '#A0AEC0', marginBottom: '1rem' }}>
          Your subscription will remain active until the end of your current billing period.
        </p>
        <ActionButton onClick={handleCancelSubscription} style={{ background: 'rgba(229, 62, 62, 0.1)', color: '#E53E3E', borderColor: 'rgba(229, 62, 62, 0.2)' }}>
          <FontAwesomeIcon icon={faTimes} />
          Cancel Subscription
        </ActionButton>
      </Section>
    </Container>
  );
};

export default ManageSubscription; 