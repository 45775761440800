import React, { Suspense, lazy, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const CADApp = lazy(() => import('./CADApp.js'))

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    this.props.onError?.(error)
  }

  render() {
    if (this.state.hasError) {
      return <div className="cad-error">Something went wrong</div>
    }
    return this.props.children
  }
}

function CADWrapper({ apiKey, onGridSave, onGridLoad, onError, isApiMode = false, currentGrid }) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [error, setError] = useState(null)
  const user = useSelector(state => state.auth.user)

  useEffect(() => {
    if (!apiKey && !isApiMode) {
      setError('API key required in API mode')
      return
    }

    const initialize = async () => {
      try {
        // Validate API key
        if (!apiKey) {
          throw new Error('API key is required')
        }
        
        if (apiKey !== user?.apiKey) {
          throw new Error('Invalid API key')
        }
        
        setIsLoaded(true)
      } catch (err) {
        console.error('CADWrapper initialization error:', err)
        setError(err.message)
        if (onError) onError(err)
      }
    }

    initialize()
  }, [apiKey, isApiMode, user])

  if (error) {
    return <div className="cad-error">{error}</div>
  }

  if (!isLoaded) {
    return <div className="cad-loading">Loading CAD Application...</div>
  }

  return (
    <ErrorBoundary onError={onError}>
      <Suspense fallback={<div className="cad-loading">Loading CAD Application...</div>}>
        <CADApp
          apiKey={apiKey}
          onGridSave={onGridSave}
          onGridLoad={onGridLoad}
          onError={onError}
          isApiMode={isApiMode}
          currentGrid={currentGrid}
        />
      </Suspense>
    </ErrorBoundary>
  )
}

// Expose to window object
if (typeof window !== 'undefined') {
  window.ErrorBoundary = ErrorBoundary
  window.CADWrapper = CADWrapper
}

export default CADWrapper;