import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { fetchGrids, clearUser, fetchUserProfile, setGrids, deleteGrid } from "./store.js";
import { ReactComponent as DynamicDraftsLogo } from "./DynamicDraftsLogo.svg";
import { supabase } from './supabaseClient.js';
import { useBusinessId } from './hooks/useBusinessId.js';
import styled from 'styled-components';
import { ADMIN_CONSOLE_SECTIONS } from "./rolePermissions";

const ViewToggleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 16px;
`;

const ViewToggleButton = styled.button`
  background-color: ${props => props.$active ? '#4B5563' : '#374151'};
  color: ${props => props.$active ? '#ffffff' : '#D1D5DB'};
  padding: 6px 12px;
  border: 1px solid #4B5563;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${props => props.$active ? '#4B5563' : '#4B5563'};
    color: #ffffff;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const ViewToggle = ({ viewType, onChange }) => (
  <ViewToggleContainer>
    <ViewToggleButton
      $active={viewType === 'cards'}
      onClick={() => onChange('cards')}
      aria-label="Card View"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
      </svg>
      Cards
    </ViewToggleButton>
    <ViewToggleButton
      $active={viewType === 'list'}
      onClick={() => onChange('list')}
      aria-label="List View"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
      </svg>
      List
    </ViewToggleButton>
  </ViewToggleContainer>
);

const GridListView = ({ grid, onClick, onDelete }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      padding: '12px 16px',
      backgroundColor: '#374151',
      borderRadius: '6px',
      marginBottom: '8px',
      transition: 'all 0.2s ease',
      border: '1px solid #4B5563',
      position: 'relative'
    }}
    onMouseEnter={(e) => {
      e.currentTarget.style.backgroundColor = '#4B5563';
      e.currentTarget.style.transform = 'translateX(4px)';
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.backgroundColor = '#374151';
      e.currentTarget.style.transform = 'translateX(0)';
    }}
  >
    <div 
      style={{ flex: 1, cursor: 'pointer' }}
      onClick={onClick}
    >
      <h3 style={{
        color: '#F3F4F6',
        fontSize: '1rem',
        fontWeight: 500,
        marginBottom: '4px'
      }}>
        {grid.name || 'Untitled Grid'}
      </h3>
      <div style={{
        display: 'flex',
        gap: '16px',
        color: '#D1D5DB',
        fontSize: '0.875rem'
      }}>
        <span>Created: {new Date(grid.created_at).toLocaleDateString()}</span>
        {grid.updated_at && grid.updated_at !== grid.created_at && (
          <span>Modified: {new Date(grid.updated_at).toLocaleDateString()}</span>
        )}
      </div>
    </div>
    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
      <button
        onClick={(e) => onDelete(e, grid.id)}
        style={{
          backgroundColor: '#EF4444',
          color: 'white',
          border: 'none',
          padding: '0.25rem 0.5rem',
          borderRadius: '0.25rem',
          fontSize: '0.75rem',
          cursor: 'pointer',
          transition: 'background-color 0.2s ease',
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.backgroundColor = '#DC2626';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.backgroundColor = '#EF4444';
        }}
      >
        Delete
      </button>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
        style={{ width: '20px', height: '20px', color: '#D1D5DB', cursor: 'pointer' }}
        onClick={onClick}
      >
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
      </svg>
    </div>
  </div>
);

const SearchInput = styled.input`
  background-color: #374151;
  color: #F3F4F6;
  padding: 0.75rem 1rem;
  border: 1px solid #4B5563;
  border-radius: 0.375rem;
  width: 300px;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  margin-right: 1rem;

  &:focus {
    outline: none;
    border-color: #6B7280;
    box-shadow: 0 0 0 2px rgba(107, 114, 128, 0.2);
  }

  &::placeholder {
    color: #9CA3AF;
  }
`;

const Dashboard = () => {
  const user = useSelector((state) => state.auth.user);
  const userProfile = useSelector((state) => state.auth.userProfile);
  const grids = useSelector((state) => state.grid.grids);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [apiGrids, setApiGrids] = useState([]);
  const [viewType, setViewType] = useState('cards');
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState({
    profile: null,
    grids: []
  });
  const [userRole, setUserRole] = useState(null);
  const [userPermissions, setUserPermissions] = useState([]);
  const { businessId } = useBusinessId();
  
  const [selectedGrids, setSelectedGrids] = useState([]);
  const [selectMode, setSelectMode] = useState(false);

  useEffect(() => {
    const fetchUserPermissionsAndGrids = async () => {
      try {
        if (!user?.id) {
          navigate('/login');
          return;
        }

        setIsLoading(true);

        // First fetch user permissions
        const { data: profile } = await supabase
          .from('accounts')
          .select('role, permissions, business_id')
          .eq('id', user.id)
          .single();

        if (profile) {
          setUserRole(profile.role);
          setUserPermissions(profile.permissions || []);

          // Update user profile in state
          setUserData(prevState => ({
            ...prevState,
            profile: profile
          }));

          // Now fetch all grids with the role information
          const { data: allGrids, error } = await supabase
            .from('grids')
            .select('*')
            .or(
              profile.business_id
                ? `business_id.eq.${profile.business_id},and(user_id.eq.${user.id},created_via_api.is.false),created_via_api.eq.true`
                : `user_id.eq.${user.id},created_via_api.eq.true`
            )
            .order('created_at', { ascending: false });

          if (error) throw error;

          console.log("profile.business_id :", profile.business_id);

          // Separate grids based on creation method and permissions
          const apiCreatedGrids = allGrids.filter(grid => {
            console.log('Checking grid:', grid.id, {
              created_via_api: grid.created_via_api,
              role: profile.role,
              hasPermission: profile.permissions?.includes(ADMIN_CONSOLE_SECTIONS.VIEW_API_GRIDS)
            });

            return (
              grid.created_via_api === true &&
              (
                profile.role === 'business_owner' ||
                (profile.permissions && profile.permissions.includes(ADMIN_CONSOLE_SECTIONS.VIEW_API_GRIDS))
              )
            );
          });

          const filteredapiCreatedGrids = apiCreatedGrids.filter(item => item.business_id === profile.business_id);

          console.log('Filtered API grids:', apiCreatedGrids);

          const manuallyCreatedGrids = allGrids.filter(grid =>
            !grid.created_via_api &&
            grid.user_id === user.id
          );

          dispatch(setGrids(manuallyCreatedGrids));
          setApiGrids(filteredapiCreatedGrids);

          // Update local state
          setUserData(prevState => ({
            ...prevState,
            grids: manuallyCreatedGrids
          }));
        }
      } catch (error) {
        console.error('Error fetching user permissions and grids:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (user?.id) {
      fetchUserPermissionsAndGrids();
    } else {
      navigate('/login');
    }

    return () => {
      setApiGrids([]);
      setError(null);
    };
  }, [user, navigate, dispatch]);

  // Clear success message after 5 seconds
  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [success]);

  const canViewApiGrids = userRole === 'business_owner' ||
    userPermissions.includes(ADMIN_CONSOLE_SECTIONS.VIEW_API_GRIDS);

  const showAdminConsole = userRole === 'business_owner' ||
    userPermissions.some(permission =>
      Object.values(ADMIN_CONSOLE_SECTIONS).includes(permission)
    );

  if (isLoading) {
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh", backgroundColor: "#111827" }}>
        <div style={{ fontSize: "1.5rem", fontWeight: 600, color: "#F3F4F6" }}>Loading...</div>
      </div>
    );
  }

  if (!user) {
    return <div>No user found. Please log in.</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!userData.profile) {
    return (
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundColor: "#111827",
        color: "#F3F4F6"
      }}>
        <div>No user profile found. Please try logging in again.</div>
      </div>
    );
  }

  const handleLogout = async () => {
    try {
      setUserRole(null);
      setUserPermissions([]);
      dispatch(clearUser());
      await supabase.auth.signOut();
      localStorage.removeItem('supabase.auth.token');
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
      alert("Failed to log out. Please try again.");
    }
  };

  const isBusinessAdmin = userData.profile && (userData.profile.role === "business" || userData.profile.role === "admin");

  const buttonStyle = {
    display: "flex",
    alignItems: "center",
    padding: "0.75rem 1rem",
    borderRadius: "0.375rem",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    color: "#ffffff",
    fontFamily: "'Inter', sans-serif",
    fontSize: "0.875rem",
    fontWeight: "500",
    textDecoration: "none",
    transition: "all 0.2s ease",
    border: "1px solid rgba(255, 255, 255, 0.1)",
  };

  const buttonHoverStyle = {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    border: "1px solid rgba(255, 255, 255, 0.2)",
  };

  const handleMouseEnter = (event) => {
    Object.assign(event.currentTarget.style, buttonHoverStyle);
  };

  const handleMouseLeave = (event) => {
    Object.assign(event.currentTarget.style, buttonStyle);
  };

  const gridItemStyle = {
    display: "block",
    backgroundColor: "#f9fafb",
    padding: "1.5rem",
    borderRadius: "0.5rem",
    transition: "all 0.3s ease",
    border: "1px solid #e5e7eb",
    transform: "translateY(0)",
    boxShadow: "none",
    textDecoration: "none",
  };

  const gridItemHoverStyle = {
    boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    transform: "translateY(-2px)",
  };

  const handleGridClick = async (gridId) => {
    if (selectMode) {
      // If in select mode, toggle grid selection
      toggleGridSelection({ preventDefault: () => {}, stopPropagation: () => {} }, gridId);
      return;
    }
    
    try {
      setIsLoading(true);
      const gridData = [...grids, ...apiGrids].find(g => g.id === gridId);

      let finalGridData = gridData;

      if (!gridData) {
        const { data, error } = await supabase
          .from('grids')
          .select('*')
          .eq('id', gridId)
          .single();

        if (error) throw error;
        if (!data) throw new Error('Grid not found');

        finalGridData = data;
      }

      // Ensure we have all required grid properties
      const normalizedGridData = {
        id: finalGridData.id,
        name: finalGridData.name || 'Untitled Grid',
        pages: finalGridData.pages || [{ points: [], shapes: [] }],
        currentPage: finalGridData.currentPage || 0,
        viewBox: finalGridData.viewBox || { x: 0, y: 0, width: 800, height: 600, zoom: 1 },
        gridMeasurement: finalGridData.gridMeasurement || 1,
        gridUnit: finalGridData.gridUnit || 'feet',
        created_via_api: finalGridData.created_via_api || false
      };

      // Store in Redux
      await dispatch({ type: 'SET_CURRENT_GRID', payload: normalizedGridData });

      // Navigate to CAD view
      navigate(`/cad/${gridId}`, { state: { gridData: normalizedGridData } });
    } catch (error) {
      console.error('Error loading grid:', error);
      setError('Failed to load grid. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteGrid = async (e, gridId) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (window.confirm("Are you sure you want to delete this grid? This action cannot be undone.")) {
      try {
        setIsLoading(true);
        await dispatch(deleteGrid(gridId)).unwrap();
        
        // Clear selection if the deleted grid was selected
        setSelectedGrids(prev => prev.filter(id => id !== gridId));
        
        // Show success message
        setSuccess("Grid deleted successfully");
        
        // Refresh grids to ensure UI is updated
        await refreshGrids();
      } catch (error) {
        console.error("Error deleting grid:", error);
        setError("Failed to delete grid. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Add a function to refresh grids
  const refreshGrids = async () => {
    if (!user?.id) return;
    
    try {
      setIsLoading(true);
      
      // Get user profile for permissions
      const { data: profile } = await supabase
        .from('accounts')
        .select('role, permissions, business_id')
        .eq('id', user.id)
        .single();
        
      if (!profile) {
        throw new Error("Could not load user profile");
      }
      
      // Fetch all grids
      const { data: allGrids, error } = await supabase
        .from('grids')
        .select('*')
        .or(
          profile.business_id
            ? `business_id.eq.${profile.business_id},and(user_id.eq.${user.id},created_via_api.is.false),created_via_api.eq.true`
            : `user_id.eq.${user.id},created_via_api.eq.true`
        )
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      // Split grids by type
      const apiCreatedGrids = allGrids.filter(grid => 
        grid.created_via_api === true &&
        (
          profile.role === 'business_owner' ||
          (profile.permissions && profile.permissions.includes(ADMIN_CONSOLE_SECTIONS.VIEW_API_GRIDS))
        )
      ).filter(item => item.business_id === profile.business_id);
      
      const manuallyCreatedGrids = allGrids.filter(grid =>
        !grid.created_via_api &&
        grid.user_id === user.id
      );
      
      // Update Redux and local state
      dispatch(setGrids(manuallyCreatedGrids));
      setApiGrids(apiCreatedGrids);
      
    } catch (error) {
      console.error("Error refreshing grids:", error);
      setError("Failed to refresh grids. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  
  // Update batch delete to refresh grids after deletion
  const handleBatchDelete = async () => {
    if (selectedGrids.length === 0) {
      alert("Please select grids to delete");
      return;
    }
    
    if (window.confirm(`Are you sure you want to delete ${selectedGrids.length} grid${selectedGrids.length > 1 ? 's' : ''}? This action cannot be undone.`)) {
      setIsLoading(true);
      try {
        // Keep track of deleted grid IDs
        const successfullyDeletedGrids = [];
        
        // Delete each selected grid
        for (const gridId of selectedGrids) {
          try {
            await dispatch(deleteGrid(gridId)).unwrap();
            successfullyDeletedGrids.push(gridId);
          } catch (error) {
            console.error(`Error deleting grid ${gridId}:`, error);
          }
        }
        
        // Show success message if any grids were deleted
        if (successfullyDeletedGrids.length > 0) {
          // Show success message
          if (successfullyDeletedGrids.length === selectedGrids.length) {
            setSuccess(`Successfully deleted ${successfullyDeletedGrids.length} grid${successfullyDeletedGrids.length > 1 ? 's' : ''}`);
          } else {
            setSuccess(`Deleted ${successfullyDeletedGrids.length} out of ${selectedGrids.length} selected grids`);
          }
          
          // Refresh grids to ensure UI is updated
          await refreshGrids();
        } else {
          setError("Failed to delete grids. Please try again.");
        }
        
        // Clear selection after deletion attempt
        setSelectedGrids([]);
        setSelectMode(false);
      } catch (error) {
        console.error("Error in batch delete:", error);
        setError("Failed to delete some grids. Please try again.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const toggleGridSelection = (e, gridId) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Direct state update for immediate UI response
    if (selectedGrids.includes(gridId)) {
      setSelectedGrids(selectedGrids.filter(id => id !== gridId));
    } else {
      setSelectedGrids([...selectedGrids, gridId]);
    }
  };

  const toggleSelectMode = () => {
    // Direct state update for immediate UI response
    const newSelectMode = !selectMode;
    setSelectMode(newSelectMode);
    
    // If exiting select mode, clear selections
    if (!newSelectMode) {
      setSelectedGrids([]);
    }
  };

  const renderGridSection = (title, gridList, isApiSection = false) => (
    <div style={{ backgroundColor: "#1F2937", borderRadius: "0.5rem", boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)", padding: "1.5rem", marginBottom: "2rem" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1.5rem" }}>
        <h2 style={{
          fontSize: "1.5rem",
          fontWeight: 500,
          color: "#F3F4F6",
          fontFamily: "'Roboto', sans-serif"
        }}>
          {title}
          {isApiSection && (
            <>
              {gridList && ` (${gridList.length})`}
              {process.env.NODE_ENV === 'development' && (
                <span style={{ fontSize: '0.8rem', color: '#9CA3AF' }}>
                  {' '}(Business ID: {businessId})
                </span>
              )}
            </>
          )}
        </h2>
        {gridList && gridList.length > 0 && (
          <div style={{ display: "flex", gap: "0.5rem" }}>
            <button
              onClick={toggleSelectMode}
              style={{
                backgroundColor: selectMode ? "#4F46E5" : "#374151",
                color: "white",
                border: "none",
                padding: "0.5rem 0.75rem",
                borderRadius: "0.25rem",
                fontSize: "0.875rem",
                cursor: "pointer",
                transition: "background-color 0.2s ease",
              }}
            >
              {selectMode ? "Cancel" : "Select"}
            </button>
            {selectMode && selectedGrids.length > 0 && (
              <button
                onClick={handleBatchDelete}
                style={{
                  backgroundColor: "#EF4444",
                  color: "white",
                  border: "none",
                  padding: "0.5rem 0.75rem",
                  borderRadius: "0.25rem",
                  fontSize: "0.875rem",
                  cursor: "pointer",
                  transition: "background-color 0.2s ease",
                }}
              >
                Delete Selected ({selectedGrids.length})
              </button>
            )}
          </div>
        )}
      </div>
      
      {isApiSection && error ? (
        <p style={{ color: "#EF4444", fontStyle: "italic" }}>Error loading API grids: {error}</p>
      ) : gridList && gridList.length === 0 ? (
        <div>
          <p style={{ color: "#9CA3AF", fontStyle: "italic" }}>
            {isApiSection ? "No API-created grids yet." : "No grids available."}
          </p>
          {isApiSection && process.env.NODE_ENV === 'development' && (
            <pre style={{ color: "#9CA3AF", fontSize: "0.8rem", marginTop: "1rem" }}>
              Debug Info:
              Business ID: {businessId}
              Total Grids: {grids.length}
            </pre>
          )}
        </div>
      ) : (
        <div style={{ display: viewType === 'cards' ? "grid" : "flex", 
                     gridTemplateColumns: viewType === 'cards' ? "repeat(auto-fill, minmax(250px, 1fr))" : "none",
                     flexDirection: viewType === 'cards' ? "row" : "column",
                     gap: viewType === 'cards' ? "1.5rem" : "8px" }}>
          {gridList && gridList.map((grid) => (
            <div
              key={grid.id}
              style={{
                position: "relative",
                backgroundColor: "#374151",
                padding: "1.25rem",
                borderRadius: "0.5rem",
                transition: "all 0.3s ease",
                border: selectedGrids.includes(grid.id) ? "1px solid #4F46E5" : "1px solid #4B5563",
                textDecoration: "none",
                overflow: "hidden",
              }}
            >
              {selectMode && (
                <div style={{
                  position: "absolute",
                  top: "0.5rem", 
                  left: "0.5rem",
                  zIndex: 10
                }}>
                  <input
                    type="checkbox"
                    checked={selectedGrids.includes(grid.id)}
                    onChange={(e) => {
                      // Direct update for better UI responsiveness
                      if (selectedGrids.includes(grid.id)) {
                        setSelectedGrids(selectedGrids.filter(id => id !== grid.id));
                      } else {
                        setSelectedGrids([...selectedGrids, grid.id]);
                      }
                    }}
                    style={{
                      width: "1rem",
                      height: "1rem",
                      cursor: "pointer"
                    }}
                  />
                </div>
              )}
              <Link
                to={`/cad/${grid.id}`}
                onClick={(e) => {
                  if (selectMode) {
                    e.preventDefault();
                    // Direct update for better UI responsiveness
                    if (selectedGrids.includes(grid.id)) {
                      setSelectedGrids(selectedGrids.filter(id => id !== grid.id));
                    } else {
                      setSelectedGrids([...selectedGrids, grid.id]);
                    }
                  } else {
                    e.preventDefault();
                    handleGridClick(grid.id);
                  }
                }}
                style={{
                  display: "block",
                  textDecoration: "none",
                  color: "inherit",
                  paddingTop: selectMode ? "1rem" : 0
                }}
              >
                <h3 style={{ fontWeight: 600, fontSize: "1.125rem", marginBottom: "0.5rem", color: "#F3F4F6" }}>
                  {grid.name}
                </h3>
                <p style={{ fontSize: "0.875rem", color: "#D1D5DB" }}>
                  Created: {new Date(grid.created_at).toLocaleDateString()}
                </p>
                {isApiSection && (
                  <span style={{
                    position: "absolute",
                    top: "0.5rem",
                    right: "0.5rem",
                    backgroundColor: "#4B5563",
                    padding: "0.25rem 0.5rem",
                    borderRadius: "0.25rem",
                    fontSize: "0.75rem",
                    color: "#D1D5DB"
                  }}>
                    API
                  </span>
                )}
              </Link>
              {!selectMode && (
                <button
                  onClick={(e) => handleDeleteGrid(e, grid.id)}
                  style={{
                    position: "absolute",
                    bottom: "0.5rem",
                    right: "0.5rem",
                    backgroundColor: "#EF4444",
                    color: "white",
                    border: "none",
                    padding: "0.25rem 0.5rem",
                    borderRadius: "0.25rem",
                    fontSize: "0.75rem",
                    cursor: "pointer",
                    transition: "background-color 0.2s ease",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = "#DC2626";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = "#EF4444";
                  }}
                >
                  Delete
                </button>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const filterGridsByTitle = (grids, query) => {
    if (!query.trim()) return grids;

    const normalizedQuery = query.toLowerCase().trim();
    return grids.filter(grid =>
      (grid.name || 'Untitled Grid')
        .toLowerCase()
        .includes(normalizedQuery)
    );
  };

  const fetchBusinessUsers = async (businessId) => {
    try {
      // Fetch all users associated with the business
      const { data: users, error } = await supabase
        .from('accounts')
        .select(`
          *,
          business:business_id (
            name
          )
        `)
        .eq('business_id', businessId)
        .order('created_at', { ascending: false });

      if (error) throw error;

      setUsers(users || []);
      return users;
    } catch (error) {
      console.error('Error fetching business users:', error);
      setError('Failed to fetch users');
      return [];
    }
  };

  return (
    <div style={{
      display: "flex",
      height: "100%",
      width: "100%",
      backgroundColor: "#111827",
      fontFamily: "'Roboto', sans-serif",
      position: "fixed",
      top: 0,
      left: 0,
      overflow: "hidden"
    }}>
      <aside style={{
        width: "16rem",
        background: "linear-gradient(to bottom, #1F2937, #111827)",
        color: "white",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        overflowY: "auto",
        borderRight: "1px solid rgba(255, 255, 255, 0.1)"
      }}>
        <div style={{ padding: "1.5rem" }}>
          <Link to="/dashboard" style={{ display: "block", marginBottom: "2rem" }}>
            <DynamicDraftsLogo style={{ width: "100%", height: "auto" }} />
          </Link>
          <nav>
            <ul style={{ display: "flex", flexDirection: "column", gap: "0.5rem", listStyleType: "none", padding: 0, margin: 0 }}>
              <li>
                <Link to="/cad" style={buttonStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ width: "1.25rem", height: "1.25rem", marginRight: "0.5rem" }}>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                  New Grid
                </Link>
              </li>
              <li>
                <Link to="/account" style={buttonStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ width: "1.25rem", height: "1.25rem", marginRight: "0.5rem" }}>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                  Account
                </Link>
              </li>
              <div className="nav-links">
                {showAdminConsole && (
                  <li>
                    <Link to="/admin" style={buttonStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ width: "1.25rem", height: "1.25rem", marginRight: "0.5rem" }}>
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                      Admin Console
                    </Link>
                  </li>
                )}
              </div>
            </ul>
          </nav>
        </div>
      </aside>
      <main style={{
        flex: 1,
        marginLeft: "16rem",
        height: "100%",
        color: "#F3F4F6",
        position: "relative",
        overflowY: "auto",
        overflowX: "hidden"
      }}>
        <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "1rem 1.5rem" }}>
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "2rem",
            flexWrap: "wrap",
            gap: "1rem"
          }}>
            <div style={{ display: "flex", alignItems: "center", gap: "1rem", flex: "1" }}>
              <h1 style={{
                fontSize: "1.875rem",
                fontWeight: 500,
                color: "#F3F4F6",
                fontFamily: "'Roboto', sans-serif"
              }}>
                Welcome, {userData.profile && userData.profile.name ? userData.profile.name : user.email}
              </h1>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <SearchInput
                type="text"
                placeholder="Search grids..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                aria-label="Search grids"
              />
              <ViewToggle viewType={viewType} onChange={setViewType} />
              <button
                onClick={handleLogout}
                style={{
                  backgroundColor: "#4B5563",
                  color: "white",
                  padding: "0.75rem 1rem",
                  borderRadius: "0.375rem",
                  border: "none",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  fontSize: "0.875rem",
                  fontWeight: "500",
                  transition: "all 0.2s ease",
                  whiteSpace: "nowrap"
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ width: "1.25rem", height: "1.25rem" }}>
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                </svg>
                Logout
              </button>
            </div>
          </div>
          <div style={{ backgroundColor: "#1F2937", borderRadius: "0.5rem", boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)", padding: "1.5rem" }}>
            {isLoading && (
              <div style={{ textAlign: "center", padding: "2rem" }}>
                <p style={{ color: "#D1D5DB" }}>Loading...</p>
              </div>
            )}

            {error && (
              <div style={{ backgroundColor: "#FEE2E2", color: "#B91C1C", padding: "1rem", borderRadius: "0.5rem", marginBottom: "1.5rem" }}>
                <p>{error}</p>
              </div>
            )}

            {success && (
              <div style={{ backgroundColor: "#D1FAE5", color: "#15803D", padding: "1rem", borderRadius: "0.5rem", marginBottom: "1.5rem" }}>
                <p>{success}</p>
              </div>
            )}

            {!isLoading && !error && (
              <div className="grid-sections">
                {renderGridSection("Your Grids", filterGridsByTitle(grids.filter(grid => !grid.created_via_api), searchQuery), false)}
                
                {(userRole === 'business_owner' || userPermissions.includes(ADMIN_CONSOLE_SECTIONS.VIEW_API_GRIDS)) && 
                  renderGridSection("API-Created Grids", filterGridsByTitle(apiGrids, searchQuery), true)
                }
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
