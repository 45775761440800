import { configureStore, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { redirect } from "react-router-dom";
import { supabase } from './supabaseClient.js';

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env;

// Remove direct Stripe usage from frontend store
// Stripe operations should be handled through API calls to the backend

export const fetchUserProfile = createAsyncThunk("users/fetchUserProfile", async (userId) => {
  try {
    // First, check if user exists in auth
    const { data: { user: authUser }, error: authError } = await supabase.auth.getUser();
    
    if (authError || !authUser) {
      throw new Error('User not authenticated');
    }

    // Then fetch the account details
    const { data, error } = await supabase
      .from('accounts')
      .select(`
        *,
        business:business_id (
          id,
          name,
          status
        )
      `)
      .eq('id', userId)
      .maybeSingle();

    if (error) {
      throw error;
    }

    if (!data) {
      // Create a new profile if one doesn't exist
      const { data: newProfile, error: createError } = await supabase
        .from('accounts')
        .insert([{
          id: userId,
          email: authUser.email,
          name: authUser.user_metadata?.name || authUser.email.split('@')[0],
          role: 'individual',
          status: 'active',
          subscription_status: 'active'
        }])
        .single();

      if (createError) throw createError;
      return newProfile;
    }

    return data;
  } catch (error) {
    console.error('Error in fetchUserProfile:', error);
    throw error;
  }
});

export const fetchGrids = createAsyncThunk("grid/fetchGrids", async (userId) => {
  const { data, error } = await supabase.from("grids").select("*").eq("user_id", userId);

  if (error) {
    throw new Error(error.message);
  }
  return data;
});

export const addGrid = createAsyncThunk("grid/addGrid", async (grid) => {
  const { data, error } = await supabase.from("grids").insert([grid]).single();

  if (error) {
    throw new Error(error.message);
  }
  return data;
});

export const deleteGrid = createAsyncThunk("grid/deleteGrid", async (gridId) => {
  const { error } = await supabase.from("grids").delete().eq("id", gridId);

  if (error) {
    throw new Error(error.message);
  }
  return gridId;
});

export const updateGrid = createAsyncThunk("grid/updateGrid", async (grid) => {
  const { data, error } = await supabase.from("grids").update(grid).eq("id", grid.id).single();

  if (error) {
    throw new Error(error.message);
  }
  return data;
});

export const fetchUsers = createAsyncThunk("users/fetchUsers", async (businessId = false) => {
  // const { data, error } = await supabase.from("accounts").select("*");

  let { data, error } = await supabase.rpc("get_account_with_business_details");

  if (error) {
    throw new Error(error.message);
  }

  console.log("businessId :", businessId);

  if (businessId && businessId !== null) {
    data = data.filter((user) => user.business_id == businessId);
  }

  return data;
});

export const fetchBusiness = createAsyncThunk("business/fetchBusiness", async (businessId) => {
  const { data, error } = await supabase
    .from("business")
    .select("*, api_keys(api_key, usage_count)")
    .eq("id", businessId)
    .single();

  if (error) throw error;

  return {
    ...data,
    api_key_enabled: !!data.api_keys?.api_key,
    api_key_usage: data.api_keys?.usage_count || 0,
  };
});

export const updatePassword = createAsyncThunk("users/updatePassword", async ({ password }) => {
  const { data, error } = await supabase.auth.updateUser({ password: password });

  if (error) {
    throw new Error(error.message);
  }
  return data;
});

export const addUser = createAsyncThunk("users/addUser", async ({ name, email, role, status, subscription_status, business_id }) => {
  const { data: existingProfile, error: checkError } = await supabase.from("accounts").select("email").eq("email", email).single();

  if (existingProfile) {
    throw new Error("User with this email already exists.");
  }

  const { data: signUpData, error: signUpError } = await supabase.auth.signUp({
    email: email,
    password: Math.random().toString(36).slice(-8),
  });

  if (signUpError) throw signUpError;

  const { data: resetPassData, error: resetPassError } = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: "http://localhost:3000/resetpassword",
  });

  if (resetPassError) throw resetPassError;

  const { data: profileData, error: profileError } = await supabase.from("accounts").insert({
    id: signUpData.user.id,
    email: email,
    name: name,
    role: role,
    status: status,
    subscription_status: subscription_status,
  });

  if (profileError) {
    throw new Error(profileError.message);
  }

  let result = {
    user_id: signUpData.user.id,
    profileData,
  };

  if (role != "admin") {
    const { data: businessUserData, error: businessUserDataError } = await supabase.from("business_users").insert({
      business_id: business_id,
      account_id: signUpData.user.id,
    });

    if (businessUserDataError) {
      throw new Error(businessUserDataError.message);
    }

    result = {
      ...result,
      businessUserData,
    };
  }

  return result;
});

export const addUserwithPassword = createAsyncThunk(
  'auth/addUserwithPassword',
  async (userData) => {
    try {
      // Store current session
      const { data: { session: currentSession } } = await supabase.auth.getSession();

      // Check if user exists
      const { data: existingUser, error: checkError } = await supabase
        .from('accounts')
        .select('*')
        .eq('email', userData.email)
        .maybeSingle();

      if (existingUser) {
        throw new Error('User with this email already exists');
      }

      // Create auth user
      const { data: authData, error: signUpError } = await supabase.auth.signUp({
        email: userData.email,
        password: userData.password,
        options: {
          data: {
            name: userData.name,
            role: userData.role
          }
        }
      }, {
        shouldUpdateSession: false  // Prevent session update
      });

      if (signUpError) throw signUpError;

      // Create account record
      const accountData = {
        id: authData.user.id,
        email: userData.email,
        name: userData.name,
        role: userData.role,
        status: userData.status || 'active',
        subscription_status: userData.subscription_status || 'active',
        business_id: userData.business_id || null
      };

      const { error: accountError } = await supabase
        .from('accounts')
        .insert([accountData]);

      if (accountError) throw accountError;

      // Restore original session
      if (currentSession) {
        await supabase.auth.setSession(currentSession);
      }

      return accountData;
    } catch (error) {
      throw error;
    }
  }
);

export const addBusiness = createAsyncThunk("business/addBusiness", async ({ businessName, adminName, adminEmail, adminPassword, businessStatus }) => {
  // Check if the email already exists
  const { data: existingProfile, error: checkError } = await supabase.from("accounts").select("email").eq("email", adminEmail).single(); // Use .single() to return one result or null

  if (existingProfile) {
    // Email already exists
    throw new Error("User with this email already exists.");
  }

  // Proceed with user sign up
  const { data: signUpData, error: signUpError } = await supabase.auth.signUp({
    email: adminEmail,
    password: adminPassword, // Generate a random password
  });

  if (signUpError) throw signUpError;

  const { data: resetPassData, error: resetPassError } = await supabase.auth.resetPasswordForEmail(adminEmail, {
    redirectTo: "http://localhost:3000/resetpassword",
  });

  if (resetPassError) throw resetPassError;

  // Insert user profile into the 'accounts' table
  const { data: profileData, error: profileError } = await supabase
    .from("accounts")
    .insert({
      id: signUpData.user.id,
      email: adminEmail,
      name: adminName,
      role: "admin",
      status: "active",
      subscription_status: "active",
    })
    .select();

  if (profileError) {
    throw new Error(profileError.message);
  }

  // Insert business data into table
  const { data: businessData, error: businessDataError } = await supabase
    .from("business")
    .insert({
      name: businessName, // User email
      status: businessStatus,
    })
    .select();

  // console.log('data1', data1);

  if (businessDataError) {
    throw new Error(businessDataError.message);
  }

  // Insert business user data into table
  const { data: businessUserData, error: businessUserDataError } = await supabase.from("business_users").insert({
    business_id: businessData[0].id,
    account_id: signUpData.user.id,
  });

  if (businessUserDataError) {
    throw new Error(businessUserDataError.message);
  }

  // Return user_id along with profile data
  return {
    user_id: signUpData.user.id, // Include user_id
    profileData, // Include profile data
    businessData,
    businessUserDataError,
  };
});

export const removeUser = createAsyncThunk("users/removeUser", async (id) => {
  const { data, error, status } = await supabase.from("accounts").delete().eq("id", id);

  if (status != 204) {
    console.log("Error Deleting user");
    return false;
  }

  return "User Deleted";
});

export const deleteBusinessAndAccounts = createAsyncThunk("users/Business", async (businessId) => {
  // Step 1: Delete associated accounts
  const { error: deleteAccountsError } = await supabase
    .from("accounts")
    .delete()
    .in(
      "id",
      (await supabase.from("business_users").select("account_id").eq("business_id", businessId)).data.map((row) => row.account_id)
    );

  if (deleteAccountsError) {
    console.log(deleteAccountsError);
  }

  // Step 2: Delete associations in business_users
  const { error: deleteBusinessUsersError } = await supabase.from("business_users").delete().eq("business_id", businessId);

  if (deleteBusinessUsersError) {
    console.log(deleteBusinessUsersError);
  }

  // Step 3: Delete the business itself
  const { error: deleteBusinessError } = await supabase.from("business").delete().eq("id", businessId);

  if (deleteBusinessError) {
    console.log(deleteBusinessError);
  }
  console.log("Business and associated accounts deleted successfully.");
  return "Business Deleted";
});

export const updateUserStatus = createAsyncThunk("users/updateUserStatus", async ({ id, status }) => {
  const { data, error } = await supabase
    .from("accounts")
    .update({ status }) // Update the status field
    .eq("id", id); // Match the user by their ID

  if (error) {
    console.error("Error updating user status:", error.message);
    throw new Error(error.message);
  }

  return "Status Updated";
});

export const updateUserSubscription = createAsyncThunk("users/updateUserSubscription", async ({ id, subscription_status, subscription_plan }) => {
  const { data, error } = await supabase
    .from("accounts")
    .update({ 
      subscription_status,
      subscription_plan 
    })
    .eq("id", id);

  if (error) {
    console.error("Error updating user subscription:", error.message);
    throw new Error(error.message);
  }

  return "Subscription Updated";
});


export const updateBusinessStatus = createAsyncThunk("users/updateBusinessStatus", async ({ id, status }) => {
  const { data, error } = await supabase
    .from("business")
    .update({ status: status }) // Update the status field
    .eq("id", id); // Match the business by their ID

  if (error) {
    console.error("Error updating business status:", error.message);
    throw new Error(error.message);
  }
  console.log("Status Updated");
  return "Status Updated";
});

export const fetchUserGrids = createAsyncThunk("user/fetchUserGrids", async (userId) => {
  const { data, error } = await supabase.from("grids").select("*").eq("user_id", userId);

  if (error) {
    console.error("Error fetching user grids:", error);
    throw new Error(error.message);
  }
  return data;
});

export const toggleApiKey = createAsyncThunk(
  "business/toggleApiKey",
  async ({ businessId, enabled }, { rejectWithValue }) => {
    try {
      console.log('toggleApiKey thunk called with:', { businessId, enabled });
      const { data, error } = await supabase
        .from("business")
        .update({ api_key_enabled: enabled })
        .eq("id", businessId)
        .single();

      if (error) throw error;
      console.log('toggleApiKey thunk result:', data);
      return data;
    } catch (error) {
      console.error('Error in toggleApiKey thunk:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const clearApiKey = createAsyncThunk(
  'business/clearApiKey',
  async (businessId) => {
    try {
      const { error } = await supabase
        .from('api_keys')
        .delete()
        .eq('business_id', businessId);

      if (error) throw error;

      // Update business record to reflect API key is disabled
      const { error: businessError } = await supabase
        .from('business')
        .update({ 
          api_key_enabled: false,
          api_key_last_generated: null 
        })
        .eq('id', businessId);

      if (businessError) throw businessError;

      return businessId;
    } catch (error) {
      console.error('Error clearing API key:', error);
      throw error;
    }
  }
);

export const getApiKey = async (userId) => {
  try {
    const { data, error } = await supabase
      .from('api_keys')
      .select('*')
      .eq('business_id', userId)
      .maybeSingle();

    if (error) throw error;

    return data;
  } catch (error) {
    console.error('Error in getApiKey:', error);
    throw error;
  }
};

// Add auth slice
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    loading: true,
    error: null
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.loading = false;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    clearUser: (state) => {
      state.user = null;
      state.loading = false;
      state.error = null;
    }
  }
});

export const { setUser, setLoading, setError, clearUser } = authSlice.actions;

const gridSlice = createSlice({
  name: "grid",
  initialState: {
    grids: [],
    currentGrid: null,
    loading: false,
    error: null
  },
  reducers: {
    setGrids: (state, action) => {
      state.grids = action.payload;
    },
    setApiGrids: (state, action) => {
      state.apiGrids = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGrids.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGrids.fulfilled, (state, action) => {
        state.loading = false;
        state.grids = action.payload;
      })
      .addCase(fetchGrids.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteGrid.fulfilled, (state, action) => {
        state.grids = state.grids.filter(grid => grid.id !== action.payload);
      })
      .addCase(deleteGrid.rejected, (state, action) => {
        state.error = action.error.message;
      });
  }
});

export const { setGrids, setApiGrids } = gridSlice.actions;

const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.users.push(action.payload);
      })
      .addCase(removeUser.fulfilled, (state, action) => {
        state.users = state.users.filter((user) => user.id !== action.payload);
      });
  },
});

export const setUserWithApiKey = createAsyncThunk(
  "auth/setUserWithApiKey",
  async (userData) => {
    if (!userData.apiKey) {
      const apiKey = await getApiKey(userData.id);
      return { ...userData, apiKey };
    }
    return userData;
  }
);

// Configure store
const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    grid: gridSlice.reducer,
    users: usersSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export default store;
