// define constant
export const userRole = [
    { id: 1, title: 'Admin' },
    { id: 2, title: 'Business' },
    { id: 3, title: 'User' }
];

export const supabaseConfig = {
    url: process.env.REACT_APP_SUPABASE_URL,
    anonKey: process.env.REACT_APP_SUPABASE_ANON_KEY
};

export const getRoleById = ($id) => {
    return userRole;
}

const config = {
  stripe: {
    publicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY || '',
    priceIds: {
      individual: process.env.REACT_APP_STRIPE_PRICE_ID_INDIVIDUAL || 'price_1Q7S1bGBhA257OkuUXhbrUZT',
      business: process.env.REACT_APP_STRIPE_PRICE_ID_BUSINESS || 'price_1Q7S0hGBhA257OkuNPBEj0to'
    }
  },
  api: {
    url: process.env.REACT_APP_API_URL || 'https://api.dynamicdrafts.io'
  }
};

// Validate configuration
const validateConfig = () => {
  const missingVars = [];
  
  if (!config.stripe.publicKey) {
    missingVars.push('REACT_APP_STRIPE_PUBLIC_KEY');
  }
  
  if (missingVars.length > 0) {
    console.warn('Missing environment variables:', missingVars);
    console.warn('Some features may not work correctly without these variables.');
    // Don't throw an error, just log a warning
  }
};

// Validate on import
validateConfig();

export default config;
  