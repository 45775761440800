import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://vpwkvuwpssblzjaxpjbr.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZwd2t2dXdwc3NibHpqYXhwamJyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjMwOTI2NTcsImV4cCI6MjAzODY2ODY1N30.S_Rg5WmFbpIe7-pzhD7RtFzQ69HVHFhVj_4Wg92bk_c';

// Create a custom storage object with error handling
const customStorage = {
  getItem: (key) => {
    try {
      const item = localStorage.getItem(key);
      return item;
    } catch (error) {
      console.error('Error reading from localStorage:', error);
      return null;
    }
  },
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      console.error('Error writing to localStorage:', error);
    }
  },
  removeItem: (key) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing from localStorage:', error);
    }
  }
};

// Create a single instance of the Supabase client
const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    storage: typeof window !== 'undefined' ? customStorage : null,
    detectSessionInUrl: true,
    flowType: 'pkce',
    debug: true,
    storageKey: 'supabase.auth.token',
    retryAttempts: 3,
    retryInterval: 1000,
    logger: process.env.NODE_ENV === 'development' ? console : {
      error: () => {},
      warn: () => {},
      info: () => {},
      debug: () => {}
    }
  },
  db: {
    schema: 'public'
  },
  global: {
    headers: {
      'apikey': supabaseAnonKey,
      'X-Client-Info': 'supabase-js-web'
    }
  },
  realtime: {
    params: {
      eventsPerSecond: 2
    }
  }
});

// Keep track of registered auth listeners
let authListener = null;

// Add debug logging for auth state changes
const setupAuthListener = () => {
  // Remove existing listener if any
  if (authListener?.subscription) {
    console.log('Removing existing auth listener');
    authListener.subscription.unsubscribe();
  }

  // Set up new listener
  authListener = supabase.auth.onAuthStateChange((event, session) => {
    if (process.env.NODE_ENV === 'development') {
      console.log('Supabase Auth State Change:', { event, sessionExists: !!session });
      if (session) {
        console.log('Session details:', {
          userId: session.user?.id,
          expiresAt: session.expires_at,
          tokenExists: !!session.access_token
        });
      }
    }
    
    // Update the client's headers with the new session
    if (session?.access_token) {
      supabase.headers = {
        ...supabase.headers,
        'Authorization': `Bearer ${session.access_token}`
      };
    }
  });
};

// Set up initial auth listener
setupAuthListener();

// Helper function to get authenticated client
export const getAuthenticatedClient = async () => {
  if (process.env.NODE_ENV === 'development') {
    console.log('Getting authenticated client...');
  }
  
  // Try to get session with retries
  let session = null;
  let error = null;
  const maxRetries = 3;
  
  for (let i = 0; i < maxRetries; i++) {
    try {
      const result = await supabase.auth.getSession();
      if (result.error) throw result.error;
      if (result.data?.session?.access_token) {
        session = result.data.session;
        break;
      }
      throw new Error('No valid session found');
    } catch (e) {
      error = e;
      if (i < maxRetries - 1) {
        if (process.env.NODE_ENV === 'development') {
          console.log(`Retrying session fetch (attempt ${i + 2}/${maxRetries})...`);
        }
        await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1)));
      }
    }
  }

  if (!session) {
    console.error('Failed to get authenticated session:', error);
    return null;
  }

  if (process.env.NODE_ENV === 'development') {
    console.log('Got authenticated session:', {
      userId: session.user?.id,
      expiresAt: session.expires_at
    });
  }

  // Update the client's headers
  supabase.headers = {
    ...supabase.headers,
    'Authorization': `Bearer ${session.access_token}`
  };

  return supabase;
};

export { supabase };

