import React, { useState, useEffect, useCallback, memo } from 'react';
import { supabase } from '../supabaseClient';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSync } from '@fortawesome/free-solid-svg-icons';
import { calculateMonthlyApiCosts } from '../billingUtils';
import { InvoiceGenerator } from '../invoiceGenerator';
import { 
  ResponsiveContainer, 
  BarChart, 
  Bar, 
  CartesianGrid,
  XAxis, 
  YAxis, 
  Tooltip, 
  Legend 
} from 'recharts';
import '../styles/BillingManagement.css';

const BillingErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const handleError = (error) => {
      console.error('Billing Error:', error);
      setHasError(true);
      setErrorMessage(error.message);
    };

    window.addEventListener('error', handleError);
    return () => window.removeEventListener('error', handleError);
  }, []);

  if (hasError) {
    return (
      <div className="error-container">
        <h3>Error in Billing Management</h3>
        <p>{errorMessage}</p>
        <button onClick={() => setHasError(false)}>Try Again</button>
      </div>
    );
  }

  return children;
};

const API_COSTS = {
  CREATE_GRID: 3.50,
  UPDATE_GRID: 1.50,
  GET_GRID: 0.00,
  LIST_GRIDS: 0.00
};

const calculateApiCosts = (logs) => {
  return logs.reduce((total, log) => {
    const cost = API_COSTS[log.action_type] || 0;
    return total + cost;
  }, 0);
};

const BillingManagement = memo(({ businessId, apiLogs = [] }) => {
  console.log('BillingManagement received:', {
    businessId,
    apiLogsCount: apiLogs?.length,
    apiLogs
  });
  
  const [billingData, setBillingData] = useState({
    subscription: null,
    additionalUsers: 0,
    apiUsage: [],
    currentCharges: 0,
    nextBillingDate: null,
    billingHistory: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('Processing API logs in BillingManagement:', apiLogs);
    if (!Array.isArray(apiLogs) || apiLogs.length === 0) return;
    
    const apiCosts = calculateDetailedApiCosts(apiLogs);
    console.log('Calculated API costs:', apiCosts);
    
    setBillingData(prev => {
      // Only update if the costs have actually changed
      const prevTotal = prev.apiCosts?.total || 0;
      const newTotal = apiCosts.total || 0;
      
      if (Math.abs(prevTotal - newTotal) < 0.01) return prev;
      
      const baseSubscriptionCost = 99.99;
      const additionalUserCost = prev.additionalUsers * 10;
      
      return {
        ...prev,
        apiUsage: apiLogs,
        apiCosts: apiCosts,
        currentCharges: parseFloat((baseSubscriptionCost + additionalUserCost + newTotal).toFixed(2))
      };
    });
  }, [apiLogs]); // Only depend on apiLogs

  useEffect(() => {
    if (!businessId) {
      setError('No business ID provided');
      setLoading(false);
      return;
    }

    const loadData = async () => {
      try {
        setLoading(true);
        setError(null);
        await fetchBillingData();
      } catch (err) {
        console.error('Error in fetchBillingData:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [businessId]); // Remove apiLogs from dependency array

  const fetchBillingData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Get the start of billing period (November 1st, 2024)
      const startOfBillingPeriod = new Date('2024-11-01T00:00:00.000Z');
      
      // Filter API logs for current billing period
      const currentPeriodLogs = apiLogs.filter(log => {
        const logDate = new Date(log.created_at);
        return logDate >= startOfBillingPeriod;
      });

      console.log('Current period logs:', currentPeriodLogs);

      // Fetch all necessary data in parallel
      const [accountsResponse, businessResponse, userCountResponse] = await Promise.all([
        // Fetch subscription info
        supabase
          .from('accounts')
          .select(`
            subscription_plan,
            subscription_status,
            subscription_end_date,
            stripe_customer_id,
            stripe_subscription_id
          `)
          .eq('business_id', businessId),

        // Fetch business details
        supabase
          .from('business')
          .select('*')
          .eq('id', businessId)
          .single(),

        // Get user count
        supabase
          .from('accounts')
          .select('id', { count: 'exact' })
          .eq('business_id', businessId)
      ]);

      // Check for errors
      if (accountsResponse.error) throw accountsResponse.error;
      if (businessResponse.error) throw businessResponse.error;
      if (userCountResponse.error) throw userCountResponse.error;

      const accountsData = accountsResponse.data;
      const businessData = businessResponse.data;
      const userCount = userCountResponse.count || 0;

      // Calculate API costs with the filtered logs
      const apiCosts = calculateDetailedApiCosts(currentPeriodLogs);

      // Find primary subscription
      const primaryAccount = accountsData?.find(account => 
        account.subscription_status === 'active' && 
        account.subscription_plan === 'business'
      ) || accountsData?.[0];

      setBillingData(prevData => ({
        ...prevData,
        subscription: {
          plan: primaryAccount?.subscription_plan || 'free',
          status: primaryAccount?.subscription_status || 'inactive',
          startDate: businessData?.created_at,
        },
        additionalUsers: Math.max(0, userCount - 10),
        apiUsage: currentPeriodLogs,
        apiCosts: apiCosts,
        currentCharges: parseFloat((99.99 + (prevData.additionalUsers * 10) + apiCosts.total).toFixed(2)),
        nextBillingDate: primaryAccount?.subscription_end_date ? 
          new Date(primaryAccount.subscription_end_date) : 
          calculateNextBillingDate(businessData?.created_at),
        billingHistory: []
      }));

      setLoading(false);
    } catch (error) {
      console.error('Error fetching billing data:', error);
      setError('Failed to fetch billing data: ' + error.message);
      setLoading(false);
    }
  }, [businessId]); // Memoize based on these dependencies

  const calculateNextBillingDate = (startDate) => {
    const start = new Date(startDate);
    const today = new Date();
    const nextDate = new Date(start);
    
    while (nextDate <= today) {
      nextDate.setMonth(nextDate.getMonth() + 1);
    }
    
    return nextDate;
  };

  const calculateDetailedApiCosts = (apiUsage) => {
    console.log('Calculating API costs for usage:', apiUsage);
    
    if (!Array.isArray(apiUsage) || apiUsage.length === 0) {
      return {
        CREATE_GRID: 0,
        UPDATE_GRID: 0,
        total: 0,
        usage: {}
      };
    }

    // Group usage by action type
    const usageByType = apiUsage.reduce((acc, log) => {
      const actionType = log.action_type;
      if (!acc[actionType]) {
        acc[actionType] = {
          count: 0,
          details: []
        };
      }
      
      acc[actionType].count++;
      acc[actionType].details.push({
        timestamp: log.created_at,
        gridId: log.grid_id,
        gridName: log.grid_name
      });
      
      return acc;
    }, {});

    const costs = {
      CREATE_GRID: 0,
      UPDATE_GRID: 0,
      GET_GRID: 0,
      LIST_GRIDS: 0,
      total: 0,
      usage: {}
    };

    // Calculate costs for each action type
    Object.entries(usageByType).forEach(([action, data]) => {
      const baseCost = API_COSTS[action] || 0;
      const count = data.count;
      let discountedRate = baseCost;

      // Apply volume discounts
      if (count > 1000) {
        discountedRate = baseCost * 0.7;
      } else if (count > 500) {
        discountedRate = baseCost * 0.8;
      } else if (count > 100) {
        discountedRate = baseCost * 0.9;
      }

      const actionCost = parseFloat((count * discountedRate).toFixed(2));
      costs[action] = actionCost;
      costs.total += actionCost;
      
      costs.usage[action] = {
        count,
        rate: discountedRate,
        cost: actionCost,
        details: data.details
      };
    });

    costs.total = parseFloat(costs.total.toFixed(2));
    console.log('Final costs calculation:', costs);
    return costs;
  };

  const renderSubscriptionOverview = () => {
    if (loading) {
      return (
        <div className="billing-card subscription-overview">
          <h3>Subscription Overview</h3>
          <div className="loading-placeholder">Loading billing data...</div>
        </div>
      );
    }

    // Get API costs from billingData
    const apiCosts = billingData.apiCosts || {};
    const usage = apiCosts.usage || {};
    
    // Calculate total API costs by summing up all action costs
    const totalApiCosts = Object.entries(API_COSTS).reduce((total, [action, baseCost]) => {
      const actionUsage = usage[action] || { count: 0 };
      const cost = apiCosts[action] || 0;
      return total + cost;
    }, 0);

    // Calculate total charges including subscription, additional users, and API costs
    const totalCharges = 99.99 + (billingData.additionalUsers * 10) + totalApiCosts;

    return (
      <div className="billing-card subscription-overview">
        <h3>Subscription Overview</h3>
        <div className="billing-details">
          <div className="detail-row">
            <span>Base Plan (Business)</span>
            <span>$99.99</span>
          </div>
          <div className="detail-row">
            <span>Additional Users ({billingData.additionalUsers})</span>
            <span>${(billingData.additionalUsers * 10).toFixed(2)}</span>
          </div>
          <div className="api-usage-section">
            <h4>API Usage This Billing Period</h4>
            {Object.entries(API_COSTS).map(([action, baseCost]) => {
              const actionUsage = usage[action] || { count: 0 };
              if (baseCost > 0) {
                const cost = apiCosts[action] || 0;
                return (
                  <div key={action} className="detail-row">
                    <span>
                      {action.replace(/_/g, ' ')} ({actionUsage.count} calls)
                      {actionUsage.count > 0 && actionUsage.rate !== baseCost && (
                        <span className="discount-note">
                          * Rate: ${actionUsage.rate?.toFixed(2)}/call
                        </span>
                      )}
                    </span>
                    <span>${cost.toFixed(2)}</span>
                  </div>
                );
              }
              return null;
            })}
            <div className="detail-row subtotal">
              <span>Total API Usage</span>
              <span>${totalApiCosts.toFixed(2)}</span>
            </div>
          </div>
          <div className="detail-row total">
            <span>Total Current Charges</span>
            <span>${totalCharges.toFixed(2)}</span>
          </div>
          <div className="next-billing">
            Next Billing Date: {format(billingData.nextBillingDate, 'MMM dd, yyyy')}
          </div>
        </div>
      </div>
    );
  };

  const renderApiUsageChart = () => {
    const dailyUsage = billingData.apiUsage.reduce((acc, log) => {
      const date = format(new Date(log.created_at), 'MMM dd');
      if (!acc[date]) {
        acc[date] = { CREATE_GRID: 0, UPDATE_GRID: 0 };
      }
      acc[date][log.action_type]++;
      return acc;
    }, {});

    const chartData = Object.entries(dailyUsage).map(([date, actions]) => ({
      date,
      ...actions,
      cost: Object.entries(actions).reduce((total, [action, count]) => 
        total + (count * API_COSTS[action]), 0)
    }));

    return (
      <div className="billing-card api-usage-chart">
        <h3>API Usage & Costs</h3>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis yAxisId="left" orientation="left" label={{ value: 'Number of Calls', angle: -90 }} />
            <YAxis yAxisId="right" orientation="right" label={{ value: 'Cost (USD)', angle: 90 }} />
            <Tooltip />
            <Legend />
            <Bar yAxisId="left" dataKey="CREATE_GRID" name="Create" fill="#8884d8" />
            <Bar yAxisId="left" dataKey="UPDATE_GRID" name="Update" fill="#82ca9d" />
            <Bar yAxisId="right" dataKey="cost" name="Cost" fill="#ff7300" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const canDownloadInvoice = () => {
    if (!billingData.nextBillingDate) return false;
    
    const lastBillingDate = new Date(billingData.nextBillingDate);
    lastBillingDate.setMonth(lastBillingDate.getMonth() - 1);
    
    // Can only download invoice after billing period ends
    return new Date() > lastBillingDate;
  };

  const handleDownloadInvoice = async () => {
    try {
      setLoading(true);
      
      // Calculate billing period
      const endDate = new Date(billingData.nextBillingDate);
      endDate.setMonth(endDate.getMonth() - 1);
      const startDate = new Date(endDate);
      startDate.setMonth(startDate.getMonth() - 1);

      // Get business details
      const { data: businessData, error: businessError } = await supabase
        .from('business')
        .select('*')
        .eq('id', businessId)
        .single();

      if (businessError) throw businessError;

      // Calculate API costs for the billing period
      const { usage } = await calculateMonthlyApiCosts(
        businessId,
        startDate.toISOString(),
        endDate.toISOString()
      );

      // Generate invoice
      const invoiceGenerator = new InvoiceGenerator(
        businessData,
        { start: startDate, end: endDate }
      );

      const pdf = await invoiceGenerator.generateInvoice(
        usage,
        billingData.additionalUsers
      );

      // Download the PDF
      pdf.save(`invoice-${format(endDate, 'yyyy-MM')}.pdf`);
    } catch (error) {
      console.error('Error generating invoice:', error);
      setError('Failed to generate invoice. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="billing-management">
      <div className="billing-header">
        <h2>Billing & Usage</h2>
        <div className="billing-actions">
          <button onClick={fetchBillingData} className="refresh-button">
            <FontAwesomeIcon icon={faSync} /> Refresh
          </button>
          <button 
            onClick={handleDownloadInvoice} 
            className="download-button"
            disabled={!canDownloadInvoice()}
          >
            <FontAwesomeIcon icon={faDownload} /> Download Invoice
          </button>
        </div>
      </div>
      
      {loading ? (
        <div className="loading-spinner">Loading billing data...</div>
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : (
        <div className="billing-content">
          {renderSubscriptionOverview()}
          {renderApiUsageChart()}
          <BillingHistory history={billingData.billingHistory} />
        </div>
      )}
    </div>
  );
}, (prevProps, nextProps) => {
  return prevProps.businessId === nextProps.businessId && 
         JSON.stringify(prevProps.apiLogs) === JSON.stringify(nextProps.apiLogs);
});

const BillingHistory = ({ history }) => {
  if (!history || history.length === 0) {
    return (
      <div className="billing-card billing-history">
        <h3>Billing History</h3>
        <div className="no-history">No billing history available</div>
      </div>
    );
  }

  return (
    <div className="billing-card billing-history">
      <h3>Billing History</h3>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {history.map((entry, index) => (
            <tr key={index}>
              <td>{format(new Date(entry.billing_date), 'MMM dd, yyyy')}</td>
              <td>{entry.description}</td>
              <td>${entry.amount.toFixed(2)}</td>
              <td>
                <span className={`status-badge ${entry.status}`}>
                  {entry.status}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BillingManagement; 