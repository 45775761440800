/**
 * Generates a cryptographically secure API key
 * @param {number} length - The length of the API key to generate
 * @returns {string} The generated API key
 */
export const generateApiKey = (length = 32) => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const array = new Uint8Array(length);
  crypto.getRandomValues(array);
  
  return Array.from(array)
    .map(x => charset[x % charset.length])
    .join('');
}; 