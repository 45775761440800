import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from './store.js';
import { motion } from 'framer-motion';
import styled, { createGlobalStyle } from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import { supabase } from './supabaseClient.js';

// For testing purposes, we'll use the Stripe public key directly here
const stripePromise = loadStripe('pk_test_51PlRsF01wg2DEAWIXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX');

const GlobalStyle = createGlobalStyle`
  // Your global styles here
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #1a202c, #2d3748);
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  padding: 2rem;
`;

const FormContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
`;

const InputGroup = styled.div`
  margin-bottom: 1rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: #e2e8f0;
  }

  input {
    width: 100%;
    padding: 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid #4a5568;
    background-color: #2d3748;
    color: #ffffff;
    font-size: 1rem;

    &:focus {
      outline: none;
      border-color: #4299e1;
    }
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background-color: #4299e1;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #3182ce;
  }
`;

const ErrorMessage = styled.p`
  color: #fc8181;
  margin-bottom: 1rem;
`;

const LoginLink = styled(Link)`
  display: block;
  text-align: center;
  margin-top: 1rem;
  color: #4299e1;
  text-decoration: none;
  font-size: 0.875rem;

  &:hover {
    text-decoration: underline;
  }
`;

const IndividualSignUpPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = async (event) => {
      event.preventDefault();
      setError(null);
    
      if (password !== confirmPassword) {
        setError("Passwords don't match");
        return;
      }
    
      try {
        const { data: { user }, error } = await supabase.auth.signUp({
          email: email,
          password: password,
        });
    
        if (error) throw error;
    
        // Update user profile in Supabase
        const { error: profileError } = await supabase
          .from('accounts')
          .insert([
            { 
              id: user.id, 
              email: email,
              name: `${firstName} ${lastName}`, 
              role: 'individual',
              status: 'active',
              subscription_status: 'pending'
            }
          ]);
    
        if (profileError) throw profileError;
    
        dispatch(setUser({...user, role: 'individual'}));
        navigate('/setup-subscription', { state: { plan: 'individual', userId: user.id } });
      } catch (error) {
        console.error('Error signing up:', error);
        setError(error.message);
      }
    };


    return (
        <>
            <GlobalStyle />
            <PageContainer>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <FormContainer>
                        <h2 style={{ fontSize: '1.875rem', fontWeight: 'bold', marginBottom: '1.5rem', textAlign: 'center' }}>
                            Individual Sign Up
                        </h2>
                        {error && <ErrorMessage>{error}</ErrorMessage>}
                        <form onSubmit={handleSubmit}>
                            <InputGroup>
                                <label htmlFor="firstName">First Name</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required
                                />
                            </InputGroup>
                            <InputGroup>
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    required
                                />
                            </InputGroup>
                            <InputGroup>
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </InputGroup>
                            <InputGroup>
                                <label htmlFor="password">Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </InputGroup>
                            <InputGroup>
                                <label htmlFor="confirmPassword">Confirm Password</label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </InputGroup>
                            <SubmitButton type="submit">Sign Up</SubmitButton>
                        </form>
                        <LoginLink to="/login">Already have an account? Log in</LoginLink>
                    </FormContainer>
                </motion.div>
            </PageContainer>
        </>
    );
};

export default IndividualSignUpPage;
