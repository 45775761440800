import CryptoJS from "crypto-js";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link, Navigate } from "react-router-dom";
import { setUser } from './store.js';
import { ReactComponent as DynamicDraftsLogo } from "./DynamicDraftsLogo.svg";
import styled from "styled-components";
import { motion } from "framer-motion";
import { createGlobalStyle } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { supabase } from './supabaseClient.js'

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    overflow-x: hidden;
    width: 100%;
    background-color: #111827;
    margin: 0;
    padding: 0;
  }

  html {
    overflow-x: hidden;
    width: 100%;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #111827;
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: hidden;
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #1f2937;
  width: 100%;
  border: none;
  box-shadow: none;
`;

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  color: #ffffff;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
  padding: 0.5rem;

  &:hover {
    color: #4299e1;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const BackText = styled.span`
  opacity: 0;
  transform: translateX(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;

  ${BackLink}:hover & {
    opacity: 1;
    transform: translateX(0);
  }
`;

const MainContent = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow-x: hidden;
`;

const TwoColumnLayout = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  padding: 0 1rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;

const Column = styled.div`
  flex: 1;
  min-width: 0;
  border: none;
`;

const FormContainer = styled.div`
  background-color: #1f2937;
  border-radius: 0.5rem;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  box-shadow: none;
  border: none;
`;

const InputGroup = styled.div`
  margin-bottom: 1rem;
  border: none;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: #e2e8f0;
  }

  input {
    width: 100%;
    padding: 0.75rem;
    border-radius: 0.25rem;
    border: 1px solid #4a5568;
    background-color: #374151;
    color: #ffffff;
    font-size: 1rem;

    &:focus {
      outline: none;
      border-color: #4299e1;
      box-shadow: none;
    }
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background-color: #4299e1;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  box-shadow: none;

  &:hover {
    background-color: #3182ce;
    box-shadow: none;
  }
`;

const ErrorMessage = styled.p`
  color: #fc8181;
  margin-bottom: 1rem;
`;

const SuccessMessage = styled.p`
  color: #68d391;
  margin-bottom: 1rem;
`;

const SubscriptionContainer = styled.div`
  background-color: #1f2937;
  border-radius: 0.5rem;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  margin-top: 2rem;
  box-shadow: none;
  border: none;
`;

function AccountPage() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setLocalUser] = useState(null);
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: '',
    status: '',
    subscription_status: '',
    subscription_plan: '',
    subscription_end_date: ''
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Check auth status on component mount
    const checkAuth = async () => {
      try {
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) throw sessionError;
        
        if (!session?.user) {
          setIsAuthenticated(false);
          setLocalUser(null);
          return;
        }

        setIsAuthenticated(true);
        setLocalUser(session.user);
        
        // Fetch user data immediately after confirming authentication
        await fetchUserData(session.user.id);
      } catch (error) {
        console.error('Auth check failed:', error);
        setIsAuthenticated(false);
        setLocalUser(null);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, []);

  const fetchUserData = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('accounts')
        .select('*')
        .eq('id', userId)
        .single();

      if (error) throw error;

      if (!data) {
        throw new Error('No account data found');
      }

      setFormData(prev => ({
        ...prev,
        name: data.name || '',
        email: data.email || '',
        role: data.role || '',
        status: data.status || '',
        subscription_status: data.subscription_status || '',
        subscription_plan: data.subscription_plan || '',
        subscription_end_date: data.subscription_end_date || ''
      }));
    } catch (err) {
      console.error('Error fetching user data:', err);
      setError('Failed to load user data. Please try refreshing the page.');
    }
  };

  // Show loading state
  if (isLoading) {
    return (
      <PageContainer>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <p>Loading...</p>
        </div>
      </PageContainer>
    );
  }

  // Redirect if not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccessMessage('');
    setIsLoading(true);

    try {
      if (formData.password && formData.password !== formData.confirmPassword) {
        setError('Passwords do not match');
        return;
      }

      // Get current session
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      if (!session?.user) {
        throw new Error('No active session found');
      }

      // Prepare updates
      const updates = {
        name: formData.name,
        email: formData.email,
        updated_at: new Date().toISOString()
      };

      // Update account information
      const { error: updateError } = await supabase
        .from('accounts')
        .update(updates)
        .eq('id', session.user.id);

      if (updateError) throw updateError;

      // Update password if provided
      if (formData.password) {
        const { error: passwordError } = await supabase.auth.updateUser({
          password: formData.password
        });

        if (passwordError) throw passwordError;
      }

      // Update email if it has changed
      if (formData.email !== session.user.email) {
        const { error: emailError } = await supabase.auth.updateUser({
          email: formData.email
        });

        if (emailError) throw emailError;
      }

      setSuccessMessage('Account updated successfully');
      
      // Update local user state
      setLocalUser(prev => ({
        ...prev,
        email: formData.email
      }));

      // Clear password fields
      setFormData(prev => ({
        ...prev,
        password: '',
        confirmPassword: ''
      }));

      // Refresh user data
      await fetchUserData(session.user.id);
    } catch (err) {
      console.error('Error updating account:', err);
      setError(err.message || 'Failed to update account. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleManageSubscription = () => {
    if (formData.subscription_status === 'active' && formData.subscription_plan) {
      // Navigate to stripe setup for users with active subscriptions
      navigate('/stripe-setup', {
        state: {
          plan: formData.subscription_plan.toLowerCase(),
          isManageMode: true,
          currentPlan: formData.subscription_plan
        }
      });
    } else {
      // Fallback to subscription page if no active subscription or plan
      navigate('/subscription-page');
    }
  };

  return (
    <>
      <GlobalStyle />
      <PageContainer>
        <TopBar>
          <BackLink to="/dashboard">
            <FontAwesomeIcon icon={faArrowLeft} />
            <BackText>Back to Dashboard</BackText>
          </BackLink>
        </TopBar>
        <MainContent>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            style={{ width: '100%' }}
          >
            <h1 style={{ fontSize: "2.25rem", fontWeight: "bold", marginBottom: "2rem", color: "#ffffff", textAlign: "center" }}>
              Account Settings
            </h1>
            <TwoColumnLayout>
              <Column>
                <FormContainer>
                  {error && <ErrorMessage>{error}</ErrorMessage>}
                  {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
                  <form onSubmit={handleSubmit}>
                    <InputGroup>
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                    </InputGroup>
                    <InputGroup>
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                    </InputGroup>
                    <InputGroup>
                      <label htmlFor="password">New Password (leave blank to keep current)</label>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                    <InputGroup>
                      <label htmlFor="confirmPassword">Confirm New Password</label>
                      <input
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                      />
                    </InputGroup>
                    <SubmitButton type="submit">Update Account</SubmitButton>
                  </form>
                </FormContainer>
              </Column>
              <Column>
                <SubscriptionContainer>
                  <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1.5rem', color: '#ffffff' }}>
                    Account Details
                  </h2>
                  <div style={{ marginBottom: '1rem' }}>
                    <p><strong>Role:</strong> {formData.role}</p>
                    <p><strong>Status:</strong> {formData.status}</p>
                    <p><strong>Subscription Status:</strong> {formData.subscription_status}</p>
                    <p><strong>Subscription Plan:</strong> {formData.subscription_plan || 'None'}</p>
                    {formData.subscription_end_date && (
                      <p><strong>Subscription End Date:</strong> {new Date(formData.subscription_end_date).toLocaleDateString()}</p>
                    )}
                  </div>
                  {formData.subscription_status === 'active' && (
                    <SubmitButton 
                      onClick={handleManageSubscription}
                      style={{ backgroundColor: '#10b981' }}
                    >
                      Manage Subscription
                    </SubmitButton>
                  )}
                  {formData.subscription_status !== 'active' && (
                    <SubmitButton 
                      onClick={() => navigate('/subscription-page')}
                      style={{ backgroundColor: '#4299e1' }}
                    >
                      Setup Subscription
                    </SubmitButton>
                  )}
                </SubscriptionContainer>
              </Column>
            </TwoColumnLayout>
          </motion.div>
        </MainContent>
      </PageContainer>
    </>
  )
}

export default AccountPage;
