import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const AccountTypeSelection = () => {
    const navigate = useNavigate();

    const handleSelection = (accountType) => {
        navigate(`/app/signup/${accountType}`);
    };

    return (
        <PageContainer>
            <motion.h1
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                Choose Your Plan
            </motion.h1>
            <PlanContainer>
                <motion.div
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    <PriceTable
                        title="Individual"
                        price="$12.99"
                        period="month"
                        features={[
                            "1 user account",
                            "Access to CAD app",
                            "Personal dashboard",
                            "Create and save grids",
                            "1 GB storage",
                            "Monthly updates",
                            "Basic support",
                        ]}
                        onSelect={() => handleSelection('individual')}
                        showButton={true}
                    />
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                >
                    <PriceTable
                        title="Business"
                        price="$99.99"
                        period="month"
                        features={[
                            "1 business account",
                            "9 additional user accounts",
                            "Admin console",
                            "Advanced support",
                            "Analytics and reporting",
                            "Increased storage",
                            "Pay-per-use API access"
                        ]}
                        onSelect={() => handleSelection('business')}
                        showButton={true}
                    />
                </motion.div>
            </PlanContainer>
            <InfoSection>
            </InfoSection>
            <StyledLink to="/login">
                Back to Login
            </StyledLink>
        </PageContainer>
    );
};

const PriceTable = ({ title, price, period, features, onSelect, note, showButton }) => {
    return (
        <PriceTableContainer>
            <h2>{title}</h2>
            <PriceText>
                {price}<span>/{period}</span>
            </PriceText>
            {note && <NoteText>{note}</NoteText>}
            <FeatureList>
                {features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                ))}
            </FeatureList>
            {showButton && (
                <StartButton onClick={onSelect}>
                    Get Started
                </StartButton>
            )}
            {title === "Business" && (
                <AdditionalInfo>Additional users $10/month each</AdditionalInfo>
            )}
        </PriceTableContainer>
    );
};

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #1a202c, #2d3748);
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    padding: 2rem;
`;

const PlanContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
    margin-top: 2rem;
`;

const InfoSection = styled.div`
    margin-top: 2rem;
    text-align: center;
    font-size: 0.9rem;
    color: #a0aec0;
`;

const StyledLink = styled(Link)`
    margin-top: 2rem;
    color: #4299e1;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.2s;

    &:hover {
        color: #63b3ed;
    }
`;

const PriceTableContainer = styled.div`
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.5rem;
    padding: 2rem;
    width: 300px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
`;

const PriceText = styled.p`
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;

    span {
        font-size: 1rem;
        color: #a0aec0;
    }
`;

const NoteText = styled.p`
    font-size: 0.8rem;
    margin-bottom: 1rem;
    font-style: italic;
    color: #a0aec0;
`;

const FeatureList = styled.ul`
    list-style: none;
    padding: 0;
    margin-bottom: 2rem;

    li {
        margin-bottom: 0.5rem;
        color: #e2e8f0;
    }
`;

const StartButton = styled.button`
    width: 100%;
    padding: 0.75rem;
    border-radius: 0.25rem;
    background-color: #4299e1;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #3182ce;
    }
`;

const AdditionalInfo = styled.p`
    font-size: 0.8rem;
    color: #4299e1;
    margin-top: 1rem;
    font-style: italic;
`;

export default AccountTypeSelection;